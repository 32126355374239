import api from 'api';

export interface ErpAccountSchema {
  erpaccountsId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string | null;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  isActive: boolean;
  businessesId: number;
  connectorId: number;
  erpaccountCreatedOn: string | null;
  erplogReferenceId: string | null;
  erpaccountReferenceId: number | null;
  json: string | null;
  jsone: string | null;
  tokenOid: string | null;
  erpaccountStatusesId: number;
}

export interface SmartDisburseSchema {
  businessesId: number;
  createdBy: string;
  tokenOid: string | null;
}

export interface ERPCreationWrapperSchema {
  businessesId: number;
  createdBy: string;
}

export interface ERPAuthFieldSchema {
  authId: number;
  authType: number;
  connector: string | null;
  connectorId: number;
  createdDate: string;
  derrivedFromTemplate: boolean;
  fieldType: string | null;
  isHidden: boolean;
  isPrivate: boolean;
  isRequired: boolean;
  key: string;
  keyType: number;
  password: boolean;
  remove: boolean;
  updatedDate: string;
  value: string;
}

export interface ERPConnectorSchema {
  auths: ERPAuthFieldSchema[];
  baseUrl: string;
  connectorId: number;
  connectorType: string;
  createdDate: string;
  dataConverter: string | null;
  description: string;
  isActive: boolean;
  logo: string;
  name: string;
  updatedDate: string | null;
}

export const searchErpAccounts = (data: Partial<ErpAccountSchema>) => {
  return api.post('Erpaccounts/SearchErpaccounts', data);
};

export const getSageBankAccounts = (data: {
  erpAccountId: number;
  connectorId: number;
  routingNumber: string;
  bankAccountNumber: string;
}) => {
  const { erpAccountId, ...payload } = data;
  return api.post(`Erpaccounts/${erpAccountId}/Sage/Intacct/Search`, payload);
};

export const addSmartDisburse = (data: Partial<SmartDisburseSchema>) => {
  return api.post(
    `ERPApi/AddSmartDisburse?businessId=${data.businessesId}`,
    data,
  );
};

export const erpCreationWrapper = (data: ERPCreationWrapperSchema) => {
  return api.post('ERPApi/ERPCreationWrapper', data);
};

export const erpUpdateBusiness = (data: ERPCreationWrapperSchema) => {
  return api.put('ERPApi/UpdateBusiness', data);
};

export const getAllConnectors = (filterType: string) => {
  return api.get(`ERPApi/GetAllConnectorsByfilter/${filterType}`);
};

export default {
  searchErpAccounts,
  addSmartDisburse,
  erpCreationWrapper,
  erpUpdateBusiness,
};
