import { useTranslation } from 'react-i18next';
import { useEffect, useRef } from 'react';

import PermissionSection from 'components/permission/PermissionSection';
import Button, { ButtonVariant, ButtonSize } from 'components/Button';
import { BankAccount, AlertProps } from 'entities/bankAccounts';
import { DashboardTabs, Permissions } from 'entities/dashboard';
import { useAppSelector, useAppDispatch } from 'hooks';
import Alert, { AlertTypes } from 'components/Alert';

import { getHasBankAccountRelation } from './AccountingSoftware/selectors';
import { setDashboardTab } from '../dashboardSlice';
import { hasPermission } from '../selectors';
import {
  viewBankAccount,
  hideAlert,
  showAlert,
} from './BankAccounts/bankAccountsSlice';
import {
  getIntegrationAlerts,
  getBankAccounts,
} from './BankAccounts/selectors';

const BANK_NAME_MISSING_ALERT = 'bankAccountNameMissing';

const IntegrationAlerts = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('dashboard');

  const isNameMissing: boolean = useAppSelector(getHasBankAccountRelation);
  const bankAccounts: BankAccount[] = useAppSelector(getBankAccounts);
  const alerts: AlertProps[] = useAppSelector(getIntegrationAlerts);
  const bankPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.bankIntegration),
  );

  const bankAccountIdRef = useRef(0);

  useEffect(() => {
    bankAccountIdRef.current =
      bankAccounts.find(
        (account) =>
          account.isActive &&
          account.accountName &&
          !account.erpBankAccountReferenceID,
      )?.bankAccountsId || 0;
  }, [bankAccounts]);

  useEffect(() => {
    const alertIsVisible = alerts.find(
      (alert) => alert.code === BANK_NAME_MISSING_ALERT,
    );

    if (
      bankPermissions.view &&
      isNameMissing &&
      bankAccountIdRef.current &&
      !alertIsVisible
    ) {
      const handleAddName = () => {
        dispatch(viewBankAccount(bankAccountIdRef.current));
        dispatch(setDashboardTab(DashboardTabs.integration));
      };

      dispatch(
        showAlert({
          code: BANK_NAME_MISSING_ALERT,
          type: AlertTypes.warning,
          title: (
            <div className="flex items-start justify-between w-full">
              <div className="pr-6">
                <div className="mb-2">
                  {t('dashboard:alerts.bankAccount.nameMissingTitle')}
                </div>
                <div className="text-yellow-700 font-normal">
                  {t('dashboard:alerts.bankAccount.nameMissingText')}
                </div>
              </div>
              <PermissionSection
                permission={Permissions.bankIntegration}
                showPopover
                edit
              >
                <Button
                  onClick={handleAddName}
                  variant={ButtonVariant.secondaryOutline}
                  size={ButtonSize.small}
                  paddingClass="px-2 py-1"
                  heightClass="h-[28px]"
                  className="flex-shrink-0 font-medium"
                  disabled={!bankPermissions.edit}
                >
                  {t('dashboard:alerts.bankAccount.nameMissingButton')}
                </Button>
              </PermissionSection>
            </div>
          ),
        }),
      );
    }

    if (alertIsVisible && (!isNameMissing || !bankAccountIdRef.current)) {
      dispatch(hideAlert(BANK_NAME_MISSING_ALERT));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNameMissing, bankAccountIdRef.current]);

  const handleClose = (code) => {
    dispatch(hideAlert(code));
  };

  return (
    <div>
      {alerts.map((alert) => (
        <Alert
          key={alert.code}
          onClose={() => handleClose(alert.code)}
          className="mb-6"
          withBorder
          {...alert}
        />
      ))}
    </div>
  );
};

export default IntegrationAlerts;
