import api from 'api';

type VendorCredentialsType = {
  id: number;
  name: string;
  value: string;
  modifiedOn?: string;
};

export interface VendorCredentialsSchema {
  integratorDirectoryId: number;
  bankAccounts_JsonId: number;
  bankAccountsId: number;
  businessesId: number;
  vendorCredentials: VendorCredentialsType[];
  json: string;
  isActive: boolean;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
}

export const getVendorCredentials = (
  banksIntegratorId: number,
  integratorDirectoryId: number,
  businessesId: string,
) => {
  return api.get(
    `/BanksIntegrators/${banksIntegratorId}/Directories/${integratorDirectoryId}/Json`,
    {
      headers: {
        businessesId,
      },
    },
  );
};

export const addVendorCredentials = (
  banksIntegratorId: number,
  integratorDirectoryId: number,
  data: Partial<VendorCredentialsSchema>,
) => {
  return api.post(
    `/BanksIntegrators/${banksIntegratorId}/Directories/${integratorDirectoryId}/Json`,
    data,
  );
};

export const updateVendorCredentials = (
  banksIntegratorId: number,
  integratorDirectoryId: number,
  jsonId: number,
  data: Partial<VendorCredentialsSchema>,
) => {
  return api.put(
    `/BanksIntegrators/${banksIntegratorId}/Directories/${integratorDirectoryId}/Json/${jsonId}`,
    data,
  );
};

export const linkVendorCredentialsToBankAccount = (
  banksIntegratorId: number,
  integratorDirectoryId: number,
  jsonId: number,
  bankAccountId: number,
) => {
  return api.post(
    `/BanksIntegrators/${banksIntegratorId}/Directories/${integratorDirectoryId}/Json/${jsonId}/BankAccounts/${bankAccountId}`,
  );
};

export default {
  addVendorCredentials,
  linkVendorCredentialsToBankAccount,
};
