import api from 'api';

export const getAllDataBusinessesFinancialsByBusinessId = async (id) => {
  return api.get(
    `BusinessesFinancials/GetAllDataBusinessesFinancialsByBusinessId/${id}`,
  );
};

export const getBusinessesFinancialsBlobReferences = async (id) => {
  return api.get(
    `BusinessesFinancialsBlobReferences/GetBusinessesFinancialsBlobReferences/${id}`,
  );
};

export const getBusinessesFinancialsBlobReferencesFile = async (guid) => {
  return api.get(
    `BusinessesFinancialsBlobReferences/GetBusinessesFinancialsBlobReferencesFile/${guid}`,
    {
      responseType: 'blob',
    },
  );
};

export const addBusinessesFinancialsBlobReferences = async (data, options) => {
  return api.post(
    'BusinessesFinancialsBlobReferences/AddBusinessesFinancialsBlobReferences',
    data,
    options,
  );
};

export const updateIsActiveBusinessesFinancialsBlobReferences = async (
  data,
) => {
  return api.put(
    'BusinessesFinancialsBlobReferences/UpdateIsActiveBusinessesFinancialsBlobReferences',
    data,
  );
};

export const insertAllDataBusinessesFinancials = async (data) => {
  return api.post(
    'BusinessesFinancials/InsertAllDataBusinessesFinancials',
    data,
  );
};

export const updateAllDataBusinessesFinancials = async (data) => {
  return api.put(
    'BusinessesFinancials/UpdateAllDataBusinessesFinancials',
    data,
  );
};

export const searchBlobReferences = async (data) => {
  return api.post(
    'BusinessesFinancialsBlobReferences/SearchBusinessesFinancialsBlobReferences',
    data,
  );
};
