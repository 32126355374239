import api from 'api';

export const searchBusinessesProductsXrefs = (data) => {
  return api.post(
    'BusinessesProductsXrefs/SearchBusinessesProductsXrefs',
    data,
  );
};

export const updateBusinessesProductsXrefs = (data) => {
  return api.put('BusinessesProductsXrefs/UpdateBusinessesProductsXrefs', data);
};
