import { createSelector } from '@reduxjs/toolkit';

import {
  mapBusinessDocuments,
  checkBusinessInfoSuccess,
  getMappedBusinessInfo,
} from './utils';

export const getRoot = (state) => state.businessInfo;
export const getProfileRoot = (state) => state.profile;

export const getIsFormUpdate = createSelector(
  [getRoot],
  ({ isFormUpdate }) => isFormUpdate,
);

export const getMappedBusinessDocuments = createSelector(
  [getRoot],
  ({ documents }) => mapBusinessDocuments(documents),
);

export const getShowDoingBusinessAs = createSelector(
  [getRoot],
  ({ showDoingBusinessAs }) => showDoingBusinessAs,
);

export const getLoadingProgress = createSelector(
  [getRoot],
  ({ loadingProgress }) => loadingProgress,
);

export const getApplicant = createSelector(
  [getRoot],
  ({ applicant }) => applicant,
);

export const getIsBusinessInfoSuccess = createSelector(
  [getRoot, getProfileRoot],
  (
    { showDoingBusinessAs, documents },
    { business, businessAddress, businessTelephoneNumber },
  ) =>
    checkBusinessInfoSuccess({
      showDoingBusinessAs,
      documents,
      business,
      businessAddress,
      businessTelephoneNumber,
    }),
);

export const getBusinessInfo = createSelector(
  [getRoot, getProfileRoot],
  (businessInfo, profile) => getMappedBusinessInfo(businessInfo, profile),
);
