import { useState } from 'react';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import logoRound from 'assets/images/group.png';
import { getFileSize } from 'utils/files';
import Button from 'components/Button';
import { DownloadWhIteIcon, TrashOutlinedIcon } from 'components/icons';
import { Switch } from 'components/Inputs';

interface FilePlanProps {
  size: number;
  name: string;
  onDelete?: (value?: any) => void;
  // onPreview?: () => void;
  onDownload?: () => void;
  isLoading?: any;
}

const FilePlan: React.FC<FilePlanProps> = ({
  size,
  name,
  onDelete,
  onDownload,
  // onPreview,
  isLoading,
}) => {
  const [isOn, setToggleState] = useState(true);
  const { t } = useTranslation('dashboard');
  const handleToggle = () => setToggleState(!isOn);

  return (
    <div className="flex">
      <div className="mr-6">
        <img src={logoRound} alt="label" className="w-[220px] h-[209px]" />
      </div>
      <div className="flex flex-col w-full">
        <h3 className="text-lg leading-7 font-medium text-gray-900">
          {t('dashboard:businessPlan.yourBusinessPlan')}
        </h3>
        <div className="flex mt-1 mb-9">
          <span className="text-gray-500">{name || 'plan.pdf'}</span>
          <div>
            <span className="bg-gray-400 w-1.5 h-1.5 mx-3 rounded-full flex-shrink-0 inline-block" />
            <span className="text-secondary flex-shrink-0">
              {getFileSize(size || 0)}
            </span>
          </div>

          <div className="ml-2">
            <span className="bg-gray-400 w-1.5 h-1.5 mx-3 rounded-full flex-shrink-0 inline-block" />
            <span className="text-secondary flex-shrink-0">
              {t('dashboard:businessPlan.uploadDate')}
              <span className="font-medium text-gray-800 ml-1">
                {moment().format('MM-DD-YYYY')}
              </span>
            </span>
          </div>
        </div>
        <div className="flex gap-x-6 max-w-[620px]">
          <Button className="w-full" onClick={onDownload} loading={isLoading}>
            <DownloadWhIteIcon className="" />
            <span className="ml-4">
              {t('dashboard:businessPlan.buttons.downloadPlan')}
            </span>
          </Button>
          <div className="w-full">
            {/* <Button
              type="button"
              className="w-full bg-white"
              variant="secondary-outline"
              onClick={onPreview}
            >
              {t('dashboard:businessPlan.buttons.reviewPlan')}
            </Button> */}
          </div>
        </div>
        <div className="flex mt-9 justify-between">
          <div className="flex items-center">
            <Switch handleToggle={handleToggle} isOn={isOn} />
            <span className="text-sm ml-3 leading-5 font-medium text-gray-900">
              {t('dashboard:businessPlan.buttons.addBusinessToProfile')}
            </span>
          </div>
          <div>
            <Button
              size="medium-large"
              variant="link-red"
              onClick={onDelete}
              className="flex-shrink-0"
            >
              <TrashOutlinedIcon className="mr-1.5" />
              {t('dashboard:businessPlan.buttons.delete')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilePlan;
