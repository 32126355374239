import api from 'api';

export const submitBusinessApplication = ({
  businessesId,
  modifiedBy,
  fromEmailId,
  tenantsId,
}) => {
  return api.put(
    `Businesses/SubmitBusinessApplication/${businessesId}/${modifiedBy}/${fromEmailId}`,
    { tenantsId },
  );
};
