import { createSelector } from '@reduxjs/toolkit';

import {
  ERPAcountStatuses,
  ERPProviders,
  ErpAccount,
  Provider,
} from 'entities/accounting';

export const getRoot = (state) => state.accounting;
export const getProfile = (state) => state.profile;
export const getDashboard = (state) => state.dashboard;

export const getProviderList = createSelector(
  [getRoot],
  ({ providersList }) => providersList,
);

export const getERPLogos = createSelector(
  [getRoot],
  ({ ERPLogos }) => ERPLogos,
);

export const getIsAccountingOpen = createSelector(
  [getRoot],
  ({ isAccountingOpen }) => isAccountingOpen,
);

export const getErpAccountsLength = createSelector(
  [getRoot],
  ({ erpAccounts }) => erpAccounts.length,
);

export const getSourceType = createSelector(
  [getRoot],
  ({ sourceType }) => sourceType,
);

export const getShowWidgets = createSelector(
  [getRoot],
  ({ showWidgets }) => showWidgets,
);

export const getProvider = createSelector(
  [getRoot],
  ({ selectedProvider }) => selectedProvider,
);

export const getErpAccounts = createSelector(
  [getRoot, getDashboard],
  ({ erpAccounts, providersList }, { dropdowns }) =>
    erpAccounts.map((account) => {
      const provider = providersList.find(
        (prov) => prov.connectorId === account.connectorId,
      );

      const status = dropdowns.erpAccountStatuses.find(
        (s) => s.eRPAccountStatusesId === account.erpaccountStatusesId,
      );

      return {
        ...account,
        connectorName: provider?.name || '',
        erpaccountStatus: status?.description || '',
      };
    }),
);

export const getConnectedErpAccount = createSelector(
  [getRoot, getDashboard],
  ({ erpAccounts }, { dropdowns }) => {
    const status = dropdowns.erpAccountStatuses.find(
      (s) => s.description === ERPAcountStatuses.connected,
    );

    return status
      ? erpAccounts.find(
          (account) =>
            account.erpaccountStatusesId === status.eRPAccountStatusesId,
        )
      : null;
  },
);

export const getHasBankAccountRelation = createSelector(
  [getRoot],
  ({ erpAccounts, providersList }) => {
    const providers = providersList.filter(
      (provider: Provider) =>
        provider.name === ERPProviders.quickbooks ||
        provider.name === ERPProviders.intacct,
    );

    return erpAccounts.find(
      (account: ErpAccount) =>
        account.isActive &&
        providers.find(
          (provider: Provider) => provider.connectorId === account.connectorId,
        ),
    )
      ? true
      : false;
  },
);

export const getIsLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getUpdateIsLoading = createSelector(
  [getRoot],
  ({ updateIsLoading }) => updateIsLoading,
);

export const getShowSuccess = createSelector(
  [getRoot],
  ({ showSuccess }) => showSuccess,
);

export const getErpAccount = createSelector(
  [getRoot, getDashboard],
  ({ selectedAccount }, { dropdowns }) => {
    const status = dropdowns.erpAccountStatuses.find(
      (s) => s.eRPAccountStatusesId === selectedAccount.erpaccountStatusesId,
    );

    return {
      ...selectedAccount,
      erpaccountStatus: status?.description || '',
    };
  },
);

export const getErpAccountStatuses = createSelector(
  [getDashboard],
  ({ dropdowns }) => dropdowns.erpAccountStatuses,
);

export const getIsCreatedByCurrentPerson = createSelector(
  [getRoot, getProfile],
  ({ selectedAccount, erpAccounts }, { person }) => {
    const account = erpAccounts.find(
      (erp) => erp.erpaccountsId === selectedAccount.erpaccountsId,
    );
    return account?.createdBy === person.emailAddress;
  },
);

export const getSteps = createSelector([getRoot], ({ step }) => step);

export const getShowSteps = createSelector(
  [getRoot],
  ({ showSteps }) => showSteps,
);
