import api from 'api';

export interface CountrySchema {
  countriesId: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedBy: string;
  isActive: boolean;
  country1: string;
  alpha2Code: string;
  alpha3Code: string;
  uncode: string;
  isocode: string;
}

export const searchCountries = (data: Partial<CountrySchema>) => {
  return api.post('Countries/SearchCountries', data);
};

export default {
  searchCountries,
};
