import api from 'api';

export interface BankAccountSchema {
  bankAccountsId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
  isActive: boolean;
  businessesId: number;
  banksIntegratorsId: number;
  accountName: string;
  routingNumber: string;
  accountNumber: string;
  bankAccountTypesId: number;
  userCalledName: string | null;
  accountNumberLast4: string;
  tokenOid: string;
}

export interface BankAccountCheckRangeSchema {
  banksAccountsChecksRangesId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
  isActive: boolean;
  startRange: string;
  endRange: string;
  bankAccountsId: number;
  isLogging: boolean;
  tokenOid: string;
  gotBy: string;
}

export type BankAccountCheckRangeType = Partial<BankAccountCheckRangeSchema>;

export interface BankAccountCheckSchema {
  banks_AccountsId: number;
  banksAccountsId: number;
  banks_Accounts_ChecksId: number;
  banksAccountsChecksId: number;
  banks_Accounts_Checks_StatusesId: number;
  tgpA_Banks_Accounts_ChecksId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
  isActive: boolean;
  checkNumber: string;
  isLogging: boolean;
  tokenOid: string;
  gotBy: string;
}

export type BankAccountCheckType = Partial<BankAccountCheckSchema>;

export type BulkEditCheckRangesType = {
  modifiedBy: string;
  checkRanges: BankAccountCheckRangeType[];
  checkNumberOmits: BankAccountCheckRangeType[];
  bankAccountsId: number;
};

export const searchBankAccounts = (data: Partial<BankAccountSchema>) => {
  return api.post('/BankAccounts/SearchBankAccounts', data);
};

export const searchBankAccountCheckRanges = (
  data: BankAccountCheckRangeType,
) => {
  return api.post(
    '/BanksAccountsChecksRanges/SearchBanksAccountsChecksRanges',
    data,
  );
};

export const searchBankAccountChecks = (data: BankAccountCheckType) => {
  return api.post('/BanksAccountsChecks/SearchBanksAccountsChecks', data);
};

export const addUpdateBanksAccountsChecksAndRanges = (
  data: BulkEditCheckRangesType,
) => {
  return api.post(
    '/BanksAccountsChecksRanges/AddUpdateBanksAccountsChecksAndRanges',
    data,
  );
};

export default {
  searchBankAccounts,
  searchBankAccountCheckRanges,
  addUpdateBanksAccountsChecksAndRanges,
};
