import api from 'api';

export const uploadBusinessPlan = (fileData, options) => {
  return api.post(
    'BusinessesPlanBlobReferences/AddBusinessesPlanBlobReferences',
    fileData,
    options,
  );
};

export const getBusinessPlanBlobReference = (id) => {
  return api.get(
    `BusinessesPlanBlobReferences/GetBusinessesPlanBlobReferences/${id}`,
  );
};

export const searchBusinessPlan = (search) => {
  return api.post(
    'BusinessesPlanBlobReferences/SearchBusinessesPlanBlobReferences',
    search,
  );
};

export const addBusinessPlan = (data) => {
  return api.post('BusinessesPlans/AddBusinessesPlans', data);
};

export const updateBusinessPlan = (data) => {
  return api.put('BusinessesPlans/UpdateBusinessesPlans', data);
};

export const getBusinessPlanById = (businessesId) => {
  return api.get(
    `BusinessesPlans/GetBusinessesPlansByBusinessesId/${businessesId}`,
  );
};

export const getBusinessPlanDocumentById = (businessesId) => {
  return api.get(`BusinessesPlans/GetBusinessesPlansDocument/${businessesId}`, {
    responseType: 'blob',
  });
};

export const updateBusinessPlanBlob = (data, options) => {
  return api.put(
    'BusinessesPlanBlobReferences/UpdateBusinessesPlanBlobReferences',
    data,
    options,
  );
};

export const updateIsActivePlan = (data) => {
  return api.put(
    'BusinessesPlanBlobReferences/UpdateIsActiveBusinessesPlanBlobReferences',
    data,
  );
};

export const getFile = (blobGUID) => {
  return api.get(
    `BusinessesPlanBlobReferences/GetBusinessesPlanBlobReferencesFile/${blobGUID}`,
    {
      responseType: 'blob',
    },
  );
};

export const businessPlanApi = {
  addBusinessPlan,
  getFile,
  getBusinessPlanDocumentById,
  getBusinessPlanById,
  updateBusinessPlan,
};
