import { ReactNode } from 'react';
import classNames from 'classnames';

import Header from './Header';

type LayoutSignUpProps = {
  children: ReactNode;
  fullWidth?: boolean;
};

const LayoutSignUp: React.FC<LayoutSignUpProps> = ({
  children,
  fullWidth = false,
}) => {
  return (
    <div className="flex flex-col h-screen min-h-screen overflow-y-auto bg-white">
      <Header />

      <div className="flex-1">
        <div
          className={classNames(
            fullWidth
              ? 'px-8 sm:px-15'
              : 'px-4 lg:px-3 max-w-[904px] m-auto min-h-full flex flex-col',
            'pt-6 sm:pt-10 pb-16',
          )}
        >
          {children}
        </div>
      </div>
    </div>
  );
};

export default LayoutSignUp;
