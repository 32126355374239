import { parsePhoneNumber } from 'react-phone-number-input';
import { createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';

import { buildFormData, getResponseErrorMessages } from 'utils/helpers';
import { getBankAccountBlobFile } from 'services/businesses';
import { ConfigurationState } from 'entities/configuration';
import { hasPermission } from 'pages/dashboard/selectors';
import { getBusinessesId } from 'utils/authService';
import { getPerson } from 'pages/Profile/selectors';
import { Permissions } from 'entities/dashboard';
import { ProfileState } from 'entities/profile';
import { RootState } from 'state/store';
import {
  getVendorCredentials,
  addVendorCredentials,
  updateVendorCredentials,
  linkVendorCredentialsToBankAccount,
} from 'services/bankIntegrators';
import {
  searchBankAccountCheckRanges,
  searchBankAccountChecks,
  searchBankAccounts,
  BankAccountCheckRangeType,
  BankAccountCheckType,
  addUpdateBanksAccountsChecksAndRanges,
} from 'services/bankAccounts';
import {
  getVendorCredentialsByIntegrator,
  getVendorCredentialsByDirectoryId,
} from 'services/tgpa';
import {
  BankAccount,
  BankAccountStatusIds,
  BankAccountsState,
} from 'entities/bankAccounts';

import { setDefaultBankAccountId } from '../Configuration/configurationSlice';
import {
  getState as getBankAccountState,
  getBankAccount,
  getBankAccountChecks,
  getBankAccountCheckRanges,
  getBankAccounts as getAccounts,
} from './selectors';
import { updateBPSTrack } from '../Configuration/thunks';
import {
  mappedAccountTypes,
  mapCreateBankAccountFields,
  mapUpdateBankAccountFields,
  mapCheckRanges,
  mapChecks,
} from './utils';
import {
  searchBanksIntegratorsByDetails,
  searchBankAccountTypes,
  getBankAccounts,
  addBankAccounts,
  updateBankAccounts,
  updateIsActiveBankAccounts,
  updateBankAccountsBlobReference,
  getBankIntegratorsFile,
} from './api';
import {
  addFundingAccount,
  getFundingAccount,
  updateFundingAccount,
} from 'services/accounts';
import { addTelephoneNumbers } from 'services/telephoneNumbers';
import { addEmailAddress } from 'services/emailAddresses';

export const fetchVendorCredentials: any = createAsyncThunk(
  'bankAccounts/fetchVendorCredentials',
  async (_: void, thunk) => {
    try {
      const { getState } = thunk;
      const { bankAccounts } = getState() as {
        bankAccounts: BankAccountsState;
      };

      const fields = await getVendorCredentialsByDirectoryId(
        bankAccounts.directoryId,
      );

      let vendorCredentials: any = null;

      if (bankAccounts.integrator) {
        vendorCredentials = await getVendorCredentials(
          bankAccounts.integrator.integratorsId,
          bankAccounts.directoryId,
          getBusinessesId(),
        );
      }

      return { fields, vendorCredentials };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchBankAccounts: any = createAsyncThunk(
  'bankAccounts/fetchBankAccounts',
  async (_: void, thunkAPI) => {
    try {
      const { view: viewBankPermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.bankIntegration,
      );

      if (!viewBankPermission) {
        return [];
      }

      const businessesId = getBusinessesId();

      const accounts: any = await searchBankAccounts({
        businessesId,
        isActive: true,
      });

      return accounts ?? [];
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchBankIntegrators: any = createAsyncThunk(
  'bankAccounts/fetchBankIntegrators',
  async (_: void, thunkAPI) => {
    try {
      const { view: viewBankPermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.bankIntegration,
      );

      if (!viewBankPermission) {
        return [];
      }

      const bankIntegrators = await searchBanksIntegratorsByDetails({
        isActive: true,
      });

      return bankIntegrators;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchCheckRanges: any = createAsyncThunk(
  'bankAccounts/fetchCheckRanges',
  async (_: void, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;

      const { bankAccountsId } = getBankAccount(state);
      const { emailAddress } = getPerson(state);

      const ranges = await searchBankAccountCheckRanges({
        bankAccountsId,
        isActive: true,
        gotBy: emailAddress,
      });

      const omitted = await searchBankAccountChecks({
        banks_AccountsId: bankAccountsId,
        isActive: true,
        gotBy: emailAddress,
      });

      return { ranges, omitted };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchBankAccountData: any = createAsyncThunk(
  'bankAccounts/fetchBankAccountData',
  async (integratorId: number, thunkApi) => {
    try {
      const { bankAccounts } = thunkApi.getState() as {
        bankAccounts: BankAccountsState;
      };
      const { bankAccountTypes: types } = bankAccounts;

      const bankAccountTypes: any = types.length
        ? types
        : await searchBankAccountTypes();

      let directoryId = 0;

      if (integratorId) {
        const integrators = await getVendorCredentialsByIntegrator(
          integratorId,
        );
        directoryId = integrators[0]?.id || 0;
      }

      return {
        bankAccountTypes: types.length
          ? bankAccountTypes
          : mappedAccountTypes(bankAccountTypes),
        directoryId,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createBankAccount: any = createAsyncThunk(
  'bankAccounts/createBankAccount',
  async (data: any, thunkApi) => {
    const { getState, rejectWithValue } = thunkApi;
    try {
      const { bankAccounts, profile } = getState() as {
        bankAccounts: BankAccountsState;
        profile: ProfileState;
      };
      const { person, business } = profile;

      const fields = mapCreateBankAccountFields({
        data,
        person,
        business,
        bankAccounts,
      });

      const formData = buildFormData(fields);

      const bankAccountId = await addBankAccounts(formData);

      if (bankAccountId) {
        const accounts: any = await getBankAccounts(bankAccountId);
        return accounts.length ? accounts[0] : null;
      }

      return null;
    } catch (e: any) {
      if (
        getResponseErrorMessages(e.response, true) ===
        'This account already exists and is active'
      ) {
        return rejectWithValue(getResponseErrorMessages(e.response));
      }

      return Promise.reject(e);
    }
  },
);

export const updateBankAccount: any = createAsyncThunk(
  'bankAccounts/updateBankAccount',
  async (data: any, thunkApi) => {
    const { getState, rejectWithValue } = thunkApi;

    try {
      const { bankAccounts, profile } = getState() as {
        bankAccounts: BankAccountsState;
        profile: ProfileState;
      };

      const { emailAddress } = profile.person;

      const fields = mapUpdateBankAccountFields({
        bankAccounts,
        data,
        emailAddress,
      });

      if (!fields) {
        return null;
      }

      const formData = buildFormData(fields);

      const bankAccountId: any = await updateBankAccounts(formData);

      if (bankAccountId) {
        const result: any = await getBankAccounts(bankAccountId);

        if (result?.[0]) {
          const accounts = bankAccounts.bankAccounts
            .slice()
            .map((acc) =>
              acc.bankAccountsId === bankAccountId ? result[0] : acc,
            );

          return {
            bankAccounts: accounts,
            bankAccount: result[0],
          };
        }
      }

      return null;
    } catch (e: any) {
      if (
        getResponseErrorMessages(e.response, true) ===
        'This account already exists and is active'
      ) {
        return rejectWithValue(getResponseErrorMessages(e.response));
      }

      return Promise.reject(e);
    }
  },
);

export const saveVendorCredentials: any = createAsyncThunk(
  'bankAccounts/saveVendorCredentials',
  async (json: any, thunkAPI) => {
    try {
      const state = thunkAPI.getState() as RootState;

      const { integrator, directoryId, bankAccount, vendorCredentials } =
        getBankAccountState(state);
      const { emailAddress } = getPerson(state);

      const businessesId = getBusinessesId();

      if (vendorCredentials) {
        await updateVendorCredentials(
          integrator.integratorsId,
          directoryId,
          vendorCredentials.bankAccounts_JsonId,
          { json, businessesId, isActive: true, modifiedBy: emailAddress },
        );

        return vendorCredentials;
      } else {
        const result: any = await addVendorCredentials(
          integrator.integratorsId,
          directoryId,
          { json, businessesId },
        );

        if (result?.id) {
          await linkVendorCredentialsToBankAccount(
            integrator.integratorsId,
            directoryId,
            result.id,
            bankAccount.bankAccountsId,
          );

          return {
            bankAccounts_JsonId: result.id,
            vendorCredentials: JSON.parse(window.atob(json)),
            createdOn: new Date().toISOString(),
          };
        }
      }

      return null;
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
);

export const updateBankAccountRanges: any = createAsyncThunk(
  'bankAccounts/updateBankAccountRanges',
  async (
    {
      ranges,
      omitted,
    }: { ranges: BankAccountCheckRangeType[]; omitted: BankAccountCheckType[] },
    thunkAPI,
  ) => {
    try {
      const state = thunkAPI.getState() as RootState;

      const { bankAccountsId } = getBankAccount(state);
      const { emailAddress } = getPerson(state);

      const checkRanges: BankAccountCheckRangeType[] =
        getBankAccountCheckRanges(state);
      const checkOmitted: BankAccountCheckType[] = getBankAccountChecks(state);

      const mappedRanges: BankAccountCheckRangeType[] = mapCheckRanges(
        ranges,
        checkRanges,
        { bankAccountsId, emailAddress },
      );

      const mappedOmitted: BankAccountCheckType[] = mapChecks(
        omitted,
        checkOmitted,
        { bankAccountsId, emailAddress },
      );

      if (mappedRanges.length || mappedOmitted.length) {
        await addUpdateBanksAccountsChecksAndRanges({
          checkRanges: mappedRanges,
          checkNumberOmits: mappedOmitted,
          modifiedBy: emailAddress,
          bankAccountsId,
        });
      }
    } catch (e: any) {
      const error = getResponseErrorMessages(e.response, true);

      if (error) {
        return thunkAPI.rejectWithValue(error);
      }

      return Promise.reject(e);
    }
  },
);

export const removeBankAccountFile: any = createAsyncThunk(
  'bankAccounts/removeBankAccountFile',
  async (_: void, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { bankAccounts, profile } = getState() as {
        bankAccounts: BankAccountsState;
        profile: ProfileState;
      };

      if (bankAccounts.bankAccount) {
        await updateBankAccountsBlobReference({
          bankAccountsId: bankAccounts.bankAccount.bankAccountsId,
          modifiedBy: profile.person.emailAddress,
          isLogging: true,
        });
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const connectBankAccount: any = createAsyncThunk(
  'bankAccounts/connectBankAccount',
  async (account: BankAccount, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const state = getState() as ProfileState;

      const { emailAddress } = getPerson(state);

      const fields: Partial<BankAccount> = {
        bankAccountsId: account.bankAccountsId,
        bankAccountStatusId: BankAccountStatusIds.connected,
        businessesId: account.businessesId,
        modifiedBy: emailAddress,
      };

      const formData = buildFormData(fields);

      const bankAccountId: any = await updateBankAccounts(formData);

      if (bankAccountId) {
        const bankAccounts = getAccounts(state);
        const accounts = bankAccounts.slice();

        return accounts.map((bankAccount: BankAccount) => {
          if (bankAccount.bankAccountsId === bankAccountId) {
            return {
              ...bankAccount,
              ...fields,
            };
          }

          return bankAccount;
        });
      }

      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const disconnectBankAccount: any = createAsyncThunk(
  'bankAccounts/disconnectBankAccount',
  async (bankAccountsId: number, thunkApi) => {
    try {
      const { bankAccounts, profile, configuration } = thunkApi.getState() as {
        bankAccounts: BankAccountsState;
        profile: ProfileState;
        configuration: ConfigurationState;
      };

      const fields = {
        bankAccountsId,
        modifiedBy: profile.person.emailAddress,
        isActive: false,
      };

      const bankAccountId: any = await updateIsActiveBankAccounts(fields);
      const accounts = bankAccounts.bankAccounts.slice();

      if (configuration.bpsTrack?.bankAccountId === bankAccountId) {
        thunkApi.dispatch(setDefaultBankAccountId(0));
        thunkApi.dispatch(
          updateBPSTrack({
            ...configuration.bpsTrack,
            bankAccountId: 0,
            modifiedBy: profile.person.emailAddress,
          }),
        );
      }

      return accounts.filter(
        (account) => account.bankAccountsId !== bankAccountId,
      );
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const downloadFile: any = createAsyncThunk(
  'businessInfo/downloadFile',
  async ({ blobId, bankAccountId, fileName }: any) => {
    try {
      const businessId: number = getBusinessesId();

      const file: any = await getBankAccountBlobFile(
        businessId,
        bankAccountId,
        blobId,
      );

      fileDownload(file, fileName);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const downloadGuide: any = createAsyncThunk(
  'businessInfo/downloadGuide',
  async ({ guid, filename }: any) => {
    try {
      const file: any = await getBankIntegratorsFile(guid);

      fileDownload(file, filename);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createFundingAccount: any = createAsyncThunk(
  'bankAccounts/createFundingAccount',
  async (data: any, thunkAPI) => {
    try {
      const businessesId: number = getBusinessesId();
      const person = getPerson(thunkAPI.getState() as RootState);

      const formData = buildFormData({
        businessesId,
        createdBy: person.emailAddress,
        isActive: true,
        banksIntegratorsId: 0,
        integratorDirectoryId: 0,
      });

      const bankAccountId = await addBankAccounts(formData);

      const bankAccounts: any = await getBankAccounts(bankAccountId);

      if (!bankAccounts?.length) {
        return null;
      }

      const parsePhone = parsePhoneNumber(data.phone);

      const phoneNumberId = await addTelephoneNumbers({
        countryCallingCode: `+${parsePhone?.countryCallingCode || 1}-`,
        telephoneNumber: `${parsePhone?.nationalNumber || ''}`,
        createdBy: person.emailAddress,
      });

      const emailAddressId = await addEmailAddress({
        emailAddress: data.email,
        createdBy: person.emailAddress,
      });

      await addFundingAccount(bankAccounts[0].referenceId, {
        bank: {
          createdBy: person.emailAddress,
          telephoneNumbersId: phoneNumberId,
          emailAddressesId: emailAddressId,
          note: data.note,
        },
      });

      return bankAccounts[0];
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchFundingAccount: any = createAsyncThunk(
  'bankAccounts/fetchFundingAccount',
  async (referenceId: string) => {
    try {
      const result = await getFundingAccount(referenceId);
      return { ...result, referenceId };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const saveFundingAccount: any = createAsyncThunk(
  'bankAccounts/saveFundingAccount',
  async (data: any, thunkAPI) => {
    try {
      const person = getPerson(thunkAPI.getState() as RootState);

      const { referenceId, id, ...payload } = data;

      const parsePhone = parsePhoneNumber(payload.phone);

      const phoneNumberId = await addTelephoneNumbers({
        countryCallingCode: `+${parsePhone?.countryCallingCode || 1}-`,
        telephoneNumber: `${parsePhone?.nationalNumber || ''}`,
        createdBy: person.emailAddress,
      });

      const emailAddressId = await addEmailAddress({
        emailAddress: payload.email,
        createdBy: person.emailAddress,
      });

      const result = await updateFundingAccount(referenceId, id, {
        bank: {
          modifiedBy: person.emailAddress,
          telephoneNumbersId: phoneNumberId,
          emailAddressesId: emailAddressId,
          note: payload.note,
        },
      });

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
