import { useTranslation } from 'react-i18next';

import AuthorizedSignatory from 'components/AuthorizedSignatory';
import TermsAgreement from './TermsAgreement';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getAuthorizing } from '../selectors';
import { getBusinessSignatory } from 'pages/Profile/selectors';
import { authorizeSignatory } from 'pages/Profile/thunks';

const AcceptTerms = () => {
  const { t } = useTranslation('dashboard');
  const signatory: any = useAppSelector(getBusinessSignatory);
  const authorizing = useAppSelector(getAuthorizing);
  const dispatch = useAppDispatch();

  const handleAuthorize = () => {
    dispatch(authorizeSignatory());
  };

  return (
    <div className="p-6">
      <p className="heading-2xl mb-1">
        {t('dashboard:submitModal.acceptTitle')}
      </p>
      <p className="text-secondary mb-9">
        {t('dashboard:submitModal.acceptSubTitle')}
      </p>

      <AuthorizedSignatory
        signatory={signatory}
        loading={authorizing}
        onClick={handleAuthorize}
      />
      <TermsAgreement />
    </div>
  );
};

export default AcceptTerms;
