import api from 'api';

export const searchBusinessesDocuments = async (data) => {
  return api.post('BusinessesDocuments/SearchBusinessesDocuments', data);
};

export const updateBusinessesDocuments = async (data) => {
  return api.put('BusinessesDocuments/UpdateBusinessesDocuments', data);
};

export const deleteBusinessesDocuments = async (id, modifiedBy) => {
  return api.delete(
    `BusinessesDocuments/DeleteBusinessesDocuments/${id}/${modifiedBy}`,
  );
};

export const getBusinessesDocuments = async (id) => {
  const documents: any = await api.get(
    `BusinessesDocuments/GetBusinessesDocuments/${id}`,
  );
  return documents.length ? documents[0] : null;
};

export const getBusinessesDocumentsFile = (guid) => {
  return api.get(`BusinessesDocuments/GetBusinessesDocumentsFile/${guid}`, {
    responseType: 'blob',
  });
};

export const addBusinessesDocuments = async (data, options = {}) => {
  return api.post('BusinessesDocuments/AddBusinessesDocuments', data, options);
};

export const searchERPPreferencesData = async (data) => {
  return api.post('ErppreferencesDatas/SearchErppreferencesDatas', data);
};

export const updateERPPreferencesData = async (data) => {
  return api.put('ErppreferencesDatas/UpdateErppreferencesDatas', data);
};

export const addERPPreferencesData = async (data) => {
  return api.post('ErppreferencesDatas/AddERPPreferencesDatas', data);
};
