import api from 'api';

export const getBusinessesOwnersPersons = async (id) => {
  return api.get(`BusinessesOwnersPersons/GetBusinessesOwnersPersons/${id}`);
};

export const getBusinessesOwnersBusinesses = async (id) => {
  return api.get(
    `BusinessesOwnersBusinesses/GetBusinessesOwnersBusinesses/${id}`,
  );
};

export const addBusinessesOwnersPersons = async (data) => {
  return api.post('BusinessesOwnersPersons/AddBusinessesOwnersPersons', data);
};

export const addBusinessesOwnersBusinesses = async (data) => {
  return api.post(
    'BusinessesOwnersBusinesses/AddBusinessesOwnersBusinesses',
    data,
  );
};

export const addBusinessesOwnersBlobReference = async (data, options = {}) => {
  return api.post(
    'BusinessesOwnersBlobReferences/AddBusinessesOwnersBlobReference',
    data,
    options,
  );
};

export const getBusinessesOwnersBlobReference = async (id) => {
  return api.get(
    `BusinessesOwnersBlobReferences/GetBusinessesOwnersBlobReference/${id}`,
  );
};

export const getBusinessesOwnersBlobReferenceFile = async (id) => {
  return api.get(
    `BusinessesOwnersBlobReferences/GetBusinessesOwnersBlobReferenceFile/${id}`,
    {
      responseType: 'blob',
    },
  );
};

export const getBusinessesOwnersPersonsFile = async (id) => {
  return api.get(
    `BusinessesOwnersPersons/GetBusinessesOwnersPersonsFile/${id}`,
    {
      responseType: 'blob',
    },
  );
};

export const updateBusinessesOwnersPersons = async (data) => {
  return api.put('BusinessesOwnersPersons/UpdateBusinessesOwnersPersons', data);
};

export const updateIsActiveBusinessesOwnersPersons = async (data) => {
  return api.put(
    'BusinessesOwnersPersons/UpdateIsActiveBusinessesOwnersPersons',
    data,
  );
};

export const updateIsActiveBusinessesOwnersBusinesses = async (data) => {
  return api.put(
    'BusinessesOwnersBusinesses/UpdateIsActiveBusinessesOwnersBusinesses',
    data,
  );
};

export const updateBusinessesOwnersBusinesses = async (data) => {
  return api.put(
    'BusinessesOwnersBusinesses/UpdateBusinessesOwnersBusinesses',
    data,
  );
};

export const searchBusinessesOwnersPersons = async (data) => {
  return api.post(
    'BusinessesOwnersPersons/SearchBusinessesOwnersPersons',
    data,
  );
};

export const updateBusinessesOwnersPersonsBlobReference = async (data) => {
  return api.put(
    'BusinessesOwnersPersons/UpdateBusinessesOwnersPersonsBlobReference',
    data,
  );
};

export const searchBusinessesOwnersBusinesses = async (data) => {
  return api.post(
    'BusinessesOwnersBusinesses/SearchBusinessesOwnersBusinesses',
    data,
  );
};

export const searchBusinessesOwnersBlobReferences = async (data) => {
  return api.post(
    'BusinessesOwnersBlobReferences/SearchBusinessesOwnersBlobReference',
    data,
  );
};

export const updateIsActiveBusinessesOwnersBlobReference = async (data) => {
  return api.put(
    'BusinessesOwnersBlobReferences/UpdateIsActiveBusinessesOwnersBlobReference',
    data,
  );
};
