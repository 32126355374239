import { sortBy, prop } from 'ramda';

import { BusinessProps, PersonProps } from 'entities/profile';
import { prysymProcessor } from 'entities/merchantServices';
import { calcProcentege } from 'pages/dashboard/utils';
import { BlobFileTypes } from 'entities/dashboard';
import { lowercaseKeys } from 'utils/helpers';
import { formatDate } from 'utils/date';

type BlobDataProps = {
  file: File;
  person: PersonProps | Record<string, never>;
  business: BusinessProps | Record<string, never>;
  blobReferencesTypesId: number;
};

export const mapBlobReference = (blob) => ({
  ...blob,
  id: blob.businessesFinancialsBlobReferencesId,
  blobGUID: blob.blobGuid,
});

export const getBlobFormData = ({
  file,
  person,
  business,
  blobReferencesTypesId,
}: BlobDataProps) => {
  const fileName = file.name.split('.');

  const formData = new FormData();

  formData.append('file', file);
  formData.append('blobFileSize', file.size.toString());
  formData.append('blobFileName', file.name);
  formData.append('blobFileExtension', fileName[1]);
  formData.append('isActive', 'true');
  formData.append('createdBy', person.emailAddress);
  formData.append('businessesId', business.businessesId.toString());
  formData.append('blobReferencesTypesId', blobReferencesTypesId.toString());

  return formData;
};

export const getParsedBusinessFinancialJsonData = (businessFinancial) => {
  const blobReferences =
    businessFinancial &&
    businessFinancial.businessesFinancialsBlobReferencesJson
      ? lowercaseKeys(
          JSON.parse(businessFinancial.businessesFinancialsBlobReferencesJson),
        ).map((blob) => mapBlobReference(blob))
      : [];

  const cardsAccepted =
    businessFinancial && businessFinancial.businessesFinancialsCardsAcceptedJson
      ? lowercaseKeys(
          JSON.parse(businessFinancial.businessesFinancialsCardsAcceptedJson),
        )
      : [];

  const delayedSales =
    businessFinancial && businessFinancial.businessesFinancialsDelayedSalesJson
      ? lowercaseKeys(
          JSON.parse(businessFinancial.businessesFinancialsDelayedSalesJson),
        )
      : [];

  const yearlyGrowth =
    businessFinancial && businessFinancial.businessesFinancialsYearlyGrowthJson
      ? lowercaseKeys(
          JSON.parse(businessFinancial.businessesFinancialsYearlyGrowthJson),
        )
      : [];

  const geographicSales =
    businessFinancial &&
    businessFinancial.businessesFinancialsGeographicSalesJson
      ? lowercaseKeys(
          JSON.parse(businessFinancial.businessesFinancialsGeographicSalesJson),
        )
      : [];

  return {
    blobReferences,
    cardsAccepted,
    delayedSales,
    yearlyGrowth,
    geographicSales,
  };
};

export const mappedBusinessFinancial = (financial) => ({
  ...financial,
  dateOfIncorporation: financial.dateOfIncorporation
    ? new Date(financial.dateOfIncorporation)
    : null,
  dateofGoLive: financial.dateofGoLive
    ? new Date(financial.dateofGoLive)
    : null,
  pcidate: financial.pcidate ? new Date(financial.pcidate) : null,
});

export const mappedPciCompliants = (compliants) =>
  compliants.slice().map((compliant) => ({
    name: compliant.description,
    value: compliant.pCICompliantId,
  }));

export const mappedMerchantCategoryCodes = (codes) =>
  sortBy(prop('label'))(
    codes.slice().map((code) => ({
      name: code.description,
      label: code.description,
      value: code.merchantCategoryCodesId,
    })),
  );

export const mappedOperatingStatuses = (statuses) =>
  statuses.slice().map((status) => ({
    name: status.description,
    value: status.operatingStatusId,
    typeCode: status.typeCode,
  }));

export const mappedSubscriptionsHowOften = (subscriptions) =>
  subscriptions.slice().map((subscription) => ({
    name: subscription.description,
    value: subscription.subscriptionsHowOftenId,
  }));

const mapBusinessFinancialFields = (data) => {
  const {
    businessesFinancialsDelayedSales,
    businessesFinancialsGeographicSales,
    businessesFinancialsYearlyGrowth,
    dateOfIncorporation,
    dateofGoLive,
    pcidate,
    isPciCompliant,
    pcicompliantId,
    numberOfEmployes,
    percentageGrowth,
    businessSalesVolumeTurnover,
    chargebackHistoryPercentage,
    refundHistoryPercentage,
    averageTicketTransaction,
    highestTicketTransaction,
    deliveryPeriodFromPurchase,
    volumeLast12Months,
    ...other
  } = data;

  const mappedSales = (businessesFinancialsDelayedSales || []).map((item) => ({
    ...item,
    percentageDelayed: item.percentageDelayed || 0,
  }));

  const mappedGeographic = (businessesFinancialsGeographicSales || []).map(
    (item) => ({
      ...item,
      percentageEstimate: item.percentageEstimate || 0,
    }),
  );

  const mappedYearlyGrowth = (businessesFinancialsYearlyGrowth || []).map(
    (item) => ({
      ...item,
      salesVolume: item.salesVolume || 0,
      salesCount: item.salesCount || 0,
      refundPercentage: item.refundPercentage || 0,
      chargebackPercentage: item.chargebackPercentage || 0,
      transactionHighest: item.transactionHighest || 0,
      transactionAverage: item.transactionAverage || 0,
    }),
  );

  return {
    ...other,
    isActive: true,
    numberOfEmployes: numberOfEmployes || 0,
    percentageGrowth: percentageGrowth || 0,
    businessSalesVolumeTurnover: businessSalesVolumeTurnover || 0,
    chargebackHistoryPercentage: chargebackHistoryPercentage || 0,
    refundHistoryPercentage: refundHistoryPercentage || 0,
    averageTicketTransaction: averageTicketTransaction || 0,
    highestTicketTransaction: highestTicketTransaction || 0,
    deliveryPeriodFromPurchase: deliveryPeriodFromPurchase || 0,
    volumeLast12Months: volumeLast12Months || 0,
    businessesFinancialsBlobReferences: [],
    businessesFinancialsDelayedSales: mappedSales,
    businessesFinancialsGeographicSales: mappedGeographic,
    businessesFinancialsYearlyGrowth: mappedYearlyGrowth,
    dateOfIncorporation: formatDate(dateOfIncorporation, 'YYYY-MM-DD'),
    dateofGoLive: formatDate(dateofGoLive, 'YYYY-MM-DD'),
    pcidate: formatDate(pcidate, 'YYYY-MM-DD'),
    pcicompliantId: isPciCompliant ? pcicompliantId : 0,
    parentBusinessId: null,
  };
};

export const mapUpdateBusinessFinancialFields = ({
  businessFinancial,
  data,
  person,
}) => {
  const fields = data.signatoryAttestationPersonsId
    ? { signatoryAttestationPersonsId: person.personsId }
    : mapBusinessFinancialFields(data);

  return {
    ...fields,
    modifiedBy: person.emailAddress,
    businessesFinancialsId: businessFinancial.businessesFinancialsId,
    businessesId: businessFinancial.businessesId,
  };
};

export const mapCreateBusinessFinancialFields = ({
  data,
  business,
  person,
}) => {
  const fields = mapBusinessFinancialFields(data);

  return {
    ...fields,
    signatoryAttestationPersonsId: data.signatoryAttestationPersonsId
      ? person.personsId
      : null,
    businessesId: business.businessesId,
    createdBy: person.emailAddress,
  };
};

export const documents = [
  {
    id: 1,
    title: 'financial:documents.shareholder.title',
    text: 'financial:documents.shareholder.text',
    optional: true,
    type: BlobFileTypes.shareholder,
  },
  {
    id: 2,
    title: 'financial:documents.accountStatement.title',
    text: 'financial:documents.accountStatement.text',
    optional: false,
    type: BlobFileTypes.bankAccountStatement,
  },
  {
    id: 3,
    title: 'financial:documents.auditedFinancials.title',
    text: 'financial:documents.auditedFinancials.text',
    optional: false,
    type: BlobFileTypes.auditedFinancials,
  },
  {
    id: 4,
    title: 'financial:documents.taxIdentification.title',
    text: ' ',
    optional: true,
    type: BlobFileTypes.federalTaxId,
  },
  {
    id: 5,
    title: 'financial:documents.certificate.title',
    text: 'financial:documents.certificate.text',
    optional: true,
    type: BlobFileTypes.certificate,
  },
  {
    id: 6,
    title: 'financial:documents.sourceOfFunds.title',
    text: 'financial:documents.sourceOfFunds.text',
    optional: false,
    type: BlobFileTypes.sourceOfFunds,
  },
  {
    id: 7,
    title: 'financial:documents.accountSignatory.title',
    text: ' ',
    optional: true,
    type: BlobFileTypes.accountSignatory,
  },
  {
    id: 8,
    title: 'financial:documents.interimFinancials.title',
    text: ' ',
    optional: true,
    type: BlobFileTypes.interimFinancials,
  },
  {
    id: 9,
    title: 'financial:documents.bankLetter.title',
    text: 'financial:documents.bankLetter.text',
    optional: false,
    type: BlobFileTypes.letterFromBank,
  },
];

const requiredFiles = ({
  blobReferences,
  regulatedService,
  acceptedCardPaymentslast24Months,
  customerTermsConditionsPosted,
}) => {
  const files = {};

  documents.map((doc) => {
    if (!doc.optional) {
      files[doc.type] = blobReferences.find(
        (blob) => blob.typeCode === doc.type,
      );
    }
  });

  if (regulatedService) {
    files[BlobFileTypes.license] = blobReferences.find(
      (blob) => blob.typeCode === BlobFileTypes.license,
    );
  }

  if (acceptedCardPaymentslast24Months) {
    files[BlobFileTypes.bankAccountStatement6] = blobReferences.find(
      (blob) => blob.typeCode === BlobFileTypes.bankAccountStatement6,
    );
  }

  if (customerTermsConditionsPosted) {
    files[BlobFileTypes.termsAndConditions] = blobReferences.find(
      (blob) => blob.typeCode === BlobFileTypes.termsAndConditions,
    );
  }

  return files;
};

const requiredFields = ({
  parentCompany,
  dateOfIncorporation,
  currencyCodesId,
  shortBusinessProductLineDescription,
  sourceOfFunds,
  industry,
  numberOfEmployes,
  businessSalesVolumeTurnover,
  operatingStatusId,
  regulatedService,
  acceptedCardPaymentslast24Months,
  dataBreach,
  violationNotice,
  currentPaymentProcessingServiceProviders,
  chargebackHistoryPercentage,
  refundHistoryPercentage,
  averageTicketTransaction,
  highestTicketTransaction,
  deliveryPeriodFromPurchase,
  pcidate,
  pcicompliantId,
  volumeLast12Months,
  acceptOrdersInAdvance,
  geographicSales,
  purchaseProcess,
  subscriptionBasedPayments,
  customerTermsConditionsPosted,
  yearlyGrowth,
  blobReferences,
  violationDescription,
  breachDescription,
  dateofGoLive,
  serviceProviders,
  delayedSales,
  cardsAccepted,
  subscriptionsHowOftenId,
  currentProcessor,
  merchant,
}) => {
  const acceptedCardPayments = acceptedCardPaymentslast24Months
    ? {
        dataBreach: dataBreach ? breachDescription : true,
        violationNotice: violationNotice ? violationDescription : true,
        currentPaymentProcessingServiceProviders:
          currentPaymentProcessingServiceProviders ? serviceProviders : true,
        chargebackHistoryPercentage,
        refundHistoryPercentage,
        averageTicketTransaction,
        highestTicketTransaction,
        deliveryPeriodFromPurchase,
        cardsAccepted: cardsAccepted.some(
          ({ last24Months, last12Months }) => last24Months || last12Months,
        ),
      }
    : {};

  const yearlyGrowthFields = yearlyGrowth.reduce(
    (
      acc,
      {
        chargebackPercentage,
        refundPercentage,
        salesCount,
        salesVolume,
        transactionAverage,
        transactionHighest,
      },
      index,
    ) => ({
      ...acc,
      [`chargebackPercentage${index}`]: chargebackPercentage,
      [`refundPercentage${index}`]: refundPercentage,
      [`salesCount${index}`]: salesCount,
      [`salesVolume${index}`]: salesVolume,
      [`transactionAverage${index}`]: transactionAverage,
      [`transactionHighest${index}`]: transactionHighest,
    }),
    {},
  );

  const filesUploaded = requiredFiles({
    blobReferences,
    regulatedService,
    acceptedCardPaymentslast24Months,
    customerTermsConditionsPosted,
  });

  if (currentProcessor !== prysymProcessor.value) {
    const merchantFields: any = {};

    if (currentProcessor) {
      merchantFields.merchantFirstName = merchant.contact?.firstName ?? null;
      merchantFields.merchantLastName = merchant.contact?.lastName ?? null;
      merchantFields.merchantPhone = merchant.contact?.phone ?? null;
      merchantFields.merchantEmail = merchant.contact?.email ?? null;

      const dynamicFields = JSON.parse(merchant.processingInfo?.json || '""');

      if (dynamicFields) {
        for (const field in dynamicFields) {
          merchantFields[field] =
            typeof dynamicFields[field] === 'boolean' || field === 'platform'
              ? true
              : dynamicFields[field];
        }
      }
    }

    return {
      ...merchantFields,
      parentCompany,
      dateOfIncorporation,
      currencyCodesId,
      shortBusinessProductLineDescription,
      sourceOfFunds,
      industry,
      numberOfEmployes,
      businessSalesVolumeTurnover,
      operatingStatusId,
      ...(operatingStatusId !== 1 && { dateofGoLive: dateofGoLive }),
    };
  }

  return {
    parentCompany,
    dateOfIncorporation,
    currencyCodesId,
    shortBusinessProductLineDescription,
    sourceOfFunds,
    industry,
    numberOfEmployes,
    businessSalesVolumeTurnover,
    operatingStatusId,
    ...(operatingStatusId !== 1 && { dateofGoLive: dateofGoLive }),
    pcidate: pcidate || pcicompliantId,
    volumeLast12Months,
    purchaseProcess,
    ...yearlyGrowthFields,
    ...filesUploaded,
    subscriptionBasedPayments: subscriptionBasedPayments
      ? subscriptionsHowOftenId
      : true,
    ...acceptedCardPayments,
    acceptOrdersInAdvance: acceptOrdersInAdvance
      ? delayedSales.some(
          ({ percentageDelayed }) => Number(percentageDelayed) > 0,
        )
      : true,
    geographicSales: geographicSales.some(
      ({ percentageEstimate }) => Number(percentageEstimate) > 0,
    ),
  };
};

export const calcFinancialPercentage = (data) => {
  const { businessFinancial, blobReferences, ...rest } = data;

  const fields = requiredFields({
    ...businessFinancial,
    blobReferences,
    ...rest,
  });

  const percentage = calcProcentege(fields);

  return percentage;
};
