import { createSelector } from '@reduxjs/toolkit';

import { formatDate } from 'utils/date';

export const getRoot = (state) => state.compliance;
export const getProfile = (state) => state.profile;

export const getIsModalOpened = createSelector(
  [getRoot],
  ({ isModalOpened }) => isModalOpened,
);

export const getLoadingButton = createSelector(
  [getRoot],
  ({ loadingButton }) => loadingButton,
);

export const getRequests = createSelector(
  [getRoot],
  ({ requests }) => requests,
);

export const getRequestsTotal = createSelector(
  [getRoot],
  ({ requests }) => requests.length,
);

export const getComplianceInfo = createSelector(
  [getProfile],
  ({ business }) => ({
    businessesId: business.businessesId,
    applicationStatusID: business.applicationStatusID,
    accountholder: business.accountholder,
    participantID: business.participantID,
    erpClientID: business.erpClientID,
    complianceStatusID: business.complianceStatusID,
    complianceUpdatedBy: business.complianceUpdatedBy,
    complianceUpdateDate: formatDate(
      business.complianceUpdateDate,
      'MM-DD-YYYY HH:mm:ss',
    ),
    complianceReference: business.complianceReference,
    hubSpotCompanyId: business.hubSpotCompanyId,
    hubSpotDealId: business.hubSpotDealId,
    hubSpotDealStageId: business.hubSpotDealStageId,
    hubSpotDealStageDescription: business.hubSpotDealStageDescription,
    hubSpotCreatedDateTime: formatDate(
      business.hubSpotCreatedDateTime,
      'MM-DD-YYYY HH:mm:ss',
    ),
    DUNSNumber: business.dunsnumber,
  }),
);
