import { createSelector } from '@reduxjs/toolkit';

import { round } from 'utils/helpers';
import {
  mappedPciCompliants,
  mappedMerchantCategoryCodes,
  mappedOperatingStatuses,
  mappedSubscriptionsHowOften,
} from './utils';

export const getRoot = (state) => state.financial;
export const getDropdowns = (state) => state.dashboard.dropdowns;
export const getInvitePersons = (state) => state.invitePersons;

export const getIsFormUpdate = createSelector(
  [getRoot],
  ({ isFormUpdate }) => isFormUpdate,
);

export const getFinancialSignatory = createSelector(
  [getRoot, getInvitePersons],
  ({ businessFinancial }, { persons }) =>
    persons.find(
      (person) =>
        person.personsId === businessFinancial?.signatoryAttestationPersonsId,
    ),
);

export const getAuthorizing = createSelector(
  [getRoot],
  ({ authorizing }) => authorizing,
);

export const getIsLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getFileLoading = createSelector(
  [getRoot],
  ({ fileLoading, loadingProgress }) => ({
    type: fileLoading,
    progress: loadingProgress,
  }),
);

export const getPciCompliantId = createSelector(
  [getRoot],
  ({ businessFinancial }) => businessFinancial?.pcicompliantId || '',
);

export const getCardsAccepted = createSelector(
  [getRoot],
  ({ cardsAccepted }) => cardsAccepted,
);

export const getDelayedSales = createSelector(
  [getRoot],
  ({ delayedSales }) => delayedSales,
);

export const getSalesDelayedPaymentPeriods = createSelector(
  [getDropdowns],
  ({ salesDelayedPaymentPeriods }) => salesDelayedPaymentPeriods,
);

export const getCreditCardNetworks = createSelector(
  [getDropdowns],
  ({ creditCardNetworks }) => creditCardNetworks,
);

export const getCurrentCurrency = createSelector(
  [getRoot],
  ({ currentCurrency }) => currentCurrency,
);

export const getYearlyGrowth = createSelector(
  [getRoot],
  ({ yearlyGrowth }) => yearlyGrowth,
);

export const getBlobReferences = createSelector(
  [getRoot],
  ({ blobReferences }) => blobReferences,
);

export const getMerchantCategoryCodesId = createSelector(
  [getRoot],
  ({ businessFinancial }) => businessFinancial?.merchantCategoryCodesId || 0,
);

export const getSubscriptionsHowOftenId = createSelector(
  [getRoot],
  ({ businessFinancial }) => businessFinancial?.subscriptionsHowOftenId || 0,
);

export const getGeographicSales = createSelector(
  [getRoot],
  ({ geographicSales }) => geographicSales,
);

export const getOperatingStatusId = createSelector(
  [getRoot],
  ({ businessFinancial }) => businessFinancial?.operatingStatusId || 0,
);

export const getGeographicSaleTypes = createSelector(
  [getDropdowns],
  ({ businessesFinancialsGeographicSalesTypes }) =>
    businessesFinancialsGeographicSalesTypes,
);

export const getMappedPciCompliants = createSelector(
  [getDropdowns],
  ({ pciCompliant }) => mappedPciCompliants(pciCompliant),
);

export const getMappedOperatingStatuses = createSelector(
  [getDropdowns],
  ({ operatingStatus }) => mappedOperatingStatuses(operatingStatus),
);

export const getMappedSubscriptionsHowOften = createSelector(
  [getDropdowns],
  ({ subscriptionsHowOften }) =>
    mappedSubscriptionsHowOften(subscriptionsHowOften),
);

export const getMappedMerchantCategoryCodes = createSelector(
  [getDropdowns],
  ({ merchantCategoryCodes }) =>
    mappedMerchantCategoryCodes(merchantCategoryCodes),
);

export const getBusinessFinancial = createSelector(
  [getRoot],
  ({ businessFinancial }) => businessFinancial,
);

export const getBusinessFinancialInfo = createSelector(
  [getRoot, getDropdowns],
  ({ businessFinancial, currentCurrency }, { currencyCodes }) => {
    const defaultCurrency = currencyCodes.find(
      (currency) => currency.alphaCode === currentCurrency,
    );

    return {
      parentCompany: businessFinancial?.parentCompany || '',
      pcicompliantId: businessFinancial?.pcicompliantId || 0,
      isPciCompliant: businessFinancial?.pcicompliantId > 0 || false,
      dateOfIncorporation: businessFinancial?.dateOfIncorporation || '',
      dateofGoLive: businessFinancial?.dateofGoLive || '',
      pcidate: businessFinancial?.pcidate || '',
      percentageGrowth: businessFinancial?.percentageGrowth || '',
      merchantCategoryCodesId: businessFinancial?.merchantCategoryCodesId || 0,
      currencyCodesId:
        businessFinancial?.currencyCodesId ||
        defaultCurrency?.currencyCodeID ||
        0,
      shortBusinessProductLineDescription:
        businessFinancial?.shortBusinessProductLineDescription || '',
      sourceOfFunds: businessFinancial?.sourceOfFunds || '',
      industry: businessFinancial?.industry || '',
      numberOfEmployes: businessFinancial?.numberOfEmployes || '',
      businessSalesVolumeTurnover:
        round(businessFinancial?.businessSalesVolumeTurnover) || '',
      operatingStatusId: businessFinancial?.operatingStatusId || 0,
      businessStatusesId: businessFinancial?.businessStatusesId || 0,
      regulatedService: businessFinancial?.regulatedService || false,
      acceptedCardPaymentslast24Months:
        businessFinancial?.acceptedCardPaymentslast24Months || false,
      dataBreach: businessFinancial?.dataBreach || false,
      breachDescription: businessFinancial?.breachDescription || '',
      violationNotice: businessFinancial?.violationNotice || false,
      violationDescription: businessFinancial?.violationDescription || '',
      currentPaymentProcessingServiceProviders:
        businessFinancial?.currentPaymentProcessingServiceProviders || false,
      serviceProviders: businessFinancial?.serviceProviders || '',
      chargebackHistoryPercentage:
        businessFinancial?.chargebackHistoryPercentage || '',
      refundHistoryPercentage: businessFinancial?.refundHistoryPercentage || '',
      averageTicketTransaction:
        round(businessFinancial?.averageTicketTransaction) || '',
      highestTicketTransaction:
        round(businessFinancial?.highestTicketTransaction) || '',
      deliveryPeriodFromPurchase:
        businessFinancial?.deliveryPeriodFromPurchase || '',
      volumeLast12Months: businessFinancial?.volumeLast12Months || '',
      acceptOrdersInAdvance: businessFinancial?.acceptOrdersInAdvance || false,
      purchaseProcess: businessFinancial?.purchaseProcess || '',
      subscriptionBasedPayments:
        businessFinancial?.subscriptionBasedPayments || false,
      subscriptionsHowOftenId: businessFinancial?.subscriptionsHowOftenId || 0,
      customerTermsConditionsPosted:
        businessFinancial?.customerTermsConditionsPosted || false,
      voidedCheckForAbadda: businessFinancial?.voidedCheckForAbadda || false,
    };
  },
);
