import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Fragment } from 'react';
import moment from 'moment';

import { FileIcon, UploadIcon, TrashOutlinedIcon } from 'components/icons';
import PermissionSection from 'components/permission/PermissionSection';
import { closeDialog, openDialog } from 'components/dialog/modalSlice';
import DangerDialog from 'components/dialog/templates/DangerDialog';
import { useAppDispatch, useAppSelector } from 'hooks';
import HideComponent from 'components/HideComponent';
import { Permissions } from 'entities/dashboard';
import { getTheme } from 'theme/selectors';
import { getFileSize } from 'utils/files';
import Button from 'components/Button';

type FileProps = {
  id: number;
  blobFileSize: number;
  blobFileName: string;
  blobFileExtension: string;
  description: string;
  createdOn?: string;
  blogFileName?: string;
};

interface FilesProps {
  file: FileProps;
  hideSize?: boolean;
  hideDate?: boolean;
  hideUploadButton?: boolean;
  readOnly?: boolean;
  size?: string;
  onDelete: (id: number) => void;
  onDownload?: (name: string) => void;
  handleSelect?: () => void;
  isMultiple?: boolean;
  permission?: Permissions;
}

const File: React.FC<FilesProps> = ({
  file,
  hideSize = false,
  hideDate = false,
  hideUploadButton = false,
  readOnly = false,
  size,
  onDelete,
  onDownload,
  handleSelect,
  isMultiple = false,
  permission,
}) => {
  const { t } = useTranslation('common');
  const dispatch = useAppDispatch();

  const { textColor } = useAppSelector(getTheme);

  const fileName = `${file.blobFileName ?? file.blogFileName}`;

  const handleDownload = () => {
    if (onDownload) {
      onDownload(fileName);
    }
  };

  const closeConfirmationDialog = () => {
    dispatch(closeDialog());
  };

  const handleDelete = () => {
    dispatch(
      openDialog({
        maxWidth: '512px',
        onClose: closeConfirmationDialog,
        onCancel: closeConfirmationDialog,
        content: (
          <DangerDialog
            title={t('confirmationText')}
            buttonText={t('delete')}
            onCancel={closeConfirmationDialog}
            onConfirm={() => {
              onDelete(file.id);
              closeConfirmationDialog();
            }}
          />
        ),
      }),
    );
  };

  return (
    <div>
      <div
        className={`flex items-center mt-5 w-full${
          isMultiple ? ' border-b border-gray-200 pb-4' : ''
        }`}
      >
        <FileIcon
          styles={{
            color: textColor.primary.base,
          }}
          className="flex-shrink-0"
        />
        <div className="ml-2.5 w-full flex items-center justify-between">
          <div>
            <button
              type="button"
              className={classNames(
                'font-medium cursor-pointer text-left',
                size === 'small' ? 'text-sm' : 'leading-6',
              )}
              onClick={handleDownload}
            >
              {file?.description}
            </button>
            <div className="flex flex-wrap items-center">
              <span
                className={classNames(
                  size === 'small' ? 'text-xs text-gray-500' : 'text-secondary',
                  'break-all mt-1',
                )}
              >
                {fileName}
              </span>
              <HideComponent hide={hideSize}>
                <Fragment>
                  <span className="bg-gray-400 w-1.5 h-1.5 mx-3 rounded-full flex-shrink-0" />
                  <span className="text-secondary flex-shrink-0 mt-1">
                    {getFileSize(file?.blobFileSize || 0)}
                  </span>
                </Fragment>
              </HideComponent>

              <HideComponent hide={hideDate}>
                <Fragment>
                  <span className="bg-gray-400 w-1.5 h-1.5 mx-3 rounded-full flex-shrink-0 mt-1" />
                  <span className="text-secondary flex-shrink-0">
                    {t('common:fileUploadDate')}{' '}
                    {moment(file?.createdOn).format('MM-DD-YYYY')}
                  </span>
                </Fragment>
              </HideComponent>
            </div>
          </div>
          <HideComponent hide={readOnly && !permission}>
            <PermissionSection permission={permission} edit showPopover>
              <div className="flex items-center ml-5 flex-shrink-0">
                <HideComponent hide={hideUploadButton}>
                  <Button
                    size="medium-large"
                    variant="link-secondary"
                    onClick={handleSelect}
                    className="flex-shrink-0"
                    disabled={readOnly}
                  >
                    <UploadIcon className="mr-1.5" />
                    {t('common:fileUploadAnother')}
                  </Button>
                </HideComponent>

                <Button
                  size="medium-large"
                  variant="link-red"
                  onClick={handleDelete}
                  className="flex-shrink-0"
                  disabled={readOnly}
                >
                  <TrashOutlinedIcon className="mr-1.5" />
                  {t('common:delete')}
                </Button>
              </div>
            </PermissionSection>
          </HideComponent>
        </div>
      </div>
    </div>
  );
};

export default File;
