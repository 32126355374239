import { FieldValues, useForm } from 'react-hook-form';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import OnboardingTabHeader from 'pages/dashboard/components/OnboardingTabHeader';
import { getShowErrors } from 'pages/dashboard/components/Progress/selectors';
import { MailIcon, PhoneIcon, InfoCircleSolidIcon } from 'components/icons';
import Link from 'components/Link';
import ErrorMessage from 'components/Inputs/components/ErrorMessage';
import { Input, Select, PhoneInput } from 'components/Inputs';
import DoingBusinessAs from './components/DoingBusinessAs';
import CompanyImages from './components/CompanyImages';
import AddressState from 'components/AddressState';
import Alert, { AlertTypes } from 'components/Alert';
import { useAppDispatch, useAppSelector } from 'hooks';
import { getTheme } from 'theme/selectors';
import { getBusinessesAddress } from 'pages/Profile/selectors';
import { getCountries, hasPermission } from 'pages/dashboard/selectors';
import { setStep, setRedirectToDashboard } from 'pages/Profile/profileSlice';
import { setIsLoading } from 'pages/dashboard/dashboardSlice';
import { setBusinessAddress } from 'pages/Profile/profileSlice';

import {
  setFormUpdateState,
  setShowDoingBusinessAs,
} from './businessInfoSlice';
import { updateBusinessInfo, checkBusinessInfoProgress } from './thunks';
import {
  getBusinessLegalEntityFormTypes,
  getDocumentTypes,
} from 'pages/dashboard/selectors';
import { getIsFormUpdate, getBusinessInfo } from './selectors';
import { CountryISOCodes } from 'common/countries';
import path from 'common/path';
import { onboardingSteps } from 'pages/Profile/utils';
import { Permissions } from 'entities/dashboard';
import PermissionSection from 'components/permission/PermissionSection';

const BusinessInformation = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const isFormUpdate = useAppSelector(getIsFormUpdate);
  const businessesAddress = useAppSelector(getBusinessesAddress);
  const countries = useAppSelector(getCountries);
  const legalEntities = useAppSelector(getBusinessLegalEntityFormTypes);
  const businessInfo = useAppSelector(getBusinessInfo);
  const { textColor } = useAppSelector(getTheme);
  const showErrors: any = useAppSelector(getShowErrors);
  const documentTypes: any = useAppSelector(getDocumentTypes);
  const onboardingPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.onboardingBusiness),
  );

  const showError = useMemo(
    () => showErrors.businessInfo && onboardingPermissions.edit,
    [showErrors.businessInfo, onboardingPermissions.edit],
  );

  const businessDocTypes = documentTypes
    .filter((type) => type.isActive && type.idDropdown)
    .map((type) => ({
      ...type,
      value: type.businessesDocumentsTypesId,
      name: type.description,
    }));

  const { register, reset, setValue, getValues, watch, control } =
    useForm<FieldValues>({
      defaultValues: businessInfo,
    });

  const [alertIsOpened, setAlertIsOpened] = useState(true);

  useEffect(() => {
    return () => {
      if (onboardingPermissions.edit) {
        const saveBusinessInfo = async () => {
          const data = getValues();

          await dispatch(
            updateBusinessInfo({ ...data, dbaFile: businessInfo.dbaFile }),
          );

          dispatch(checkBusinessInfoProgress(data));
        };

        saveBusinessInfo();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [businessInfo.dbaFile]);

  useEffect(() => {
    if (!isFormUpdate) {
      if (businessInfo.doingBusinessAs) {
        dispatch(setShowDoingBusinessAs(true));
      }
      reset(businessInfo);
      dispatch(setFormUpdateState());
    }
  }, [businessInfo, isFormUpdate, reset, dispatch]);

  const handleAlertClick = () => {
    setAlertIsOpened(!alertIsOpened);
  };

  const handleChangeBusiness = () => {
    if (onboardingPermissions.edit) {
      dispatch(setStep(onboardingSteps.business));
      dispatch(setRedirectToDashboard(false));
      dispatch(setIsLoading(true));
    }
  };

  const handleEntityOnChange = ({ value }) => {
    setValue('businessesLegalEntityFormTypesId', value);
  };

  const handleIdTypeChange = ({ value }) => {
    setValue('businessesDocumentsTypesId', value);
  };

  const handleCountryOnChange = ({ name, value, alpha2Code }) => {
    dispatch(
      setBusinessAddress({
        ...businessesAddress,
        alpha2Code,
        country: name,
        countryIsocode: value,
      }),
    );
    setValue('countryIsocode', value);
  };

  const [
    businessLegalName,
    businessesLegalEntityFormTypesId,
    countryIsocode,
    phone,
    addressLine1,
    businessEmailAddress,
    primaryURL,
    city,
    postalCode,
    stateOrProvince,
    doingBusinessAs,
    contactEmailAddress,
    contactTelephoneNumber,
    businessesDocumentsTypesId,
    idNumber,
  ] = watch([
    'businessLegalName',
    'businessesLegalEntityFormTypesId',
    'countryIsocode',
    'phone',
    'addressLine1',
    'businessEmailAddress',
    'primaryURL',
    'city',
    'postalCode',
    'stateOrProvince',
    'doingBusinessAs',
    'contactEmailAddress',
    'contactTelephoneNumber',
    'businessesDocumentsTypesId',
    'idNumber',
  ]);

  const selectedLegalEntity = legalEntities.find(
    (entity: any) => entity.value === businessesLegalEntityFormTypesId,
  );

  const selectedCountry = countries.find(
    (c) =>
      c.iSOCode === (businessesAddress.countryIsocode || CountryISOCodes.USA),
  );

  const selectedDocumentType = businessDocTypes.find(
    (type) => type.businessesDocumentsTypesId === businessesDocumentsTypesId,
  );

  return (
    <div className="p-6">
      <PermissionSection
        permission={Permissions.onboardingBusiness}
        showMessage
      >
        <form>
          <div className="mb-5">
            <OnboardingTabHeader
              title={t('dashboard:businessInfo.title')}
              subTitle={t('dashboard:businessInfo.subTitle')}
            />
          </div>

          <div className="border-b border-gray-200 pb-5">
            <div className="flex items-end -mx-4.5">
              <Input
                register={register}
                label={t('common:fields.businessLegalName.label')}
                name="businessLegalName"
                className="w-full mx-4.5"
                readOnly={
                  (businessInfo.businessLegalName && businessInfo.dunsnumber) ||
                  !onboardingPermissions.edit
                }
                error={showError && !businessLegalName}
              />

              {alertIsOpened ? (
                <Alert
                  type={AlertTypes.info}
                  title={
                    <span
                      style={{
                        color: textColor.secondery.base,
                      }}
                    >
                      {t('dashboard:alerts.changeCompany.title')}
                      <div className="inline-block">
                        <PermissionSection
                          permission={Permissions.onboardingBusiness}
                          showPopover
                          edit
                        >
                          <Link
                            to={path.profile}
                            state={{ checkStep: false }}
                            styles={{
                              color: textColor.secondery.base,
                              ':hover': {
                                color: textColor.primary.hover,
                              },
                            }}
                            className="ml-3"
                            onClick={handleChangeBusiness}
                            disabled={!onboardingPermissions.edit}
                          >
                            {t('dashboard:alerts.changeCompany.link')}
                          </Link>
                        </PermissionSection>
                      </div>
                    </span>
                  }
                  className="mt-5 mx-4.5 flex-shrink-0"
                  paddingClass="p-11px"
                  onClose={handleAlertClick}
                  withClose
                />
              ) : (
                <button
                  onClick={handleAlertClick}
                  className="rounded-md p-11px mt-5 mx-4.5 flex-shrink-0 text-blue-400 bg-blue-50"
                >
                  <InfoCircleSolidIcon />
                </button>
              )}
            </div>
            {showError && !businessLegalName && (
              <ErrorMessage
                error={{
                  message: t(
                    'common:fields.businessLegalName.validation.required',
                  ),
                }}
              />
            )}
          </div>

          <CompanyImages />

          <div className="flex flex-wrap -mx-8">
            <div className="w-1/2 px-8">
              <Select
                label={t('dashboard:fields.entityForm.label')}
                placeholder={t('dashboard:fields.entityForm.placeholder')}
                name="businessesLegalEntityFormTypesId"
                className="w-full mt-5"
                options={legalEntities}
                onChange={handleEntityOnChange}
                value={selectedLegalEntity}
                disabled={
                  (selectedLegalEntity && businessInfo.dunsnumber) ||
                  !onboardingPermissions.edit
                }
                error={
                  showError &&
                  !selectedLegalEntity && {
                    message: t(
                      'dashboard:fields.entityForm.validation.required',
                    ),
                  }
                }
              />
              <Select
                label={t('common:fields.country.label')}
                name="countryIsocode"
                onChange={handleCountryOnChange}
                value={selectedCountry}
                className="w-full mt-5"
                options={countries}
                disabled={
                  (businessInfo.countryIsocode && businessInfo.dunsnumber) ||
                  !onboardingPermissions.edit
                }
                error={
                  showError &&
                  !countryIsocode && {
                    message: t('common:fields.country.validation.required'),
                  }
                }
                withIcon
              />
              <Input
                register={register}
                label={t('common:fields.address.label')}
                name="addressLine1"
                className="w-full mt-5"
                readOnly={!onboardingPermissions.edit}
                error={
                  showError &&
                  !addressLine1 && {
                    message: t('common:fields.address.validation.required'),
                  }
                }
              />
              <Input
                register={register}
                label={t('common:fields.address2.label')}
                name="addressLine2"
                className="w-full mt-5"
                readOnly={!onboardingPermissions.edit}
                optional
              />
              <Input
                register={register}
                label={t('common:fields.city.label')}
                name="city"
                className="w-full mt-5"
                readOnly={
                  (businessInfo.city && businessInfo.dunsnumber) ||
                  !onboardingPermissions.edit
                }
                error={
                  showError &&
                  !city && {
                    message: t('common:fields.city.validation.required'),
                  }
                }
              />
              <div className="w-full flex">
                <Input
                  register={register}
                  label={t('common:fields.postal.label')}
                  name="postalCode"
                  className="w-full mr-3 mt-5"
                  readOnly={
                    (businessInfo.postalCode && businessInfo.dunsnumber) ||
                    !onboardingPermissions.edit
                  }
                  error={
                    showError &&
                    !postalCode && {
                      message: t('common:fields.postal.validation.required'),
                    }
                  }
                />
                <AddressState
                  label={t('common:fields.province.label')}
                  name="stateOrProvince"
                  className="w-full ml-3 mt-5"
                  countryISOCode={businessesAddress.countryIsocode}
                  disabled={
                    (businessInfo.stateOrProvince && businessInfo.dunsnumber) ||
                    !onboardingPermissions.edit
                  }
                  register={register}
                  setValue={setValue}
                  value={businessInfo.stateOrProvince}
                  error={
                    showError &&
                    !stateOrProvince && {
                      message: t('common:fields.province.validation.required'),
                    }
                  }
                />
              </div>
            </div>

            <div className="w-1/2 px-8">
              <Input
                register={register}
                label={t('dashboard:fields.centralIndexKey.label')}
                readOnly={!onboardingPermissions.edit}
                name="centralIndexKey"
                className="w-full mt-5"
                optional
              />
              <PhoneInput
                label={t('common:fields.phone.label')}
                placeholder={t('common:fields.phone.placeholder')}
                name="phone"
                className="w-full mt-5"
                control={control}
                iconEnd={<PhoneIcon className="text-gray-400" />}
                readOnly={!onboardingPermissions.edit}
                error={
                  showError &&
                  !phone && {
                    message: t('common:fields.phone.validation.required'),
                  }
                }
              />
              <Input
                register={register}
                label={t('common:fields.email.label')}
                placeholder={t('common:fields.email.placeholder')}
                name="businessEmailAddress"
                className="w-full mt-5"
                iconEnd={<MailIcon className="text-gray-400" />}
                readOnly={!onboardingPermissions.edit}
                error={
                  showError &&
                  !businessEmailAddress && {
                    message: t('common:fields.email.validation.required'),
                  }
                }
              />
              <Input
                register={register}
                label={t('dashboard:fields.primaryURL.label')}
                placeholder={t('dashboard:fields.primaryURL.placeholder')}
                name="primaryURL"
                className="w-full mt-5"
                prefix="http://"
                readOnly={!onboardingPermissions.edit}
                error={
                  showError &&
                  !primaryURL && {
                    message: t(
                      'dashboard:fields.primaryURL.validation.required',
                    ),
                  }
                }
              />

              <div className="bg-gray-50 rounded-[8px] p-4 w-full mt-6">
                <div className="text-gray-900 font-medium text-sm">
                  {t('dashboard:businessInfo.support.title')}
                </div>
                <Input
                  register={register}
                  label={t('common:fields.email.label')}
                  placeholder={t('common:fields.email.placeholder')}
                  name="contactEmailAddress"
                  className="w-full mt-5"
                  iconEnd={<MailIcon className="text-gray-400" />}
                  readOnly={!onboardingPermissions.edit}
                  error={
                    showError &&
                    !contactEmailAddress && {
                      message: t('common:fields.email.validation.required'),
                    }
                  }
                />
                <PhoneInput
                  label={t('common:fields.phone.label')}
                  placeholder={t('common:fields.phone.placeholder')}
                  name="contactTelephoneNumber"
                  className="w-full mt-5"
                  control={control}
                  iconEnd={<PhoneIcon className="text-gray-400" />}
                  readOnly={!onboardingPermissions.edit}
                  error={
                    showError &&
                    !contactTelephoneNumber && {
                      message: t('common:fields.phone.validation.required'),
                    }
                  }
                />
              </div>
            </div>
          </div>

          <div className="flex mt-16 -mx-8">
            <Select
              label={t('dashboard:fields.idType.label')}
              placeholder={t('dashboard:fields.idType.placeholder')}
              name="businessesDocumentsTypesId"
              className="w-1/2 mx-8"
              options={businessDocTypes}
              onChange={handleIdTypeChange}
              value={selectedDocumentType}
              disabled={!onboardingPermissions.edit}
              error={
                showError &&
                !businessesDocumentsTypesId && {
                  message: t('dashboard:fields.idType.validation.required'),
                }
              }
            />
            <Input
              register={register}
              label={t('dashboard:fields.idNumber.label')}
              name="idNumber"
              className="w-1/2 mx-8"
              readOnly={!onboardingPermissions.edit}
              error={
                showError &&
                !idNumber && {
                  message: t('dashboard:fields.idNumber.validation.required'),
                }
              }
            />
          </div>

          <DoingBusinessAs
            register={register}
            disabled={
              (businessInfo.doingBusinessAs && businessInfo.dunsnumber) ||
              !onboardingPermissions.edit
            }
            error={doingBusinessAs && showError && !businessInfo.dbaFile}
          />

          <div className="border-b pb-5">
            <p className="heading-lg mt-16 mb-1">
              {t('dashboard:businessInfo.applicant.title')}
            </p>
            <p className="text-secondary">
              {t('dashboard:businessInfo.applicant.subTitle')}
            </p>
          </div>

          <div className="flex items-center -mx-4.5">
            <Input
              register={register}
              label={t('dashboard:businessInfo.applicant.name')}
              name="applicantName"
              className="w-full mx-4.5 mt-5"
              readOnly
            />

            <Input
              register={register}
              label={t('dashboard:businessInfo.applicant.role')}
              name="applicantRole"
              className="w-full mx-4.5 mt-5"
              readOnly
            />
          </div>

          <div className="flex items-center -mx-4.5">
            <Input
              register={register}
              label={t('dashboard:businessInfo.applicant.email')}
              name="applicantEmail"
              className="w-full mx-4.5 mt-5"
              readOnly
            />

            <Input
              register={register}
              label={t('dashboard:businessInfo.applicant.phone')}
              name="applicantPhone"
              className="w-full mx-4.5 mt-5"
              readOnly
            />
          </div>
        </form>
      </PermissionSection>
    </div>
  );
};

export default BusinessInformation;
