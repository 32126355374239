import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { whereEq } from 'ramda';

import { getShowErrors } from 'pages/dashboard/components/Progress/selectors';
import PermissionSection from 'components/permission/PermissionSection';
import { closeDialog, openDialog } from 'components/dialog/modalSlice';
import ErrorMessage from 'components/Inputs/components/ErrorMessage';
import DangerDialog from 'components/dialog/templates/DangerDialog';
import { UploadFile, File } from 'components/uploadFile';
import { useAppSelector, useAppDispatch } from 'hooks';
import { MailIcon, PhoneIcon } from 'components/icons';
import AddressState from 'components/AddressState';
import { CountryISOCodes } from 'common/countries';
import { Permissions } from 'entities/dashboard';
import Button from 'components/Button';
import {
  NumberInput,
  Input,
  Select,
  PhoneInput,
  DatePicker,
} from 'components/Inputs';
import {
  getPersonsDocumentsTypes,
  getCountries,
  hasPermission,
} from 'pages/dashboard/selectors';

import SocialNumberInput from './SocialNumberInput';
import { getOwnersPersonSchema } from '../utils';
import { getTotalShare } from '../selectors';
import {
  updateOwnerPerson,
  createOwnerPerson,
  deleteOwnerBusiness,
  deleteOwnerPerson,
  downloadPersonFile,
} from '../thunks';

interface FileData {
  personDocumentsTypesId: number | null;
  idfrontBlobFileSize: number | null;
  idfrontBlobFileName: string | null;
  idfrontBlobFileExtension: string | null;
  frontBlobFile?: File | null;
  idfrontGuid?: string | null;
}

interface IndividualFormProps {
  ownerData: any;
  isOpen: boolean;
}

enum IsLoadingButtons {
  empty,
  submit,
  delete,
}

const defaultFileData: FileData = {
  personDocumentsTypesId: null,
  idfrontBlobFileSize: null,
  idfrontBlobFileName: null,
  idfrontBlobFileExtension: null,
  frontBlobFile: null,
};

const IndividualForm: React.FC<IndividualFormProps> = ({
  ownerData,
  isOpen,
}) => {
  const { t } = useTranslation('ownership');
  const dispatch = useAppDispatch();

  const countries = useAppSelector(getCountries);
  const types = useAppSelector(getPersonsDocumentsTypes);
  const totalShare = useAppSelector(getTotalShare);
  const showErrors: any = useAppSelector(getShowErrors);
  const ownershipPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.ownership),
  );

  const [fileData, setFileData] = useState<FileData>(defaultFileData);
  const [uploadedFiles, setUploadedFiles] = useState<any>([]);
  const [fileProgress, setFileProgress] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<IsLoadingButtons>(
    IsLoadingButtons.empty,
  );

  const showError = useMemo(
    () => showErrors.owner && ownershipPermissions.edit,
    [showErrors.owner, ownershipPermissions.edit],
  );

  const maxShare = totalShare - (ownerData.ownershipPercentage || 0);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    control,
    formState: { errors },
    reset,
  } = useForm<FieldValues>({
    defaultValues: ownerData,
    resolver: yupResolver(getOwnersPersonSchema(t, maxShare)),
  });

  const values = watch();
  const hideSubmitButton =
    !fileData.frontBlobFile &&
    whereEq(
      { ...values, idfrontGuid: uploadedFiles[0]?.id ?? null },
      ownerData,
    );

  useEffect(() => {
    const files: any = [];
    if (ownerData.idfrontGuid) {
      setFileData(defaultFileData);
      files.push({
        id: ownerData.idfrontGuid,
        blobFileName: ownerData.idfrontBlobFileName,
        blobFileExtension: ownerData.idfrontBlobFileExtension,
        blobFileSize: ownerData.idfrontBlobFileSize,
        description: t('ownership:docIdTitle'),
        createdOn: ownerData.modifiedOn || ownerData.createdOn,
      });
    }
    setUploadedFiles(files);

    reset(ownerData);
  }, [ownerData, setUploadedFiles, reset, t]);

  useEffect(() => {
    if (fileData.frontBlobFile) {
      const files: any = [];

      files.push({
        id: 0,
        blobFileName: fileData.idfrontBlobFileName,
        blobFileExtension: fileData.idfrontBlobFileExtension,
        blobFileSize: fileData.idfrontBlobFileSize,
        description: t('ownership:docIdTitle'),
      });

      setUploadedFiles(files);
    }
  }, [fileData, setUploadedFiles, t]);

  const handleFormSubmit = async (data) => {
    setIsLoading(IsLoadingButtons.submit);

    if (ownerData.businessesOwnersBusinessesId) {
      await dispatch(
        deleteOwnerBusiness(ownerData.businessesOwnersBusinessesId),
      );
    }

    if (data.countryIsocode !== CountryISOCodes.USA) {
      data.idnumber = '';
    }

    const fields =
      uploadedFiles.length && !fileData.frontBlobFile
        ? data
        : {
            ...data,
            ...fileData,
          };

    await dispatch(
      ownerData.businessesOwnersPersonsId
        ? updateOwnerPerson(fields)
        : createOwnerPerson(fields),
    );

    setIsLoading(IsLoadingButtons.empty);
  };

  const handleFileDownload = (guid: string, fileName: string) => {
    if (guid) {
      dispatch(downloadPersonFile({ guid, fileName }));
    }
  };

  const handleFileDelete = () => {
    setFileData(defaultFileData);
    setUploadedFiles([]);
  };

  const handleFileUpload = (files: FileList) => {
    handleFileDelete();

    const file = files[0];
    const fileName = file.name.split('.');

    setFileProgress(100);

    setTimeout(() => {
      setFileProgress(0);

      setFileData({
        personDocumentsTypesId: types[0]?.personDocumentsTypesId || null,
        idfrontBlobFileSize: file.size,
        idfrontBlobFileName: file.name,
        idfrontBlobFileExtension: fileName[1],
        frontBlobFile: files[0],
      });
    }, 2000);
  };

  const onSubmit = () => {
    handleSubmit(handleFormSubmit)();
  };

  const closeConfirmationDialog = () => {
    dispatch(closeDialog());
  };

  const onDelete = () => {
    dispatch(
      openDialog({
        maxWidth: '512px',
        onClose: closeConfirmationDialog,
        onCancel: closeConfirmationDialog,
        content: (
          <DangerDialog
            title={t('common:confirmationText')}
            buttonText={t('common:remove')}
            onCancel={closeConfirmationDialog}
            onConfirm={async () => {
              closeConfirmationDialog();

              setIsLoading(IsLoadingButtons.delete);

              await dispatch(
                deleteOwnerPerson(ownerData.businessesOwnersPersonsId),
              );

              setIsLoading(IsLoadingButtons.empty);
            }}
          />
        ),
      }),
    );
  };

  const selectedCountry = countries.find(
    (country) =>
      country.value === (ownerData.countryIsocode || CountryISOCodes.USA),
  );

  const nationality = countries.find(
    (country) =>
      country.value ===
      (ownerData.nationalityCountryISOCode || CountryISOCodes.USA),
  );

  return (
    <div className={isOpen ? '' : 'hidden'}>
      <NumberInput
        name="ownershipPercentage"
        label={t('ownership:fields.ownershipPercentage.label')}
        description={t('ownership:fields.ownershipPercentage.description')}
        placeholder={t('ownership:fields.ownershipPercentage.placeholder')}
        readOnly={!ownershipPermissions.edit}
        className="w-full mt-6"
        inputClassName="pl-8"
        prefix="%"
        prefixInline
        control={control}
        error={errors?.ownershipPercentage}
      />

      <div className="flex -mx-4.5">
        <Input
          name="givenName1"
          label={t('common:fields.firstname.label')}
          readOnly={!ownershipPermissions.edit}
          className="w-full mx-4.5 mt-6"
          register={register}
          error={errors?.givenName1}
        />
        <Input
          name="surnameFirst"
          label={t('common:fields.lastname.label')}
          readOnly={!ownershipPermissions.edit}
          className="w-full mx-4.5 mt-6"
          register={register}
          error={errors?.surnameFirst}
        />
      </div>

      <div className="flex -mx-4.5">
        <DatePicker
          label={t('ownership:fields.dateOfBirth.label')}
          disabled={!ownershipPermissions.edit}
          name="dateOfBirth"
          className="w-full mx-4.5 mt-6"
          maxDate={new Date()}
          control={control}
          error={errors?.dateOfBirth}
        />
        <Select
          label={t('ownership:fields.nationality.label')}
          disabled={!ownershipPermissions.edit}
          name="nationalityCountryISOCode"
          value={nationality}
          className="w-full mx-4.5 mt-5"
          options={countries}
          register={register}
          setValue={setValue}
          withIcon
          error={errors?.nationalityCountryISOCode}
        />
      </div>

      <div className="border-b border-gray-300 pb-5 mt-16">
        <p className="heading-lg mb-1">{t('ownership:contactTitle')}</p>
        <p className="text-secondary">{t('ownership:contactSubTitle')}</p>
      </div>

      <div className="flex -mx-8">
        <div className="w-1/2 mx-8">
          <Select
            label={t('common:fields.country.label')}
            disabled={!ownershipPermissions.edit}
            name="countryIsocode"
            value={selectedCountry}
            className="w-full mt-5"
            options={countries}
            register={register}
            setValue={setValue}
            withIcon
            error={errors?.countryIsocode}
          />
          <Input
            name="addressLine1"
            label={t('common:fields.address.label')}
            readOnly={!ownershipPermissions.edit}
            className="w-full mt-6"
            register={register}
            error={errors?.addressLine1}
          />
          <Input
            name="addressLine2"
            label={t('common:fields.address2.label')}
            readOnly={!ownershipPermissions.edit}
            className="w-full mt-6"
            register={register}
            optional
          />
          <Input
            name="city"
            label={t('common:fields.city.label')}
            readOnly={!ownershipPermissions.edit}
            className="w-full mt-6"
            register={register}
            error={errors?.city}
          />
          <div className="flex -mx-4.5">
            <Input
              name="postalCode"
              label={t('common:fields.postal.label')}
              readOnly={!ownershipPermissions.edit}
              className="w-full mx-4.5 mt-6"
              register={register}
              error={errors?.postalCode}
            />
            <AddressState
              label={t('common:fields.province.label')}
              name="stateOrProvince"
              className="w-full mx-4.5 mt-6"
              disabled={!ownershipPermissions.edit}
              countryISOCode={values.countryIsocode}
              value={ownerData.stateOrProvince}
              error={errors?.stateOrProvince}
              register={register}
              setValue={setValue}
            />
          </div>
        </div>
        <div className="w-1/2 mx-8">
          <PhoneInput
            label={t('common:fields.phone.label')}
            placeholder={t('common:fields.phone.placeholder')}
            readOnly={!ownershipPermissions.edit}
            name="phone"
            className="w-full mt-6"
            iconEnd={<PhoneIcon className="text-gray-400" />}
            control={control}
            error={errors?.phone}
          />
          <Input
            label={t('common:fields.email.label')}
            placeholder={t('common:fields.email.placeholder')}
            readOnly={!ownershipPermissions.edit}
            name="emailAddress"
            className="w-full mt-6"
            iconEnd={<MailIcon className="text-gray-400" />}
            register={register}
            error={errors?.emailAddress}
          />
          {values.countryIsocode !== CountryISOCodes.Canada && (
            <SocialNumberInput
              control={control}
              error={errors?.idnumber}
              disabled={!ownershipPermissions.edit}
            />
          )}
        </div>
      </div>

      <UploadFile
        title={t('ownership:docIdTitle')}
        text={t('ownership:docIdSubTitle')}
        onUpload={handleFileUpload}
        uploadFiles={uploadedFiles}
        className={classNames(
          'mt-6',
          showError &&
            uploadedFiles.length === 0 &&
            'border border-red-600 p-2 rounded-md',
        )}
        progressPercent={fileProgress}
        handleProgress={setFileProgress}
        disabled={!ownershipPermissions.edit}
        permission={Permissions.ownership}
      >
        {({ handleSelect }) =>
          uploadedFiles.map((file) => (
            <div key={file.id}>
              <File
                file={file}
                onDelete={handleFileDelete}
                onDownload={(name) => handleFileDownload(file.id, name)}
                handleSelect={handleSelect}
                readOnly={!ownershipPermissions.edit}
                permission={Permissions.ownership}
              />
            </div>
          ))
        }
      </UploadFile>

      {showError && uploadedFiles.length === 0 && (
        <ErrorMessage error={{ message: t('ownership:docIdRequired') }} />
      )}

      {!hideSubmitButton && (
        <PermissionSection permission={Permissions.ownership} showPopover edit>
          <Button
            type="button"
            onClick={onSubmit}
            className="mr-3 mt-6"
            loading={isLoading === IsLoadingButtons.submit}
            disabled={!ownershipPermissions.edit}
          >
            {t('common:submit')}
          </Button>
        </PermissionSection>
      )}

      {ownerData.businessesOwnersPersonsId && (
        <PermissionSection permission={Permissions.ownership} showPopover edit>
          <Button
            type="button"
            onClick={onDelete}
            variant="red-pale"
            className="mt-6"
            loading={isLoading === IsLoadingButtons.delete}
            disabled={!ownershipPermissions.edit}
          >
            {t('common:remove')}
          </Button>
        </PermissionSection>
      )}
    </div>
  );
};

export default IndividualForm;
