import { useTranslation } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import { setOnboardingTab } from 'pages/dashboard/dashboardSlice';
import { useAppSelector, useAppDispatch } from 'hooks';
import Alert, { AlertTypes } from 'components/Alert';
import { Permissions } from 'entities/dashboard';

import { getIsBusinessInfoSuccess } from '../selectors';

const BusinessAlert = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const isBusinessInfoSuccess = useAppSelector(getIsBusinessInfoSuccess);

  const handleClick = (e) => {
    e.preventDefault();
    e.stopPropagation();

    dispatch(setOnboardingTab(0));

    const businessTab = document.getElementById('onboarding-tabs');
    businessTab?.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  if (isBusinessInfoSuccess) {
    return null;
  }

  return (
    <PermissionSection permission={Permissions.onboardingBusiness} edit>
      <Alert
        title={
          <span>
            {t('dashboard:alerts.businessInformation.title')}
            <a
              href="#business-information"
              className="underline text-yellow-700 ml-1"
              onClick={handleClick}
            >
              {t('dashboard:alerts.businessInformation.link')}
            </a>
          </span>
        }
        className="mb-6"
        type={AlertTypes.warning}
        withBorder
      />
    </PermissionSection>
  );
};

export default BusinessAlert;
