import { sortBy, prop } from 'ramda';

import { ProductTypeCodes, TabProgressProps } from 'entities/progress';
import { BlobReferencesType, CurrencyCode } from 'entities/dashboard';
import { lowercaseKeys, trimStr } from 'utils/helpers';
import { StatusIconTypes } from 'components/Tabs';
import {
  OfficeBuildingIcon,
  UsersIcon,
  DocumentTextIcon,
  CreditCardIcon,
  SwitchIcon,
} from 'components/icons';

import BusinessInformation from './components/BusinessInformation/BusinessInformation';
import Transactional from './components/Transactional/Transactional';
import PlanTab from './components/BusinessPlan/BusinessPlan';
import Ownership from './components/Ownership/Ownership';
import Financial from './components/Financial/Financial';

const currencyList = ['USD', 'EUR'];

export enum OnboardingTabCodes {
  ownership = 'owner',
  business = 'businessInfo',
  financial = 'financial',
  transactional = 'transactional',
  plan = 'businessPlan',
}

export interface OnboardingTab {
  name: string;
  icon: React.ReactNode;
  statusIcon: StatusIconTypes;
  key: string;
  component: React.ReactNode;
  product: ProductTypeCodes;
  code?: OnboardingTabCodes;
  progress?: TabProgressProps;
}

export const tabList: OnboardingTab[] = [
  {
    name: 'dashboard:businessInfo.tab',
    icon: <OfficeBuildingIcon />,
    statusIcon: StatusIconTypes.empty,
    key: 'business',
    product: ProductTypeCodes.payments,
    component: <BusinessInformation />,
  },
  {
    name: 'dashboard:ownershipTab',
    icon: <UsersIcon />,
    statusIcon: StatusIconTypes.empty,
    key: 'ownership',
    product: ProductTypeCodes.payments,
    component: <Ownership />,
  },
  {
    name: 'dashboard:financialTab',
    icon: <CreditCardIcon />,
    statusIcon: StatusIconTypes.empty,
    key: 'financial',
    product: ProductTypeCodes.services,
    component: <Financial />,
  },
  {
    name: 'dashboard:transactionalTab',
    icon: <SwitchIcon />,
    statusIcon: StatusIconTypes.empty,
    key: 'transactional',
    product: ProductTypeCodes.crossBorderPayments,
    component: <Transactional />,
  },
  {
    name: 'dashboard:businessPlan.tab',
    icon: <DocumentTextIcon />,
    statusIcon: StatusIconTypes.empty,
    product: ProductTypeCodes.services,
    key: 'plan',
    component: <PlanTab />,
  },
];

export const getOnboardingTabs = (
  { tabProgress, showErrors },
  currentTab: any = null,
) => {
  return tabList.map(({ key, ...rest }: OnboardingTab, index: number) => {
    let statusIcon: StatusIconTypes = StatusIconTypes.progress;

    if (tabProgress[OnboardingTabCodes[key]].progress === 0) {
      statusIcon = StatusIconTypes.empty;
    }

    if (tabProgress[OnboardingTabCodes[key]].progress === 100) {
      statusIcon = StatusIconTypes.success;
    }

    if (
      showErrors[OnboardingTabCodes[key]] &&
      tabProgress[OnboardingTabCodes[key]].progress < 100 &&
      currentTab !== index
    ) {
      statusIcon = StatusIconTypes.error;
    }

    return {
      ...rest,
      key,
      code: OnboardingTabCodes[key],
      statusIcon,
      progress: tabProgress[OnboardingTabCodes[key]],
    };
  });
};

export const mappedCurrencyCodes = (codes) =>
  sortBy(prop('label'))(
    codes.slice().map((code) => ({
      name: `${code.currencyName} (${code.alphaCode})`,
      label: `${code.currencyName} (${code.alphaCode})`,
      value: code.currencyCodeID,
      alphaCode: code.alphaCode,
    })),
  );

const mapBlobReferencesTypes = (result) => {
  const mappedBlobReferencesTypes = {};

  result.blobReferencesTypes.map((type: BlobReferencesType) => {
    mappedBlobReferencesTypes[type.typeCode] = type.blobReferencesTypesId;
  });

  result.mappedBlobReferencesTypes = mappedBlobReferencesTypes;

  return result;
};

const mapCurrencyCodes = (result) => {
  result.inputCurrencies = result.currencyCodes.filter(
    (currencyCode: CurrencyCode) =>
      currencyList.includes(currencyCode.alphaCode),
  );

  return result;
};

const mapLegalEntities = (result) => {
  result.businessesLegalEntityFormTypes =
    result.businessesLegalEntityFormTypes.map((type) => ({
      name: type.description,
      value: type.businessesLegalEntityFormTypesId,
    }));

  return result;
};

const mapCountries = (result) => {
  result.countries = result.countries.map((country) => ({
    ...country,
    name: country.country,
    value: country.iSOCode,
    icon: `/assets/flags/${country.alpha2Code.toLowerCase()}.png`,
  }));

  return result;
};

interface DropdownsObject {
  [key: string]: any;
}

const parseCallBacks = {
  blobReferencesTypes: mapBlobReferencesTypes,
  businessesLegalEntityFormTypes: mapLegalEntities,
  countries: mapCountries,
  currencyCodes: mapCurrencyCodes,
};

export const getParsedDropdowns = (dropdowns) => {
  const result: DropdownsObject = {};

  for (const key in dropdowns) {
    const dropdownKey = key.replace('Json', '');

    result[dropdownKey] = dropdowns[key]
      ? lowercaseKeys(JSON.parse(dropdowns[key]))
      : [];

    if (parseCallBacks[dropdownKey]) {
      parseCallBacks[dropdownKey](result);
    }
  }

  return result;
};

export const calcProcentege = (selectedData: any) => {
  if (!selectedData) {
    return {
      totalFields: 0,
      enteredFields: 0,
      progress: 0,
    };
  }

  const allFields = Object.values(selectedData);
  const noValuesLength = allFields.filter((item) => !trimStr(item)).length;
  const progress = 100 - Math.ceil((noValuesLength * 100) / allFields.length);

  return {
    totalFields: allFields.length,
    enteredFields: allFields.length - noValuesLength,
    progress,
  };
};

export const isBusinessRegistered = (payload: any) => {
  if (!payload) {
    return false;
  }

  const { business, businessPerson, person, personTelephoneNumber } = payload;

  if (
    !businessPerson ||
    !businessPerson.businessesPersonsRole ||
    (!business.dunsnumber && !business.noDunslisted) ||
    !personTelephoneNumber.telephoneNumber ||
    !person.givenName1.trim() ||
    !person.surnameFirst.trim()
  ) {
    return false;
  }

  return true;
};
