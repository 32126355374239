import { Routes, Route } from 'react-router-dom';

import ErpCallback from 'pages/dashboard/components/AccountingSoftware/components/ErpCallback';
import PrivacyPolicy from 'pages/privacyPolicy/PrivacyPolicy';
import Businesses from 'pages/businesses/Businesses';
import TermsOfUse from 'pages/termsOfUse/TermsOfUse';
import Invitation from 'pages/invitation/Invitation';
import PrivateRoute from 'components/PrivateRoute';
import Dashboard from 'pages/dashboard/Dashboard';
import Profile from 'pages/Profile/Profile';
import NotFound from 'components/NotFound';
import Enroll from 'pages/enroll/Enroll';
import Login from 'pages/login/Login';
import path from 'common/path';

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={path.login} element={<Login />} />
      <Route path={path.enroll} element={<Enroll />} />
      <Route path={path.profile} element={<Profile />} />
      <Route path={path.invitation} element={<Invitation />} />
      <Route path={path.terms} element={<TermsOfUse />} />
      <Route path={path.privacy} element={<PrivacyPolicy />} />
      <Route path={path.callback} element={<ErpCallback />} />
      <Route
        path={path.businesses}
        element={<PrivateRoute component={Businesses} />}
      />
      <Route
        path={path.dashboard}
        element={<PrivateRoute component={Dashboard} />}
      />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default AppRoutes;
