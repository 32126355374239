import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ErrorMessage from 'components/Inputs/components/ErrorMessage';
import { UploadFile, File } from 'components/uploadFile/index';
import { BusinessDocumentTypes } from 'entities/businessInfo';
import { useAppDispatch, useAppSelector } from 'hooks';
import YesNoCollapse from 'components/YesNoCollapse';
import { Permissions } from 'entities/dashboard';
import { Input } from 'components/Inputs';

import { setShowDoingBusinessAs } from '../businessInfoSlice';
import {
  uploadBusinessDocument,
  deleteBusinessDocument,
  downloadBusinessDocument,
} from '../thunks';
import {
  getShowDoingBusinessAs,
  getMappedBusinessDocuments,
  getLoadingProgress,
} from '../selectors';

const DoingBusinessAs = ({ register, disabled, error }) => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();

  const showDoingBusinessAs = useAppSelector(getShowDoingBusinessAs);
  const documents = useAppSelector(getMappedBusinessDocuments);
  const loadingProgress = useAppSelector(getLoadingProgress);

  const docs = documents.filter(
    (document) =>
      document.businessesDocumentsTypesId ===
      BusinessDocumentTypes.dbaStatement,
  );

  const handleFileDelete = async (id: number) => {
    await dispatch(deleteBusinessDocument(id));
  };

  const handleFileDownload = async (guid: string, fileName: string) => {
    await dispatch(downloadBusinessDocument({ guid, fileName }));
  };

  const handleFileUpload = async (files: FileList) => {
    if (docs.length) {
      await handleFileDelete(docs[0].id);
    }

    const data = {
      files,
      businessesDocumentsTypesId: BusinessDocumentTypes.dbaStatement,
    };

    dispatch(uploadBusinessDocument(data));
  };

  const handleCollapse = (value) => {
    dispatch(setShowDoingBusinessAs(Boolean(value)));
  };

  return (
    <YesNoCollapse
      value={showDoingBusinessAs}
      title={t('dashboard:businessInfo.dba.title')}
      disabled={disabled}
      onChange={handleCollapse}
      className="bg-gray-50"
      permission={Permissions.onboardingBusiness}
    >
      <Input
        register={register}
        label={t('dashboard:businessInfo.dba.name')}
        placeholder={t('dashboard:businessInfo.dba.placeholder')}
        name="doingBusinessAs"
        className="pt-5"
        readOnly={disabled}
      />

      <UploadFile
        title={t('dashboard:businessInfo.dba.file')}
        className={classNames(
          'mt-5',
          error && 'border border-red-600 p-2 rounded-md',
        )}
        onUpload={handleFileUpload}
        progressPercent={loadingProgress}
        uploadFiles={docs}
        disabled={disabled}
        permission={Permissions.onboardingBusiness}
      >
        {({ handleSelect }) =>
          docs.map((doc) => (
            <div key={doc.id}>
              <File
                file={{
                  ...doc,
                  description: t('dashboard:businessInfo.dba.file'),
                }}
                onDelete={handleFileDelete}
                onDownload={(name) =>
                  handleFileDownload(doc.documentsBlobId, name)
                }
                handleSelect={handleSelect}
                readOnly={disabled}
                permission={Permissions.onboardingBusiness}
              />
            </div>
          ))
        }
      </UploadFile>
      {error && (
        <ErrorMessage
          error={{
            message: t('dashboard:businessInfo.dba.validation.required'),
          }}
        />
      )}
    </YesNoCollapse>
  );
};

export default DoingBusinessAs;
