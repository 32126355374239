import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { useAppSelector, useAppDispatch } from 'hooks';
import HideComponent from 'components/HideComponent';
import Loading from 'components/Loading';
import {
  BankAccountCheckRangeType,
  BankAccountCheckType,
} from 'services/bankAccounts';
import {
  BankAccount,
  BanksWithPaymentPreferences,
} from 'entities/bankAccounts';

import { fetchCheckRanges } from '../thunks';
import { getBankName } from '../utils';
import {
  getSelectedBankName,
  getBankAccount,
  getBankAccountCheckRanges,
  getBankAccountChecks,
} from '../selectors';

const BankAccountInfoChecks = () => {
  const { t } = useTranslation('bank');
  const dispatch = useAppDispatch();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const account: BankAccount = useAppSelector(getBankAccount);
  const bankName: string = useAppSelector(getSelectedBankName);
  const checkRanges: BankAccountCheckRangeType[] = useAppSelector(
    getBankAccountCheckRanges,
  );
  const checkOmitted: BankAccountCheckType[] =
    useAppSelector(getBankAccountChecks);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      await dispatch(fetchCheckRanges());
      setIsLoading(false);
    };

    if (
      Object.values(BanksWithPaymentPreferences).includes(getBankName(bankName))
    ) {
      fetchData();
    }
  }, [dispatch, bankName]);

  return (
    <HideComponent
      show={Object.values(BanksWithPaymentPreferences).includes(
        getBankName(bankName),
      )}
    >
      <div className="relative">
        <Loading loading={isLoading} transparent />
        <div className="mt-6 pt-6 border-t border-gray-200 flex">
          <span className="inline-flex items-center bg-gray-50 text-sm font-medium flex-shrink-0 px-3 py-1.5 rounded-xl mr-4">
            <span
              className={`w-2 h-2 rounded-full mr-2 ${
                account.checkCustomerEnabled ? 'bg-green-400' : 'bg-gray-300'
              }`}
            ></span>
            {t('bank:paymentPreferences.checkBadge', {
              value: t(
                `bank:paymentPreferences.${
                  account.checkCustomerEnabled ? 'enabled' : 'disabled'
                }`,
              ),
            })}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-x-9 gap-y-6 mt-4">
          {checkRanges.map(
            (range: BankAccountCheckRangeType, index: number) => (
              <div
                key={range.banksAccountsChecksRangesId}
                className="p-4 bg-gray-50 rounded-lg"
              >
                <p className="text-sm leading-5 font-medium text-gray-500 mb-1">
                  {t('bank:paymentPreferences.checkRange', {
                    number: index + 1,
                  })}
                </p>
                <p className="text-sm">
                  {t('common:from')}&nbsp;
                  <span className="font-medium">#{range.startRange}</span>
                </p>
                <p className="text-sm">
                  {t('common:to')}&nbsp;
                  <span className="font-medium">#{range.endRange}</span>
                </p>
              </div>
            ),
          )}
        </div>
        <HideComponent show={checkOmitted.length > 0}>
          <div className="mt-4">
            <p className="text-sm leading-5 font-medium text-gray-500">
              {t('bank:paymentPreferences.omittedTitle')}
            </p>
            <div className="flex flex-wrap items-center">
              {checkOmitted.map((omitted: BankAccountCheckType) => (
                <div
                  key={omitted.banks_Accounts_ChecksId}
                  className="py-0.5 px-2.5 bg-gray-100 mr-2 rounded-sm text-sm font-medium mt-1"
                >
                  #{omitted.checkNumber}
                </div>
              ))}
            </div>
          </div>
        </HideComponent>
      </div>
    </HideComponent>
  );
};

export default BankAccountInfoChecks;
