import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

import { Select, SelectIndicatorTypes } from 'components/Inputs';
import { BankIntegratorProps, BankLabels } from 'entities/bankAccounts';
import { useAppDispatch, useAppSelector } from 'hooks';
import Loading from 'components/Loading';

import { setIntegrator } from '../bankAccountsSlice';
import { getBankName } from '../utils';
import {
  getBankIntegrators,
  getSelectedIntegrator,
  getBankAccountIsLoading,
  getTGPAccountReferenceId,
} from '../selectors';

interface MappedBank extends BankIntegratorProps {
  name: string;
  value: number;
}

const SelectedOption = ({ selected }) => {
  const name = getBankName(selected.name);

  return (
    <span className="flex w-full items-center justify-between">
      <span className="truncate block">
        {BankLabels[selected.name.replace(/\s/g, '')] || selected.name}
      </span>
      <img
        alt={selected.name}
        src={`/assets/images/banks/${name}.png`}
        className="max-w-[100px] max-h-[24px]"
      />
    </span>
  );
};

const BankOption = ({ option }: { option: MappedBank }) => {
  const name = getBankName(option.bankName);

  return (
    <div className="flex items-center py-2 pl-1 justify-between w-full">
      <p className="text-sm font-medium">
        {BankLabels[option.bankName.replace(/\s/g, '')] || option.bankName}
      </p>
      <img
        alt={option.bankName}
        src={`/assets/images/banks/${name}.png`}
        className="max-w-[100px] max-h-[24px]"
      />
    </div>
  );
};

const BankAccountList = () => {
  const { t } = useTranslation('bank');
  const dispatch = useAppDispatch();

  const bankIntegrators: BankIntegratorProps[] =
    useAppSelector(getBankIntegrators);
  const selectedIntegrator: BankIntegratorProps = useAppSelector(
    getSelectedIntegrator,
  );
  const isLoading: boolean = useAppSelector(getBankAccountIsLoading);
  const tgpaccountReferenceId = useAppSelector(getTGPAccountReferenceId);

  const handleSelectedBank = (bank: MappedBank) => {
    if (
      !tgpaccountReferenceId ||
      selectedIntegrator?.bankName === bank.bankName
    ) {
      dispatch(setIntegrator(bank));
    }
  };

  const mappedBanks: MappedBank[] = useMemo(
    () =>
      bankIntegrators
        .filter((bank: BankIntegratorProps) => bank.banksIntegratorsId)
        .map((bank: BankIntegratorProps) => ({
          ...bank,
          name: bank.bankName,
          value: bank.banksIntegratorsId,
        })),
    [bankIntegrators],
  );

  return (
    <div className="relative">
      <Loading loading={isLoading} transparent />
      <Select
        name="bankIntegrator"
        label={t('bankSelector.label')}
        placeholder={t('bankSelector.placeholder')}
        indicator={SelectIndicatorTypes.selector}
        options={mappedBanks}
        value={
          mappedBanks.find(
            (bank: MappedBank) =>
              bank.banksIntegratorsId ===
              selectedIntegrator?.banksIntegratorsId,
          ) || null
        }
        onChange={handleSelectedBank}
        disabled={tgpaccountReferenceId}
        optionComponent={BankOption}
        selectedComponent={SelectedOption}
        classes={{
          activeOption: 'bg-gray-100 cursor-default',
          options: 'max-h-auto',
          option: 'border-b border-gray-200 pr-4',
        }}
      />
    </div>
  );
};

export default BankAccountList;
