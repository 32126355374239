import { Trans, useTranslation } from 'react-i18next';
import classNames from 'classnames';

import ProgressProducts from 'pages/dashboard/components/Progress/components/ProgressProducts';
import { getActiveProductsTypeCodes } from 'pages/dashboard/components/Progress/selectors';
import BadgeConnector from 'pages/dashboard/components/Connectors/BadgeConnector';
import { getOnboardingTabs, OnboardingTab } from 'pages/dashboard/utils';
import { CommunicationIcon, FinanceIcon } from 'components/icons';
import { hasPermission } from 'pages/dashboard/selectors';
import { BankAccount } from 'entities/bankAccounts';
import { ErpAccount } from 'entities/accounting';
import { Permissions } from 'entities/dashboard';
import { useAppSelector } from 'hooks';
import {
  getBankAccounts,
  getConnectedBankAccount,
} from 'pages/dashboard/components/BankAccounts/selectors';
import {
  getErpAccounts,
  getConnectedErpAccount,
} from 'pages/dashboard/components/AccountingSoftware/selectors';
import {
  getTabProgresses,
  getShowErrors,
} from 'pages/dashboard/components/Progress/selectors';
import Accordion from 'components/Accordion';
import {
  ProcentByTabProps,
  ProductTypeCodes,
  ShowErrorsProps,
} from 'entities/progress';

const Confirmation = () => {
  const { t } = useTranslation('dashboard');

  const bankAccounts: BankAccount[] = useAppSelector(getBankAccounts);
  const erpAccounts: ErpAccount[] = useAppSelector(getErpAccounts);
  const tabProgress: ProcentByTabProps = useAppSelector(getTabProgresses);
  const showErrors: ShowErrorsProps = useAppSelector(getShowErrors);
  const connectedErpAccount: ErpAccount = useAppSelector(
    getConnectedErpAccount,
  );
  const connectedBankAccount: BankAccount = useAppSelector(
    getConnectedBankAccount,
  );
  const activeProductTypes: ProductTypeCodes[] = useAppSelector(
    getActiveProductsTypeCodes,
  );
  const bankPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.bankIntegration),
  );
  const erpPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.erpIntegration),
  );

  const tabList = getOnboardingTabs({ tabProgress, showErrors });

  const bankIncomplete = bankAccounts.length === 0 && bankPermissions.view;
  const erpIncomplete = erpAccounts.length === 0 && erpPermissions.view;

  return (
    <div className="p-6">
      <p className="heading-2xl mb-1 font-bold">
        {t('dashboard:submitModal.confirmTitle')}
      </p>
      <p className="text-secondary mb-9">
        {t('dashboard:submitModal.confirmTitleInfo')}
      </p>
      {(bankIncomplete || erpIncomplete) && (
        <>
          <p className="heading-lg mb-1">
            {t('dashboard:submitModal.confirmSubTitle')}
          </p>
          <p className="text-secondary pb-5 border-b mb-4">
            {bankIncomplete && erpIncomplete ? (
              <Trans
                ns="dashboard"
                i18nKey="submitModal.confirmSubTitleNotConnectedBoth"
              >
                Your&nbsp;
                <span className="font-semibold text-gray-900">
                  Accounting Software
                </span>
                &nbsp;and&nbsp;
                <span className="font-semibold text-gray-900">
                  Bank Account
                </span>
                &nbsp;are not connected.
              </Trans>
            ) : (
              (bankIncomplete || erpIncomplete) && (
                <Trans
                  ns="dashboard"
                  i18nKey="submitModal.confirmSubTitleNotConnected"
                  values={{
                    connector: t(
                      `dashboard:connectors.${
                        bankIncomplete ? 'bank' : 'accounting'
                      }.title`,
                    ),
                  }}
                >
                  Your&nbsp;
                  <span className="font-semibold text-gray-900">connector</span>
                  &nbsp;is not connected.
                </Trans>
              )
            )}

            <span className="ml-1">
              {t('dashboard:submitModal.confirmSubTitleInfo')}
            </span>
          </p>
        </>
      )}

      {(erpPermissions.view || bankPermissions.view) && (
        <div className="flex items-center">
          {erpPermissions.view && (
            <div className="mr-4">
              <BadgeConnector
                image={<CommunicationIcon className="w-6 h-6 text-blue-600" />}
                text={t('dashboard:connectors.accounting.title')}
                connected={!!connectedErpAccount}
                started={erpAccounts.length > 0}
              />
            </div>
          )}

          {bankPermissions.view && (
            <BadgeConnector
              image={<FinanceIcon className="w-6 h-6 text-blue-600" />}
              text={
                bankAccounts.length > 1
                  ? t('dashboard:connectors.bank.multiTitle', {
                      value: bankAccounts.length,
                    })
                  : t('dashboard:connectors.bank.title')
              }
              connected={!!connectedBankAccount}
              started={bankAccounts.length > 0}
            />
          )}
        </div>
      )}

      <p className="heading-lg mt-9 mb-1">
        {t('dashboard:submitModal.confirmOrderTitle')}
      </p>
      <p className="text-secondary pb-5 border-b mb-4">
        {t('dashboard:submitModal.confirmOrderSubTitle')}
      </p>

      <div className="flex flex-wrap pb-3">
        <ProgressProducts filter={[ProductTypeCodes.payments]} inline />
      </div>

      {tabList
        .filter((tab: OnboardingTab) =>
          activeProductTypes.includes(tab.product),
        )
        .map((tab: OnboardingTab) => (
          <Accordion
            title={
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <span className="text-gray-400 mr-4">{tab.icon}</span>
                  <span className="text-gray-800 font-normal">
                    {t(tab.name)}
                  </span>
                </div>
                <span
                  className={classNames(
                    tab.progress?.progress === 100
                      ? 'text-green-600 bg-green-100'
                      : 'text-gray-700 bg-gray-100',
                    'rounded-md px-4 py-1 mr-9 font-normal',
                  )}
                >
                  {t('dashboard:submitModal.complete', {
                    value: tab.progress?.progress,
                  })}
                </span>
              </div>
            }
            opened={false}
            key={tab.key}
            className="mt-6 border border-gray-300 rounded-md"
            titleClassName="py-3 px-[18px] border-b hover:bg-gray-50"
            toggleOnTitleClick
          >
            <div>{tab.component}</div>
          </Accordion>
        ))}
    </div>
  );
};

export default Confirmation;
