import { useTranslation } from 'react-i18next';

import { Checkbox } from 'components/Inputs';
import { useAppDispatch } from 'hooks';
import Link from 'components/Link';
import path from 'common/path';

import { addTandCaccept, addTandCview } from '../thunks';

interface TermsAndConditionCheckBoxProps {
  state: boolean;
  handleChange: () => void;
}

const TermsAndConditionCheckBox: React.FC<TermsAndConditionCheckBoxProps> = ({
  state,
  handleChange,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('profile');

  const onChange = (value) => {
    handleChange();

    if (value) {
      dispatch(addTandCaccept());
    }
  };

  const onClick = () => {
    dispatch(addTandCview());
  };

  return (
    <label className="flex items-center mb-10 sm:mb-[30px]">
      <Checkbox onChange={onChange} checked={state} disabled={false} />
      <span className="ml-3 text-sm leading-5 text-gray-700 font-medium">
        {t('profile:readAndUndestood')}
        <Link
          onClick={onClick}
          href={path.terms}
          target="_blank"
          className="font-medium"
          rel="noreferrer"
        >
          {t('profile:termsAndConditions')}
        </Link>{' '}
        and{' '}
        <Link
          href={path.privacy}
          target="_blank"
          className="font-medium"
          rel="noreferrer"
        >
          {t('profile:privacyPolicy')}
        </Link>
        .
      </span>
    </label>
  );
};

export default TermsAndConditionCheckBox;
