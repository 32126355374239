import { useTranslation } from 'react-i18next';

import BusinessAlert from './BusinessInformation/components/BusinessAlert';
import DashboardTabHeader from './DashboardTabHeader';
import OnboardingTabs from './OnboardingTabs';

const OnboardingTab = () => {
  const { t } = useTranslation('dashboard');

  return (
    <div>
      <DashboardTabHeader
        title={t('dashboard:title')}
        description={t('dashboard:description')}
      />
      <BusinessAlert />
      <OnboardingTabs />
    </div>
  );
};

export default OnboardingTab;
