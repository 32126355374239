import { useTranslation } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import { ConnectorTypes, Permissions } from 'entities/dashboard';
import { useAppSelector } from 'hooks';

import BankAccountInfoModal from './BankAccounts/components/BankAccountInfoModal';
import FundingAccountModal from './BankAccounts/components/FundingAccountModal';
import AccountingModal from './AccountingSoftware/AccountingModal';
import { getBankAccountsLength } from './BankAccounts/selectors';
import BankAccountModal from './BankAccounts/BankAccountModal';
import BankAccountList from './BankAccounts/BankAccountList';
import ConnectorWrapper from './Connectors/ConnectorWrapper';
import DashboardTabHeader from './DashboardTabHeader';
import DataSource from './Connectors/DataSource';
import FXProvider from './FXProvider/FXProvider';

const IntegrationTab = () => {
  const { t } = useTranslation('integration');

  const bankAccounts: number = useAppSelector(getBankAccountsLength);

  return (
    <div>
      <DashboardTabHeader
        title={t('integration:title')}
        description={t('integration:description')}
      />

      <DataSource />
      <PermissionSection permission={Permissions.erpIntegration}>
        <AccountingModal />
      </PermissionSection>

      <ConnectorWrapper type={ConnectorTypes.bank} isEmpty={!bankAccounts}>
        <BankAccountList />
      </ConnectorWrapper>

      <PermissionSection permission={Permissions.bankIntegration}>
        <>
          <BankAccountModal />
          <FundingAccountModal />
          <BankAccountInfoModal />
        </>
      </PermissionSection>

      <FXProvider />
    </div>
  );
};

export default IntegrationTab;
