import { createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';

import {
  deleteBusinessesDocuments,
  searchBusinessesDocuments,
  updateBusinessesDocuments,
  addBusinessesDocuments,
  getBusinessesDocuments,
  getBusinessesDocumentsFile,
  searchERPPreferencesData,
  updateERPPreferencesData,
  addERPPreferencesData,
} from './api';
import {
  mapApplicantData,
  getUpdateBusinessFields,
  getUpdateBusinessAddressFields,
  getUpdateBusinessPhoneFields,
  getAddDocumentFields,
  getUpdateBusinessERPPreferences,
  calcBusinessInfoPercentage,
  getMappedBusinessInfo,
} from './utils';
import telephoneService, {
  BUSINESS_PHONE_TYPE_CODE,
} from 'services/telephoneNumbers';
import { updateAddresses } from 'services/addresses';
import { ProfileState } from 'entities/profile';
import {
  BusinessDocumentTypes,
  BusinessInfoState,
} from 'entities/businessInfo';
import { updateBusinesses } from 'pages/Profile/api';
import { setLoadingProgress, setERPPreferences } from './businessInfoSlice';
import { setBusinessInfo } from 'pages/Profile/profileSlice';
import { DashboardState, Permissions } from 'entities/dashboard';
import { getUploadOptions } from 'utils/files';
import { objectMap } from 'utils/helpers';
import { hasPermission } from 'pages/dashboard/selectors';
import { RootState } from 'state/store';
import { getPerson } from 'services/persons';
import {
  getBusinessSourceTypes,
  searchBusinessesPersonsXrefs,
} from 'services/businesses';

export const checkBusinessInfoProgress: any = createAsyncThunk(
  'financial/checkBusinessInfoProgress',
  async (data: any) => {
    try {
      return calcBusinessInfoPercentage(data);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchBusinessAdditionalData: any = createAsyncThunk(
  'businessInfo/fetchBusinessAdditionalData',
  async (data: any, thunkAPI) => {
    try {
      const { business, person, personTelephoneNumber, businessPerson } = data;

      const { view: viewSourcePermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.erpIntegration,
      );

      let sourceTypes: any = [];
      if (viewSourcePermission) {
        sourceTypes = await getBusinessSourceTypes(business.businessesId);
      }

      const { view: viewBusinessPermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.onboardingBusiness,
      );

      if (!viewBusinessPermission) {
        return { sourceTypes };
      }

      const documents: any = await searchBusinessesDocuments({
        businessesId: business.businessesId,
        isActive: true,
      });

      const erpPreferences: any = await searchERPPreferencesData({
        businessesId: business.businessesId,
        isActive: true,
      });

      let applicant: any = {
        givenName1: person.givenName1,
        surnameFirst: person.surnameFirst,
        emailAddress: person.emailAddress,
        personCountryCallingCode: personTelephoneNumber.countryCallingCode,
        personTelephoneNumber: personTelephoneNumber.telephoneNumber,
        businessesPersonsRole: businessPerson.businessesPersonsRole,
      };

      if (person.emailAddress !== business.createdBy) {
        try {
          const realApplicant = await getPerson(business.createdBy);

          if (realApplicant) {
            const applicantBusinessPersons: any =
              await searchBusinessesPersonsXrefs({
                isActive: true,
                businessesId: business.businessesId,
                personsId: realApplicant.id,
              });

            applicant = {
              givenName1: realApplicant.givenName1,
              surnameFirst: realApplicant.surnameFirst,
              emailAddress: realApplicant.emailAddress,
              personCountryCallingCode: realApplicant.phone.countryCallingCode,
              personTelephoneNumber: realApplicant.phone.number,
              businessesPersonsRole: applicantBusinessPersons.length
                ? applicantBusinessPersons[0].businessesPersonsRole
                : '',
            };
          }
        } catch {}
      }

      const result = {
        sourceTypes: sourceTypes,
        documents,
        applicant: applicant ? mapApplicantData(applicant) : null,
        erpPreferences: erpPreferences.length ? erpPreferences[0] : null,
      };

      const businessInfo = getMappedBusinessInfo(result, data);

      thunkAPI.dispatch(checkBusinessInfoProgress(businessInfo));

      return result;
    } catch (e: any) {
      return Promise.reject(e);
    }
  },
);

export const uploadBusinessDocument: any = createAsyncThunk(
  'businessInfo/uploadBusinessDocument',
  async (data: any, thunkApi) => {
    const { getState, dispatch } = thunkApi;
    try {
      const { businessInfo, profile } = getState() as {
        businessInfo: BusinessInfoState;
        profile: ProfileState;
        dashboard: DashboardState;
      };
      const { documents } = businessInfo;
      const { business, person } = profile;

      dispatch(setLoadingProgress(1));

      const docs = documents.slice();

      const fields = getAddDocumentFields({
        person,
        business,
        file: data.files[0],
        businessesDocumentsTypesId: data.businessesDocumentsTypesId,
      });

      const options = getUploadOptions((value) =>
        dispatch(setLoadingProgress(value)),
      );

      const docId = await addBusinessesDocuments(fields, options);

      if (docId) {
        dispatch(setLoadingProgress(100));

        setTimeout(() => {
          dispatch(setLoadingProgress(0));
        }, 2000);

        const document: any = await getBusinessesDocuments(docId);
        docs.push(document);
      }

      return docs;
    } catch (e) {
      dispatch(setLoadingProgress(0));
      return Promise.reject(e);
    }
  },
);

export const deleteBusinessDocument: any = createAsyncThunk(
  'businessInfo/deleteBusinessDocument',
  async (id: number, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { businessInfo, profile } = getState() as {
        businessInfo: BusinessInfoState;
        profile: ProfileState;
      };
      const { documents } = businessInfo;
      const { person } = profile;

      const docId = await deleteBusinessesDocuments(id, person.emailAddress);

      if (docId) {
        return documents
          .slice()
          .filter((doc) => doc.businessesDocumentsId !== id);
      }

      return documents.slice();
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const downloadBusinessDocument: any = createAsyncThunk(
  'businessInfo/downloadBusinessDocument',
  async ({ guid, fileName }: any) => {
    try {
      const file: any = await getBusinessesDocumentsFile(guid);

      fileDownload(file, fileName);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateBusinessDocument: any = createAsyncThunk(
  'businessInfo/updateBusinessDocument',
  async (data: any, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { businessInfo, profile } = getState() as {
        businessInfo: BusinessInfoState;
        profile: ProfileState;
      };
      const { documents } = businessInfo;
      const { business, person } = profile;

      const { idnumber, businessesDocumentsTypesId } = data;

      const docs = documents.slice();
      const documentIndex: any = docs.findIndex(
        (doc) =>
          doc.businessesDocumentsTypesId !==
            BusinessDocumentTypes.centralIndexKey &&
          doc.businessesDocumentsTypesId !== BusinessDocumentTypes.dbaStatement,
      );

      const formData = new FormData();
      formData.append('idnumber', idnumber);
      formData.append('businessesDocumentsTypesId', businessesDocumentsTypesId);

      if (documentIndex >= 0) {
        if (!idnumber && !businessesDocumentsTypesId) {
          await deleteBusinessesDocuments(
            docs[documentIndex].businessesDocumentsId,
            person.emailAddress,
          );

          return docs.filter(
            (doc) =>
              doc.businessesDocumentsId !==
              docs[documentIndex].businessesDocumentsId,
          );
        }

        if (
          docs[documentIndex].idnumber !== idnumber ||
          docs[documentIndex].businessesDocumentsTypesId !==
            businessesDocumentsTypesId
        ) {
          formData.append('modifiedBy', person.emailAddress);
          formData.append(
            'businessesDocumentsId',
            docs[documentIndex].businessesDocumentsId.toString(),
          );

          await updateBusinessesDocuments(formData);

          docs[documentIndex] = {
            ...docs[documentIndex],
            idnumber,
            businessesDocumentsTypesId,
          };
        }
      } else if (idnumber || businessesDocumentsTypesId) {
        formData.append('isActive', 'true');
        formData.append('createdBy', person.emailAddress);
        formData.append('businessesId', business.businessesId.toString());

        const businessDocId = await addBusinessesDocuments(formData);
        const document: any = await getBusinessesDocuments(businessDocId);

        docs.push(document);
      }

      return docs;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateCentralIndexDocument: any = createAsyncThunk(
  'businessInfo/updateCentralIndexDocument',
  async (data: any, thunkApi) => {
    try {
      const { getState } = thunkApi;
      const { businessInfo, profile } = getState() as {
        businessInfo: BusinessInfoState;
        profile: ProfileState;
      };
      const { documents } = businessInfo;
      const { business, person } = profile;

      const { idnumber, businessesDocumentsTypesId } = data;

      const docs = documents.slice();
      const documentIndex: any = docs.findIndex(
        (doc) => doc.businessesDocumentsTypesId === businessesDocumentsTypesId,
      );

      const formData = new FormData();
      formData.append('idnumber', idnumber);

      if (documentIndex >= 0) {
        if (!idnumber) {
          await deleteBusinessesDocuments(
            docs[documentIndex].businessesDocumentsId,
            person.emailAddress,
          );
          return docs.filter(
            (doc) =>
              doc.businessesDocumentsId !==
              docs[documentIndex].businessesDocumentsId,
          );
        }

        if (docs[documentIndex].idnumber !== idnumber) {
          formData.append('modifiedBy', person.emailAddress);
          formData.append(
            'businessesDocumentsId',
            docs[documentIndex].businessesDocumentsId.toString(),
          );

          await updateBusinessesDocuments(formData);

          docs[documentIndex] = {
            ...docs[documentIndex],
            idnumber,
          };
        }
      } else if (idnumber) {
        formData.append('isActive', 'true');
        formData.append('createdBy', person.emailAddress);
        formData.append('businessesId', business.businessesId.toString());
        formData.append(
          'businessesDocumentsTypesId',
          businessesDocumentsTypesId.toString(),
        );

        const federalDocId = await addBusinessesDocuments(formData);
        const document: any = await getBusinessesDocuments(federalDocId);

        docs.push(document);
      }

      return docs;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateBusinessInfo: any = createAsyncThunk(
  'businessInfo/updateBusinessInfo',
  async (data: any, thunkApi) => {
    try {
      const { dispatch, getState } = thunkApi;
      const { businessInfo, profile } = getState() as {
        businessInfo: BusinessInfoState;
        profile: ProfileState;
      };
      const {
        business,
        businessAddress,
        businessTelephoneNumber,
        businessTelephoneNumberXref,
        person,
      } = profile;
      const { showDoingBusinessAs, erpPreferences } = businessInfo;

      const updateBusinessFields = getUpdateBusinessFields({
        data,
        showDoingBusinessAs,
        business,
      });

      let isFormUpdate = true;

      if (!business.businessesId) {
        return isFormUpdate;
      }

      if (updateBusinessFields) {
        isFormUpdate = false;
        await updateBusinesses({
          logoFileShareURI: business.logoFileShareURI,
          faviconFileShareURI: business.faviconFileShareURI,
          businessesId: business.businessesId,
          modifiedBy: person.emailAddress,
          ...updateBusinessFields,
        });
      }

      const updateBusinessAddressFields = getUpdateBusinessAddressFields({
        data,
        businessAddress,
      });

      if (updateBusinessAddressFields) {
        isFormUpdate = false;
        await updateAddresses({
          addressesId: businessAddress.addressesId,
          modifiedBy: person.emailAddress,
          ...updateBusinessAddressFields,
        });
      }

      const updateBusinessPhoneFields: any = getUpdateBusinessPhoneFields({
        data,
        businessTelephoneNumber,
      });

      let businessesTelephoneNumbersXrefId: any =
        businessTelephoneNumberXref.businessesTelephoneNumbersXrefId || 0;

      if (updateBusinessPhoneFields) {
        isFormUpdate = false;

        const telephoneNumbersId = await telephoneService.addTelephoneNumbers({
          createdBy: person.emailAddress,
          ...updateBusinessPhoneFields,
        });

        updateBusinessPhoneFields.telephoneNumbersId = telephoneNumbersId;

        if (businessTelephoneNumberXref.businessesTelephoneNumbersXrefId) {
          businessesTelephoneNumbersXrefId =
            await telephoneService.updateBusinessesTelephoneNumbersXrefs({
              businessesTelephoneNumbersXrefid:
                businessTelephoneNumberXref.businessesTelephoneNumbersXrefId,
              telephoneNumbersId,
              modifiedBy: person.emailAddress,
            });
        } else {
          businessesTelephoneNumbersXrefId =
            await telephoneService.addBusinessesTelephoneNumbersXrefs({
              createdBy: person.emailAddress,
              telephoneNumbersTypeCode: BUSINESS_PHONE_TYPE_CODE,
              businessesId: business.businessesId,
              telephoneNumbersId,
              isActive: true,
            });
        }
      }

      const updateErpPreferences = getUpdateBusinessERPPreferences({
        data,
        erpPreferences,
      });

      if (updateErpPreferences && !profile.onboarding) {
        isFormUpdate = false;

        if (erpPreferences) {
          const erpData = {
            erppreferenceDataId: erpPreferences.erppreferenceDataId,
            modifiedBy: person.emailAddress,
            ...objectMap(updateErpPreferences, (value) =>
              value === '' ? ' ' : value,
            ),
          };

          await updateERPPreferencesData(erpData);

          dispatch(
            setERPPreferences({
              ...erpPreferences,
              ...updateErpPreferences,
            }),
          );
        } else {
          const erpData = {
            isActive: true,
            businessesId: business.businessesId,
            createdBy: person.emailAddress,
            requiresDigitalSignature: true,
            ...updateErpPreferences,
          };

          await addERPPreferencesData(erpData);

          dispatch(setERPPreferences(erpData));
        }
      }

      dispatch(
        setBusinessInfo({
          business: updateBusinessFields,
          businessAddress: {
            ...businessAddress,
            ...updateBusinessAddressFields,
          },
          businessTelephoneNumber: {
            ...businessTelephoneNumber,
            ...updateBusinessPhoneFields,
          },
          businessTelephoneNumberXref: {
            businessesTelephoneNumbersXrefId,
          },
        }),
      );

      await dispatch(
        updateBusinessDocument({
          businessesDocumentsTypesId: data.businessesDocumentsTypesId,
          idnumber: data.idNumber,
        }),
      );

      await dispatch(
        updateCentralIndexDocument({
          businessesDocumentsTypesId: BusinessDocumentTypes.centralIndexKey,
          idnumber: data.centralIndexKey,
        }),
      );

      dispatch(checkBusinessInfoProgress(data));

      return isFormUpdate;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
