import DashboardTabsNav from './DashboardTabsNav';

type DashboardTabHeaderProps = {
  title: string;
  description: string;
};

const DashboardTabHeader: React.FC<DashboardTabHeaderProps> = ({
  title,
  description,
}) => (
  <div className="flex items-start justify-between my-6 w-full">
    <div className="pr-6 max-w-[790px]">
      <p className="heading-3xl mb-2">{title}</p>
      <p className="text-secondary">{description}</p>
    </div>
    <DashboardTabsNav />
  </div>
);

export default DashboardTabHeader;
