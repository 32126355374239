import { createAsyncThunk } from '@reduxjs/toolkit';
import { rm } from 'lockr';

import { getActiveProductsTypeCodes } from 'pages/dashboard/components/Progress/selectors';
import { getMerchant } from 'pages/dashboard/components/MerchantServices/selectors';
import { fetchBankAccounts } from 'pages/dashboard/components/BankAccounts/thunks';
import { fetchBPSTrack } from 'pages/dashboard/components/Configuration/thunks';
import { fetchProfileByEmail, updateBusiness } from 'pages/Profile/thunks';
import { ApplicationStatuses, ProductTypeCodes } from 'entities/progress';
import { erpCreationWrapper, erpUpdateBusiness } from 'services/erp';
import { getApplicationStatuses } from 'pages/dashboard/selectors';
import { showResponseError } from 'components/notification/utils';
import { mcTrackBPSCreationWrapper } from 'services/mcTrackBPS';
import { verifyCompliancePerson } from 'services/persons';
import { getResponseErrorMessages } from 'utils/helpers';
import { ApplicationStatus } from 'entities/dashboard';
import { ComplianceStatuses } from 'common/compliance';
import { prysymCreateMerchant } from 'services/prysym';
import { tgpaCreationWrapper } from 'services/tgpa';
import { RootState } from 'state/store';
import {
  addBusinessesRequests,
  searchBusinessesRequests,
  getBusinessesRequests,
} from 'services/businesses';

import { getProfile } from './selectors';
import { isMerchantValid } from './utils';

export const approveCompliance: any = createAsyncThunk(
  'compliance/approveCompliance',
  async (complianceReference: string, thunk) => {
    const { getState, dispatch } = thunk;

    try {
      rm('serverTimeout');

      const state = getState() as RootState;

      const { person, business } = getProfile(state);

      try {
        const result = await verifyCompliancePerson({
          businessesId: business.businessesId,
          createdBy: person.emailAddress,
        });

        if (!result) {
          const message =
            'Business owner must complete Microsoft Authentication flow to enable access to SMART-Hub and continue the payment workflow.';
          showResponseError(message, dispatch);
          return false;
        }
      } catch (e: any) {
        const message = getResponseErrorMessages(e.response, true);
        showResponseError(message, dispatch);
        return false;
      }

      const merchant = getMerchant(state);
      const activeProducts = getActiveProductsTypeCodes(state);

      await mcTrackBPSCreationWrapper({
        businessesId: business.businessesId,
        createdBy: person.emailAddress,
      });

      if (
        activeProducts.includes(ProductTypeCodes.services) &&
        isMerchantValid(merchant)
      ) {
        await prysymCreateMerchant(business.businessesId);
      }

      await tgpaCreationWrapper({
        businessesId: business.businessesId,
        modifiedBy: person.emailAddress,
        complianceReference,
      });

      await erpCreationWrapper({
        businessesId: business.businessesId,
        createdBy: person.emailAddress,
      });

      const statuses: ApplicationStatus[] = getApplicationStatuses(
        getState() as RootState,
      );

      const status: ApplicationStatus | undefined = statuses.find(
        (s: ApplicationStatus) => s.typeCode === ApplicationStatuses.approved,
      );

      if (status) {
        await dispatch(
          updateBusiness({
            applicationStatusID: status.applicationStatusId,
            applicationStatusTypeCode: status.typeCode,
            complianceStatusID: ComplianceStatuses.approved,
            complianceUpdatedBy: person.emailAddress,
            complianceReference,
          }),
        );

        await erpUpdateBusiness({
          businessesId: business.businessesId,
          createdBy: person.emailAddress,
        });

        dispatch(fetchProfileByEmail(person.emailAddress));
        dispatch(fetchBPSTrack());
        dispatch(fetchBankAccounts());
      }
    } catch (e: any) {
      const message = getResponseErrorMessages(e.response, true);
      showResponseError(message, dispatch);
      return Promise.reject(e);
    }
  },
);

export const declineCompliance: any = createAsyncThunk(
  'compliance/declineCompliance',
  async (complianceReference: string, thunk) => {
    rm('serverTimeout');
    const { getState, dispatch } = thunk;

    try {
      const { person, business } = getProfile(getState() as RootState);
      const statuses: ApplicationStatus[] = getApplicationStatuses(
        getState() as RootState,
      );

      const status: ApplicationStatus | undefined = statuses.find(
        (s: ApplicationStatus) => s.typeCode === ApplicationStatuses.declined,
      );

      if (status) {
        const fields = {
          applicationStatusID: status.applicationStatusId,
          applicationStatusTypeCode: status.typeCode,
          complianceStatusID: ComplianceStatuses.declined,
          complianceUpdatedBy: person.emailAddress,
          complianceReference,
        };

        await dispatch(updateBusiness(fields));

        if (business.complianceStatusID) {
          await erpUpdateBusiness({
            businessesId: business.businessesId,
            createdBy: person.emailAddress,
          });
        }

        dispatch(fetchProfileByEmail(person.emailAddress));
      }
    } catch (e: any) {
      const message = getResponseErrorMessages(e.response, true);
      showResponseError(message, dispatch);
      return Promise.reject(e);
    }
  },
);

export const suspendCompliance: any = createAsyncThunk(
  'compliance/suspendCompliance',
  async (complianceReference: string, thunk) => {
    const { getState, dispatch } = thunk;

    try {
      const { person, business } = getProfile(getState() as RootState);
      const statuses: ApplicationStatus[] = getApplicationStatuses(
        getState() as RootState,
      );

      const status: ApplicationStatus | undefined = statuses.find(
        (s: ApplicationStatus) => s.typeCode === ApplicationStatuses.suspended,
      );

      if (status) {
        await dispatch(
          updateBusiness({
            applicationStatusID: status.applicationStatusId,
            applicationStatusTypeCode: status.typeCode,
            complianceStatusID: ComplianceStatuses.pending,
            complianceUpdatedBy: person.emailAddress,
            complianceReference,
          }),
        );

        if (business.complianceStatusID) {
          await erpUpdateBusiness({
            businessesId: business.businessesId,
            createdBy: person.emailAddress,
          });
        }
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const activateCompliance: any = createAsyncThunk(
  'compliance/activateCompliance',
  async (complianceReference: string, thunk) => {
    const { getState, dispatch } = thunk;

    try {
      const { person } = getProfile(getState() as RootState);
      const statuses: ApplicationStatus[] = getApplicationStatuses(
        getState() as RootState,
      );

      const status: ApplicationStatus | undefined = statuses.find(
        (s: ApplicationStatus) =>
          s.typeCode === ApplicationStatuses.pendingApproval,
      );

      if (status) {
        await dispatch(
          updateBusiness({
            applicationStatusID: status.applicationStatusId,
            applicationStatusTypeCode: status.typeCode,
            complianceStatusID: ComplianceStatuses.pending,
            complianceUpdatedBy: person.emailAddress,
            complianceReference,
          }),
        );
      }
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchBusinessRequests: any = createAsyncThunk(
  'compliance/fetchBusinessRequests',
  async (_: void, thunk) => {
    try {
      const { getState } = thunk;
      const { business } = getProfile(getState() as RootState);

      const requests = await searchBusinessesRequests({
        businessesId: business.businessesId,
        isActive: true,
      });

      return requests;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const sendMessage: any = createAsyncThunk(
  'compliance/sendMessage',
  async (message: string, thunk) => {
    const { getState } = thunk;

    try {
      const { person, business } = getProfile(getState() as RootState);

      const data = {
        businessesId: business.businessesId,
        subject: `${person.givenName1} ${person.surnameFirst}`,
        messageText: message,
        createdBy: person.emailAddress,
        isActive: true,
        isHidden: false,
      };

      const requestId: any = await addBusinessesRequests(data);
      const requests = await getBusinessesRequests(requestId);

      return requests?.[0] || null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
