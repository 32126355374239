import { setDashboardTab } from 'pages/dashboard/dashboardSlice';
import { getDashboardTab } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import { ChevronRightIcon } from 'components/icons';
import Button from 'components/Button';

import { dashboardTabs } from './DashboardTabs';

export default function DashboardTabsNav() {
  const dispatch = useAppDispatch();

  const currentTab = useAppSelector(getDashboardTab);

  const handleChange = (value: number) => {
    dispatch(setDashboardTab(value));
  };

  return (
    <div className="flex items-center">
      <Button
        size="medium"
        variant="secondary-outline"
        paddingClass="px-2"
        className="mr-3"
        onClick={() => handleChange(currentTab - 1)}
        disabled={currentTab === 0}
      >
        <ChevronRightIcon className="rotate-180" />
      </Button>
      <Button
        size="medium"
        variant="secondary-outline"
        paddingClass="px-2"
        onClick={() => handleChange(currentTab + 1)}
        disabled={currentTab === dashboardTabs.length - 1}
      >
        <ChevronRightIcon />
      </Button>
    </div>
  );
}
