import { createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';

import { ERPAcountStatuses, ERPProviders } from 'entities/accounting';
import { getAllConnectors, searchErpAccounts } from 'services/erp';
import { updateBusinessSourceTypes } from 'services/businesses';
import { hasPermission } from 'pages/dashboard/selectors';
import { getPerson } from 'pages/Profile/selectors';
import { getBusinessesId } from 'utils/authService';
import { getSubDomain } from 'utils/domainService';
import { Permissions } from 'entities/dashboard';
import { RootState } from 'state/store';
import { config } from 'config';

import { getProvider, getErpAccount, getErpAccountStatuses } from './selectors';
import {
  addErpAccount as addErpAccountData,
  getErpAccounts,
  updateErpAccount as updateErpAccountData,
  updateIsActiveErpAccount,
  searchERPIntegrators,
  getGuideByLink,
  erpAuthentication,
  erpValidateConnection,
} from './api';
import {
  mapedSaveAccountsData,
  mapedJsonData,
  getJsonUpdated,
  getMappedConnectors,
} from './utils';

export const fetchAllConnectors: any = createAsyncThunk(
  'accountingSlice/fetchAllConnectors',
  async (filterType: string, thunkAPI) => {
    try {
      const { view: viewERPPermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.erpIntegration,
      );

      if (!viewERPPermission) {
        return [];
      }

      const connectors = await getAllConnectors(filterType);
      const integrators = await searchERPIntegrators({ isActive: true });

      return getMappedConnectors({ connectors, integrators });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchErpAccount: any = createAsyncThunk(
  'accountingSlice/fetchErpAccount',
  async (accountId: number) => {
    try {
      const accounts: any = await getErpAccounts(accountId);
      return accounts.length ? accounts[0] : null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const erpAuth: any = createAsyncThunk(
  'accountingSlice/erpAuth',
  async (erpName: string, thunkApi) => {
    try {
      const { getState } = thunkApi;

      const state = getState() as RootState;
      const { erpaccountsId } = getErpAccount(state);
      const { emailAddress } = getPerson(state);
      const { auths } = getProvider(state);

      const payload: any = {
        erpaccountsId,
        createdBy: emailAddress,
      };

      if (erpName === ERPProviders.quickbooks) {
        const redirectUrl = auths.find((a) => a.key === 'RedirectUrl')?.value;

        if (redirectUrl) {
          payload.redirectUri = `${
            config.PROTOCOL
          }${getSubDomain()}${redirectUrl}`;
        }
      }

      const result = await erpAuthentication(erpName, payload);

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const erpValidate: any = createAsyncThunk(
  'accountingSlice/erpValidate',
  async (_: void, thunkApi) => {
    try {
      const { getState, dispatch } = thunkApi;

      const { emailAddress } = getPerson(getState() as RootState);
      const { erpaccountsId } = getErpAccount(getState() as RootState);

      const result = await erpValidateConnection(erpaccountsId, emailAddress);

      await dispatch(fetchErpAccount(erpaccountsId));

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchErpAccounts: any = createAsyncThunk(
  'accountingSlice/fetchErpAccounts',
  async (_: void, thunkAPI) => {
    try {
      const { view: viewERPPermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.erpIntegration,
      );

      if (!viewERPPermission) {
        return [];
      }

      const businessesId = getBusinessesId();

      const data = await searchErpAccounts({
        businessesId,
        isActive: true,
      });

      return data;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const addErpAccount: any = createAsyncThunk(
  'accountingSlice/addErpAccount',
  async (formData: any, thunkApi) => {
    try {
      const { getState, dispatch } = thunkApi;

      const { auths, connectorId } = getProvider(getState() as any);
      const { emailAddress } = getPerson(getState() as any);
      const businessesId = getBusinessesId();

      const jsonData = JSON.stringify(mapedJsonData(formData, auths));

      const data = mapedSaveAccountsData({
        createdBy: emailAddress,
        connectorId,
        businessesId,
        jsonData,
      });

      const id = await addErpAccountData(data);

      await dispatch(fetchErpAccount(id));

      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateErpAccount: any = createAsyncThunk(
  'accountingSlice/updateErpAccount',
  async ({ formData, erpaccountsId, createdBy }: any, thunkApi) => {
    try {
      const { getState, dispatch } = thunkApi;
      const state = getState() as RootState;

      const { auths, connectorId } = getProvider(state);
      const { jsone } = getErpAccount(state);
      const { emailAddress } = getPerson(state);
      const erpAccountStatuses = getErpAccountStatuses(state);
      const businessesId = getBusinessesId();

      const jsonUpdated = getJsonUpdated(formData, jsone);
      const erpaccountStatusesId = jsonUpdated
        ? erpAccountStatuses.find(
            (status) => status.description === ERPAcountStatuses.verification,
          )?.eRPAccountStatusesId
        : null;

      const jsonData = JSON.stringify(mapedJsonData(formData, auths));
      const data = mapedSaveAccountsData({
        modifiedBy: emailAddress,
        createdBy,
        connectorId,
        businessesId,
        jsonData,
        erpaccountsId,
        erpaccountStatusesId,
      });

      const accountId = await updateErpAccountData(data);

      await dispatch(fetchErpAccount(accountId));

      return null;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const disconnectErpAccount: any = createAsyncThunk(
  'accountingSlice/disconnectErpAccount',
  async (data: any, thunkAPI) => {
    try {
      const { createdBy } = getPerson(thunkAPI.getState() as RootState);

      const { onSuccess, ...other } = data;

      await updateIsActiveErpAccount({
        ...other,
        isActive: false,
        modifiedBy: other.modifiedBy || createdBy,
      });

      if (onSuccess) {
        onSuccess();
      }

      return '';
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const downloadGuide: any = createAsyncThunk(
  'accountingSlice/downloadGuide',
  async ({ guideFileShareUri, filename }: any) => {
    try {
      const file: any = await getGuideByLink(guideFileShareUri);

      fileDownload(file, filename);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateSourceTypes: any = createAsyncThunk(
  'accountingSlice/updateSourceTypes',
  async (sourceTypes: number[], thunkAPI) => {
    try {
      const { emailAddress } = getPerson(thunkAPI.getState() as RootState);

      const businessId: number = getBusinessesId();

      await updateBusinessSourceTypes(businessId, {
        sourceTypes,
        createdBy: emailAddress,
      });
    } catch (e) {
      return Promise.reject(e);
    }
  },
);
