import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Loading from 'components/Loading';
import { getUrlParams } from 'utils/url';
import path from 'common/path';

import { erpUpdateToken } from '../api';

const apiName = 'QuickBooks';

const ErpCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const params: any = getUrlParams();

    const updateToken = async () => {
      try {
        await erpUpdateToken(apiName, {
          ...params,
          redirectUri: `${window.location.origin}${path.callback}`,
        });

        window.location.replace(`${path.callback}?success=true`);
      } catch (e) {}
    };

    if (!params) {
      navigate(path.dashboard);
    }

    if (params && !params.success) {
      updateToken();
    }
  }, [navigate]);

  return <Loading fullHeight />;
};

export default ErpCallback;
