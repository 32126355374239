import { createSlice } from '@reduxjs/toolkit';

import { searchPersonByEmail } from 'components/InvitePersons/thunks';
import { authorizeSignatory } from 'pages/Profile/thunks';

interface SubmitDashboardProps {
  isModalOpened: boolean;
  isAgreed: boolean;
  authorizing: boolean;
}

export const initialState: SubmitDashboardProps = {
  isModalOpened: false,
  isAgreed: false,
  authorizing: false,
};

const submitDashboardSlice = createSlice({
  name: 'submitDashboard',
  initialState,
  reducers: {
    setIsAgreed(state, action) {
      state.isAgreed = action.payload;
    },
    setIsModalOpened(state, action) {
      state.isModalOpened = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(authorizeSignatory.pending, (state) => {
      state.authorizing = true;
    });
    builder.addCase(authorizeSignatory.rejected, (state) => {
      state.authorizing = false;
    });
    builder.addCase(authorizeSignatory.fulfilled, (state) => {
      state.authorizing = false;
    });
    builder.addCase(searchPersonByEmail.fulfilled, (state) => {
      state.isModalOpened = false;
    });
  },
});

export const { setIsAgreed, setIsModalOpened } = submitDashboardSlice.actions;

export default submitDashboardSlice.reducer;
