import { createSelector } from '@reduxjs/toolkit';

export const getRoot = (state) => state.modal;

export const getIsOpened = createSelector(
  [getRoot],
  ({ isOpened }) => isOpened,
);

export const getModalData = createSelector([getRoot], ({ data }) => data);
