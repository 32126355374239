import api from 'api';

export const searchBanksIntegrators = () => {
  return api.get('BanksIntegrators/SearchBanksIntegrators');
};

export const searchBanksIntegratorsByDetails = (data) => {
  return api.post('BanksIntegrators/SearchBanksIntegratorsBydetails', data);
};

export const getBankIntegratorsFile = (id) => {
  return api.get(`/BanksIntegrators/GetBanksIntegratorsFile/${id}`, {
    responseType: 'blob',
  });
};

export const searchBankAccountTypes = () => {
  return api.get('/BankAccountTypes/SearchBankAccountTypes');
};

export const getBankAccounts = (id) => {
  return api.get(`/BankAccounts/GetBankAccounts/${id}`);
};

export const addBankAccounts = (data) => {
  return api.post('/BankAccounts/AddBankAccounts', data);
};

export const updateBankAccounts = (data) => {
  return api.put('/BankAccounts/UpdateBankAccounts', data);
};

export const updateIsActiveBankAccounts = (data) => {
  return api.put('/BankAccounts/UpdateIsActiveBankAccounts', data);
};

export const updateBankAccountsBlobReference = (data) => {
  return api.put('/BankAccounts/UpdateBankAccountsBlobReference', data);
};

export const getERPBankAccounts = (provider: string, businessId: number) => {
  return api.post(`/${provider}/GetERPBankAccounts/${businessId}`);
};
