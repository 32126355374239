import LayoutSignUp from 'components/layouts/LayoutSignUp';
import PoweredBy from 'components/layouts/PoweredBy';
import Link from 'components/Link';
import { CheckCircleIcon, CheckIcon } from 'components/icons';
import { formatDate } from 'utils/date';

const Title = ({ children, mb = 6 }) => (
  <h4 className={`heading-3xl mb-${mb}`}>{children}</h4>
);

const SubTitle = ({ children }) => (
  <h5 className="text-[24px] mb-6 text-gray-900 font-bold">{children}</h5>
);

const Typography = ({ children, last = false }) => (
  <p className={`${last ? 'mb-12' : 'mb-6'} text-gray-700`}>{children}</p>
);

const ListItem = ({ children }) => (
  <div className="flex items-start py-6">
    <CheckIcon className="text-green-500 flex-shrink-0 w-6 h-6 mt-[2px]" />
    <div className="ml-3 text-gray-500">{children}</div>
  </div>
);

const List = ({ children }) => (
  <div className="divide-y divide-gray-200 -my-6">{children}</div>
);

const ListWrapper = ({ title, text, children }) => (
  <div className="flex flex-wrap -mx-6 my-24">
    <div className="max-w-[424px] mx-6">
      <Title mb={4}>{title}</Title>
      <p className="text-[18px] text-gray-500 whitespace-pre-wrap">{text}</p>
    </div>
    <div className="flex-1 mx-6">{children}</div>
  </div>
);

const PrivacyPolicy = () => {
  return (
    <LayoutSignUp fullWidth>
      <div className="max-w-[1096px] m-auto">
        <div className="flex mt-3 mb-5">
          <div className="flex items-center rounded-full bg-gray-50 text-sm p-[5px]">
            <CheckCircleIcon className="mr-[14px] text-green-500" />
            <span className="text-gray-500">Last updated:</span>&nbsp;
            <span className="font-medium text-gray-900">
              {formatDate('2022-04-01', 'LL')}
            </span>
          </div>
        </div>

        <h2 className="text-5xl font-extrabold tracking-tight mb-12">
          <span className="text-blue-600">Transcard</span> Privacy Policy
        </h2>

        <div className="mb-12">
          <Typography>
            This Privacy Policy (“Policy”) explains how Transcard Payments, LLC
            and its agents, representatives, assigns, successors and service
            providers (collectively, “Company”, “Transcard”, “we,” “us,”),
            collect, store, use, share, transfer and protect your personal
            information, which we obtain when you visit our websites, use our
            mobile application or use our services. You accept this Policy and
            all of its terms whenever you access or use our products, website,
            services, features and technologies, including our mobile
            application (collectively, the “Services”). Please read this Policy
            carefully.
          </Typography>
          <Typography>
            Remember that your use of our Services is at all times subject to
            our Transcard’s Terms of Service SMART-Hub.com.
          </Typography>
          <Typography>
            This Policy covers how we treat “personal information” that we
            gather or collect from you when you access or use our Services. This
            includes, without limitation, information we collect when you access
            or use the mobile application operated by us to view, manage, or
            conduct transactions on the deposit or credit account (“Banking
            Account”) or the Transcard Debit Card or Credit Card (each, a
            “Transcard Card”), both of which are made available by a partnering
            financial institution (“Bank”). However, it does not apply to the
            Banking Account or Transcard Card themselves. Please refer to the
            terms and conditions you enter into with the Bank and the Bank’s
            privacy policy for information concerning those products and your
            privacy rights with respect to those products.
          </Typography>
          <Typography>
            Our Services are intended for users in the U.S.A. (“Territory”). If
            you visit or use one of our Services, your information may be
            processed in the Territory and/or transferred outside the Territory
            to other third parties in connection with the Services rendered by
            us . We currently do not offer products or services to customers
            outside the Territory.
          </Typography>
          <Typography last>
            This Policy only applies to us and not to any third-party sites or
            services you may access through our website, our mobile application,
            or by using any of our Services and does not apply to information
            submitted or collected by websites maintained by other companies or
            organizations to which we may link or who may link to us. We are not
            responsible for the actions and privacy policies of third-party
            websites or services rendered by such parties.
          </Typography>

          <Title>What is Personal Information?</Title>
          <Typography last>
            When we use the term “personal information” in this Policy, we mean
            information that identifies, relates to, describes, is reasonably
            capable of being associated with, or could reasonably be linked,
            directly or indirectly, to you, including without limitation
            sensitive personal information. The term “personal information” does
            not include aggregated or deidentified information that is
            maintained in a form that is not reasonably capable of being
            associated with or linked to you.
          </Typography>

          <Title>Our Collection of Personal Information</Title>
          <Typography last>
            From the first moment you interact with us, we are collecting
            personal information about you. Sometimes we collect personal
            information automatically when you interact with our services and
            sometimes we collect the personal information directly from you. At
            times, we may collect personal information about you from other
            sources and third parties, even before our first direct interaction.
          </Typography>

          <ListWrapper
            title="Personal Information You Provide"
            text="We collect the following personal information you provide in connection with our Services:"
          >
            <List>
              <ListItem>
                account registration information, such as your name, email,
                postal address, user name and password, telephone number, and
                preferences when using our Services;
              </ListItem>
              <ListItem>
                information about your personal characteristics, such as your
                full name, age, phone and/or cell number, date of birth,
                nationality, gender, signature, utility bills, citizenship,
                disability, military status, and photographs;
              </ListItem>
              <ListItem>
                government-issued identification, such as your social security
                number or equivalent, driver’s license number, taxpayer
                identification number/permanent account number, and passport
                number);
              </ListItem>
              <ListItem>
                financial information, such as your bank account information,
                including without limitation, account and routing numbers, tax
                information; credit and debit card information, credit history,
                and credit scores and access to the information you provide us
                via SMART-Hub.com;
              </ListItem>
              <ListItem>
                employment-related information, such as your job title and
                salary information;
              </ListItem>
              <ListItem>
                other information you may provide, such as information you
                provide when you contact us with questions, feedback, survey
                responses, participation in contests, promotions or other
                prospective seller marketing forms or devices, suggestions for
                improvements of our services or products, referrals, or
                otherwise communicate with us; and
              </ListItem>
              <ListItem>
                personal information you affirmatively disclose to us in
                connection with your interactions with us, including through
                your use of our Services.
              </ListItem>
            </List>
          </ListWrapper>

          <Typography last>
            In order to use the facilities and Services, you may be required,
            from time to time, at the request of Transcard or other third
            parties to provide certain additional personal information. Our
            collection, use, and disclosure of such personal information may be
            subject to additional privacy disclosures that we will make
            available to you as required by law.
          </Typography>

          <ListWrapper
            title="Personal Information Automatically Collected"
            text="As is true of most digital platforms, we collect certain personal
            information automatically when you visit our online services,
            including:"
          >
            <List>
              <ListItem>
                log file data, including your internet protocol (IP) address,
                operating system, browser type, browser id, date/time of visit,
                and pages visited;
              </ListItem>
              <ListItem>
                analytics data, including the electronic path you take to our
                services, through our services and when exiting our services, as
                well as your usage and activity on our services, such as the
                links and object you view, click or otherwise interact with
                (also known as “Clickstream Data”);
              </ListItem>
              <ListItem>
                location data, including your general geographic location based
                on your IP address or more precise location when accessing our
                services through a mobile device;
              </ListItem>
              <ListItem>
                information sent by your mobile device or web browser. We
                collect certain information that your mobile device sends when
                you use our Services. For example, we may collect a device
                identifier, user settings and the operating system of your
                device, as well as information about your use of our Services;
                and
              </ListItem>
              <ListItem>
                “Web Beacons” (also known as Web bugs, pixel tags or clear GIFs)
                are tiny graphics with a unique identifier that may be included
                on our Services for several purposes, including to deliver or
                communicate with cookies, to track and measure the performance
                of our Services, to monitor how many visitors view our Services,
                and to monitor the effectiveness of our advertising. Unlike
                cookies, which are stored on the user’s hard drive, Web Beacons
                are typically embedded invisibly on web pages (or in an e-mail).
              </ListItem>
            </List>
          </ListWrapper>
          <Typography last>
            For information about our and our third-party partners’ use of
            cookies and related technologies to collect information
            automatically, and any choices you may have in relation to its
            collection, please see our Cookie Notice below.
          </Typography>

          <ListWrapper
            title="Personal Information from Other Sources and Third Parties"
            text={
              'We also obtain personal information from other sources, which we often combine with personal information we collect either automatically or directly from you.\n\nWe receive the same categories of personal information as described above from the following sources and third parties:'
            }
          >
            <List>
              <ListItem>
                public databases and ID verification partners. We obtain
                information about you from public databases and ID verification
                partners to verify your identity and information you may provide
                us.
              </ListItem>
              <ListItem>
                third party sources and credit reporting agencies. We also
                obtain information about you from third party sources. Such
                information may include credit report information, identity
                verification information, fraud detection information,
                collection information, and information about any person or
                corporation with whom you have had, currently have, or may have
                a financial relationship.
              </ListItem>
              <ListItem>
                data append services, data brokers, and joint marketing
                partners. We receive personal information from these sources so
                that we can better understand which of our Services may be of
                interest to you.
              </ListItem>
              <ListItem>
                external bank account information providers. When you use
                certain Services, we may collect from you, e.g., usernames,
                passwords, account numbers, and other account information for
                third-party websites and Internet banking services. This
                information is used to obtain your account, transaction, and
                other banking information from the relevant financial
                institution on your behalf.
              </ListItem>
              <ListItem>
                lenders, financial institutions, and brokers. We obtain
                information about you from your lender, financial institution,
                or broker.
              </ListItem>
            </List>
          </ListWrapper>
          <Typography last>
            We also collect account information from you when you open a Banking
            Account, use the SMART Hub, obtain loans from lending/financial
            institutions and/or obtain a Transcard Card through our Services.
            This information is used to obtain your account, transaction, and
            other banking information from the relevant financial institution on
            your behalf in order to display the information to you or to fulfill
            your requests for certain products, services, or transactions
            through a Service.
          </Typography>

          <ListWrapper
            title="Our Use of Personal Information"
            text="We use personal information we collect to:"
          >
            <List>
              <ListItem>
                to provide you with our Services. We use your personal
                information to provide you with our Services;
              </ListItem>
              <ListItem>
                to fulfill your requests for our products, programs, and
                Services, to respond to your inquiries about our Services, and
                to offer you other products, programs, or services that we
                believe may be of interest to you.
              </ListItem>
              <ListItem>
                to improve our Services and run our Services. We may use
                personal information to research, analyze, and understand how
                users access and use our Services, both on an aggregated and
                individualized basis, in order to help us improve our Services
                and develop new products. We also use personal information to
                operate, maintain, test, improve (including developing new
                products and services), enhance, update, monitor, diagnose, fix,
                and improve our Services;
              </ListItem>
              <ListItem>
                to correspond with you. We use personal information to provide
                you with customer support anytime you reach out to us with
                questions or issues arising from your account. We also use
                personal information to send customers technical alerts,
                updates, security notifications, and administrative
                communications;
              </ListItem>
              <ListItem>
                personalize your experience. When you use our services and
                sites, as well other thirdparty sites and services. In this
                case, we might use tracking technologies like cookies;
              </ListItem>
              <ListItem>
                for marketing purposes. We may use your personal information to
                communicate with you regarding certain products, services or
                promotions offered by us or certain thirdparty products and
                services we believe you may be interested in, provided that you
                have not already opted out of receiving such communications;
              </ListItem>
              <ListItem>
                send you locally relevant options. We can only do this if you
                make your location available to us. If you do, this can help us
                enhance the security of our sites and services, and customize
                our Services by sending your relevant coupons and otherwise
                personalizing content for you (e.g., through appropriate ads and
                search results);
              </ListItem>
              <ListItem>
                make it easy for you to find and connect with others. For
                example, if you let us access your contacts, we can suggest
                connections with people you may know;
              </ListItem>
              <ListItem>
                to detect, investigate, and prevent fraud and security issues.
                We use your personal information as necessary to protect our
                Company, our customers, and our business partners, including by
                investigating and preventing fraudulent activities, unauthorized
                access to the Services, and other illegal activities;
              </ListItem>
              <ListItem>
                Verify information you have provided to us or to protect against
                fraudulent or illegal activities;
              </ListItem>
              <ListItem>
                comply with all applicable laws and regulations in the U.S.A and
                India, as the case may be. We use your personal information as
                we believe necessary or appropriate to comply with applicable
                laws in the U.S.A and in India, as the case may be, lawful
                requests, and legal processes, such as to comply with applicable
                anti-money laundering laws, to report to credit bureaus, and to
                respond to subpoenas or requests from the respective government
                authorities; and
              </ListItem>
              <ListItem>
                prevent, investigate, or provide notice of fraud or unlawful or
                criminal activity, and enforce and carry out contracts and
                agreements between us and you.
              </ListItem>
            </List>
          </ListWrapper>
          <Typography>
            You acknowledge that if we determine that any personal information
            you have provided or uploaded violates this Policy, we have the
            right, in our absolute discretion, to delete or destroy such
            personal information without incurring any liability to you.
          </Typography>
          <Typography>
            If you choose to contact us, we may need additional information to
            fulfill your request or respond to your inquiry. We may provide
            additional privacy disclosures where the scope of the
            inquiry/request and/or personal information we require fall outside
            the scope of this Policy. In that case, the additional privacy
            disclosures will govern how we may process the information you
            provide at that time.
          </Typography>
          <Typography last>
            We may also use personal information for any other purposes
            disclosed to you at the time your information is collected or
            pursuant to your consent.
          </Typography>

          <ListWrapper
            title="Our Disclosure of Personal Information"
            text="We share your personal information with the following types of
            recipients and in the following ways:"
          >
            <List>
              <ListItem>
                service providers. We share your personal information with
                service providers that we have engaged to perform services on
                our behalf, including in connection with the Services we provide
                to you. This includes service providers that: conduct research
                and analytics; create content; provide customer, technical or
                operational support; conduct or support marketing (such as email
                or advertising platforms); host the Services; maintain
                databases; send or support online or mobile advertising; allow
                you to conduct transactions in connection with the Services; and
                otherwise support or help us provide the Services. Service
                providers also include payment processors, payment networks,
                external bank account information providers, credit reporting
                and scoring agencies, providers of identity services, risk
                investigation and mitigation service providers, hosting,
                technology and communication providers, security and fraud
                prevention consultants, risk investigation and mitigation
                service providers, support and customer service vendors and
                product fulfillment and delivery providers. You acknowledge that
                such third parties will have access to your personal information
                on a need basis, in connection with the Services rendered by us;
              </ListItem>
              <ListItem>
                advertising and marketing partners. We share your personal
                information with other third parties, including
                advertising/analytics partners who enable the delivery of
                interest-based advertising to users, as well as vendors that
                provide specific services we do not;
              </ListItem>
              <ListItem>
                business transaction or reorganization. We may take part in or
                be involved with a corporate business transaction, such as a
                merger, acquisition, joint venture, or financing or sale of
                company assets. We may disclose personal information to a third
                party during negotiation of, in connection with or as an asset
                in such a corporate business transaction. Personal information
                may also be disclosed in the event of insolvency, bankruptcy, or
                receivership;
              </ListItem>
              <ListItem>
                affiliates, investors and business partners. We share your
                personal information with our affiliates to support the Services
                and investors and business partners, subject to the terms of
                this Policy or otherwise as we disclose to you at the time the
                data is collected;
              </ListItem>
              <ListItem>
                credit bureaus and collection agencies. Depending on
                circumstances, we may share your personal information with
                credit bureaus and collection agencies, as permitted by law;
              </ListItem>
              <ListItem>
                legal obligations and rights. We may disclose personal
                information to third parties, such as legal advisors and law
                enforcement:
                <ul className="list-disc pl-12">
                  <li className="mt-9 mb-4 pl-2">
                    in connection with the establishment, exercise, or defense
                    of legal claims;
                  </li>
                  <li className="mb-4 pl-2">
                    to comply with laws or to respond to lawful requests and
                    legal process;
                  </li>
                  <li className="mb-4 pl-2">
                    to protect the rights and property of us, our agents,
                    customers, and others, including to enforce our agreements,
                    policies, terms of service, and terms and conditions.
                  </li>
                  <li className="mb-4 pl-2">
                    to detect, suppress, or prevent fraud;
                  </li>
                  <li className="mb-4 pl-2">
                    to reduce credit risk and collect debts owed to us;
                  </li>
                  <li className="mb-4 pl-2">
                    to protect the health and safety of us, our customers, or
                    any person; or
                  </li>
                  <li className="pl-2">
                    as otherwise required by applicable law; and
                  </li>
                </ul>
              </ListItem>
              <ListItem>
                consent. We may disclose personal information about you to
                certain other third parties with your consent.
              </ListItem>
            </List>
          </ListWrapper>

          <Title>Cookie Notice</Title>
          <SubTitle>Scope of Cookie Notice</SubTitle>
          <Typography>
            This provision explains how we and our business partners and
            services providers use cookies and related technologies in the
            course of managing and providing our online services and our
            communications to you. It explains what these technologies are and
            why we use them, as well as your rights to control our use of them.
            In some cases, we may use cookies and related technologies described
            in this provision to collect personal information, or to collect
            information that becomes personal information if we combine it with
            other information.
          </Typography>
          <SubTitle>What We Collect When Using Cookies</SubTitle>
          <Typography>
            We and our third-party partners and providers may use cookies to
            automatically collect certain types of usage information when you
            visit or interact with our email communications, websites and other
            online services. For example, we may collect log data about your
            device and its software, such as your IP address, operating system,
            browser type, date/time of your visit, and other similar
            information. We may collect analytics data or use third-party
            analytics tools, such as Google Analytics, to help us measure usage
            and activity trends for our online services and better understand
            our customer base.
          </Typography>
          <Typography>
            Third-party partners and providers may also collect personal
            information about your online activities over time and across
            different websites when you use our websites and online services.
          </Typography>
          <SubTitle>How We Use That Information</SubTitle>
          <Typography>
            We use or may use the data collected through cookies to: (a)
            remember information so that you will not have to re-enter it during
            your visit or the next time you visit our websites and Copyright
            2022 Transcard Payments. All Rights Reserved V1.0 online services;
            (b) provide and monitor the effectiveness of our websites and online
            services; (c) monitor online usage and activities of our websites
            and online services; (d) diagnose errors and problems with our
            websites and online services; (e) otherwise plan for and enhance our
            online services; and (f) facilitate the purposes identified in the{' '}
            <span className="font-semibold">
              Our Use of Personal Information
            </span>{' '}
            section of this <span className="font-semibold">Policy</span>. We
            and our advertising partners also use the information we collect
            through cookies to understand your browsing activities, including
            across unaffiliated thirdparty sites, so that we can deliver ads and
            information about products and services that may be of interest to
            you.
          </Typography>
          <Typography>
            Please note that we link some of the personal information we collect
            through cookies with the other personal information that we collect
            about you and for the purposes described in this{' '}
            <span className="font-semibold">Policy</span>.
          </Typography>

          <Title>Your Choices About Cookies</Title>
          <Typography>
            If you would prefer not to accept cookies, most browsers will allow
            you to: (i) change your browser settings to notify you when you
            receive a cookie, which lets you choose whether or not to accept it;
            (ii) disable existing cookies; or (iii) set your browser to
            automatically reject cookies. Please note that doing so may
            negatively impact your experience using our online services, as some
            features and services on our online services may not work properly.
            Depending on your device and operating system, you may not be able
            to delete or block all cookies. In addition, if you want to reject
            cookies across all your browsers and devices, you will need to do so
            on each browser on each device you actively use. You may also set
            your email options to prevent the automatic downloading of images
            that may contain technologies that would allow us to know whether
            you have accessed our email and performed certain functions with it.
          </Typography>
          <Typography>
            You can learn more about Google’s practices with Google Analytics by
            visiting Google’s privacy policy{' '}
            <Link
              href="https://www.google.com/policies/privacy/partners"
              target="_blank"
            >
              here
            </Link>
            . You can also view Google’s currently available opt-out options{' '}
            <Link
              href="https://tools.google.com/dlpage/gaoptout"
              target="_blank"
            >
              here
            </Link>
            .
          </Typography>

          <Title>Your Choices About Online Ads</Title>
          <Typography>
            We support the self-regulatory principles for online behavioral
            advertising (Principles) published by the Digital Advertising
            Alliance (DAA). This means that we allow you to exercise choice
            regarding the collection of information about your online activities
            over time and across third-party websites for online interest-based
            advertising purposes. More information about these Principles can be
            found at{' '}
            <Link href="https://www.aboutads.info" target="_blank">
              www.aboutads.info
            </Link>
            . If you want to opt out of receiving online interest-based
            advertisements on your internet browser from advertisers and third
            parties that participate in the DAA program and perform
            advertising-related services for us and our partners, please follow
            the instructions at{' '}
            <Link href="https://www.aboutads.info/choices" target="_blank">
              www.aboutads.info/choices
            </Link>
            , or{' '}
            <Link
              href="https://www.networkadvertising.org/choices/"
              target="_blank"
            >
              https://www.networkadvertising.org/choices/
            </Link>{' '}
            to place an opt-out cookie on your device indicating that you do not
            want to receive interest-based advertisements. Opt-out cookies only
            work on the internet browser and device they are downloaded onto. If
            you want to opt out of interest-based advertisements across all your
            browsers and devices, you will need to opt out on each browser on
            each device you actively use. If you delete cookies on your device
            generally, you will need to opt out again.
          </Typography>
          <Typography>
            If you want to opt out of receiving online interest-based
            advertisements on mobile apps, please follow the instructions at{' '}
            <Link href="https://www.aboutads.info/appchoices" target="_blank">
              http://www.aboutads.info/appchoices
            </Link>
            .
          </Typography>
          <Typography>
            Please note that when you opt out of receiving interest-based
            advertisements, this does not mean you will no longer see
            advertisements from us or on our online services. It means that the
            online ads that you do see from DAA program participants should not
            be based on your interests. We are not responsible for the
            effectiveness of, or compliance with, any thirdparties’ opt-out
            options or programs or the accuracy of their statements regarding
            their programs. In addition, third parties may still use cookies to
            collect information about your use of our online services, including
            for analytics and fraud prevention as well as any other purpose
            permitted under the DAA’s Principles.
          </Typography>

          <Title>Children’s Personal Information</Title>
          <Typography>
            Our websites and online services are not directed to children under
            the age of 13, and we do not either intend to or knowingly collect
            or solicit personal information from children under the age of 13.
            If you are under the age of 13, please do not use our Services or
            otherwise provide us with any personal information either directly
            or by other means. If a child under the age of 13 has provided
            personal information to us, we encourage the child’s parent or
            guardian to contact us to request that we remove the personal
            information from our systems. If we learn that any personal
            information we collect has been provided by a child under the age of
            13, we will promptly delete that personal information.
          </Typography>

          <Title>Jurisdiction-Specific Disclosures</Title>
          <SubTitle>USA</SubTitle>
          <Typography>
            California’s &#34;Shine the Light&#34; law (Civil Code Section
            §1798.83) permits California residents that have an established
            business relationship with us to request certain information
            regarding our disclosure of certain types of personal information to
            third parties for their direct marketing purposes during the
            immediately preceding calendar year. To make such a request, please
            send an email to legal@Transcard.com.
          </Typography>
          <Typography>
            Chapter 603A of the Nevada Revised Statutes permits a Nevada
            resident to opt out of future sales of certain covered information
            that a website operator has collected or will collect about the
            resident. To submit such a request, please contact us at
            legal@Transcard.com.
          </Typography>

          <Title>Third-Party Websites</Title>
          <Typography>
            Our websites and online services may include links to third-party
            websites, plug-ins and applications. Except where we post, link to
            or expressly adopt or refer to this Policy, this Policy does not
            apply to, and we are not responsible for, any personal information
            practices of thirdparty websites and online services or the
            practices of other third parties. To learn about the personal
            information practices of third parties, please refer to their
            respective privacy policies.
          </Typography>

          <Title>Protecting Your Information</Title>
          <Typography>
            While we take reasonable steps to protect your personal information,
            nothing on the internet is 100% secure. For these reasons, we cannot
            guarantee that all of your personal information will never be
            disclosed in ways you do not expect, or which are not described in
            this Policy. While we protect our systems and services, we cannot
            guarantee the security of your information during transmission and
            any transmission is at your own risk. Also, you are responsible for
            keeping your password(s) and account information safe and private.
            You are also responsible for making sure your personal information
            is accurate and up-to-date.
          </Typography>

          <Title>Updates to This Policy</Title>
          <Typography>
            We will update this Policy from time to time. When we make changes
            to this Policy, we will change the &#34;Last Updated&#34; date at
            the beginning of this Policy. If we make material changes to this
            Policy, we will notify you by email to your registered email
            address, by prominent posting on this website or our online
            services, or through other appropriate communication channels, as
            determined by us in our sole discretion. All changes shall be
            effective from the date of publication unless otherwise provided.
          </Typography>

          <Title>Storage and Processing</Title>
          <Typography>
            Regardless of where you live, you acknowledge that any personal
            information you make available to us may be processed and stored in
            the Territory. You further acknowledge that such personal
            information may be processed and stored in other jurisdictions from
            time to time by our service providers and other third parties we
            work with.
          </Typography>
          <Typography>
            If you have any questions or requests in connection with this Policy
            or other privacy-related matters, please send an email to
            support@smart-hub.com.
          </Typography>
        </div>
        <PoweredBy />
      </div>
    </LayoutSignUp>
  );
};

export default PrivacyPolicy;
