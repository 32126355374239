import api from 'api';

export interface BusinessPersonXrefSchema {
  businessesAdmin: boolean;
  businessesPersonsXREFId: number;
  createdOn: string;
  createdBy: string;
  hubCreateUser: boolean;
  modifiedOn: string | null;
  modifiedBy: string | null;
  isActive: boolean;
  businessesId: number;
  personsId: number;
  businessesPersonsRole: string;
  tokenOid: string;
}

export interface BusinessBPSTrackSchema {
  businessesBpstracksId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string | null;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  isActive: boolean;
  businessesId: number;
  buyerId: string | null;
  buyerProfileId: string | null;
  supplierId: string | null;
  supplierProfileId: string | null;
  buyerEnabled: boolean;
  supplierEnabled: boolean;
  supplierMaxTenor: number | null;
  supplierMinTenor: number | null;
  supplierRate: number | null;
  bankAccountId: number;
  erpBusinessMctrackId: number | null;
  isLogging: boolean | null;
}

export interface BusinessBPSTrackPaymentSchema {
  businessesBpsTracksPaymentControllerIdsId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string | null;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  isActive: boolean;
  paymentControllerId: string;
  paymentMethod: string;
  businessesBPSTracksId: number;
  minInvoiceAmount: number;
  maxInvoiceAmount: number;
  isLogging: boolean;
}

export interface BusinessBPSTrackPaymentXrefSchema {
  businessesBpstracksBusinessesBpstracksPaymentControllerIdsXrefId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string | null;
  modifiedBy: string | null;
  modifiedBySp: string | null;
  isActive: boolean;
  searchedBy: string;
  searchedOn: string;
  businessesBpstracksId: number;
  businessesBpstracksPaymentControllerIdsId: number;
  isLogging: boolean;
}

export interface BusinessRequestSchema {
  businessesRequestsId: number;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
  isActive: boolean;
  businessesId: number;
  isHidden: boolean;
  isReplyAllowed: boolean;
  isEmail: boolean;
  subject: string;
  messageText: string;
  followUpOn: string;
  replyDueOn: string;
  threadMessageId: string;
  replyMessageId: string;
  priority: number;
  tokenOid: string;
  isLogging: number;
}

export interface BusinessDetailsSchema {
  businessesId: number;
  businessCreatedOn: string;
  businessCreatedBy: string;
  businessModifiedOn: string;
  businessModifiedBy: string;
  businessIsActive: boolean;
  masterBusinessesId: number | null;
  tenantId: number;
  tenantName: string;
  businessLegalName: string;
  doingBusinessAs: string;
  dunsNumber: string;
  noDUNSListed: boolean;
  businessTypesId: number | null;
  productsSold: number | null;
  referenceNumber: number | null;
  businessStatusesId: number;
  businessStatus: string;
  businessChannelTypesid: number | null;
  businessChannelType: string | null;
  businessesLegalEntityFormTypesId: number | null;
  businessesLegalEntityFormType: string | null;
  completionPercentage: number;
  accountholder: number | null;
  participantID: number | null;
  erpClientID: number | null;
  emailAddress: string | null;
  primaryURL: string | null;
  applicationStatusID: number;
  applicationStatusTypeCode: string;
  applicationStatusDescription: string;
  acceptedTandC: string | null;
  acceptedTandCDate: string | null;
  complianceStatusID: number | null;
  complianceUpdatedBy: string | null;
  complianceUpdateDate: string | null;
  complianceReference: string | null;
  signatoryAttestationPersonsID: number | null;
  singleOwner25Percent: boolean;
  businessInformation: string | null;
  ownership: string | null;
  financial: string | null;
  businessPlan: string | null;
  hubSpotCompanyId: number | null;
  hubSpotDealId: number | null;
  hubSpotDealStageId: number | null;
  hubSpotDealStageDescription: string | null;
  hubSpotCreatedDateTime: string | null;
  logoFileShareURI: string | null;
  faviconFileShareURI: string | null;
  smartDisburseProductsId: number | null;
  productIdSentToErp: number | null;
  productValidatorPersonId: number | null;
  hubSpotTicketId: number | null;
  businessesAddressesXrefId: number;
  businessesAddressesXrefIsActive: boolean;
  businessAddressesId: number;
  businessAddressIsActive: boolean;
  businessAddressLine1: string;
  businessAddressLine2: string | null;
  businessAddressLine3: string | null;
  businessCity: string;
  businessStateOrProvince: string;
  businessCountryISOCode: number;
  businessCountry: string;
  businessAlpha2Code: string;
  businessPostalCode: string;
  businessesTelephoneNumbersXrefId: number | null;
  businessesTelephoneNumbersXrefIsActive: boolean | null;
  businessTelephoneNumberId: number | null;
  businessCountryCallingCode: string | null;
  businessTelephoneNumber: string | null;
  businessTelephoneType: string | null;
  erpBusinessDataId: number | null;
  businesses__Persons_XREF: string | null;
  bankAccounts: string | null;
  erpAccounts: string | null;
  products: string | null;
  businessesOwners_Persons: string | null;
  businessesOwners_Businesses: string | null;
  businessesFinancials: string | null;
  businessesFinancialsBlobReferences: string | null;
  erpPreferencesData: string | null;
}

export const getBusinesses = (id: number) => {
  return api.get(`Businesses/GetBusinesses/${id}`);
};

export const getAllDataBusinesses = (id: number) => {
  return api.get(`Businesses/GetAllDataBusinesses/${id}`);
};

export const getBusinessDetailsById = async (id: number) => {
  const result: BusinessDetailsSchema[] = await api.get(
    `Businesses/GetAllDataBusinesses/${id}`,
  );
  return Array.isArray(result) && result.length ? result[0] : null;
};

export const addBusinesses = (data: any) => {
  return api.post('Businesses/AddBusinesses', data);
};

export const updateBusinesses = (data: any) => {
  return api.put('Businesses/UpdateBusinesses', data);
};

export const addBusinessesPersonsXrefs = (
  data: Partial<BusinessPersonXrefSchema>,
) => {
  return api.post('BusinessesPersonsXrefs/AddBusinessesPersonsXrefs', data);
};

export const updateBusinessesPersonsXrefs = (
  data: Partial<BusinessPersonXrefSchema>,
) => {
  return api.put('BusinessesPersonsXrefs/UpdateBusinessesPersonsXrefs', data);
};

export const searchBusinessesPersonsXrefs = (
  data: Partial<BusinessPersonXrefSchema>,
) => {
  return api.post('BusinessesPersonsXrefs/SearchBusinessesPersonsXrefs', data);
};

export const addBusinessesBPSTracks = (
  data: Partial<BusinessBPSTrackSchema>,
) => {
  return api.post('BusinessesBpsTracks/AddBusinessesBpsTracks', data);
};

export const updateBusinessesBPSTracks = (
  data: Partial<BusinessBPSTrackSchema>,
) => {
  return api.put('BusinessesBpsTracks/UpdateBusinessesBpsTracks', data);
};

export const searchBusinessesBPSTracks = (
  data: Partial<BusinessBPSTrackSchema>,
) => {
  return api.post('BusinessesBpsTracks/SearchBusinessesBpsTracks', data);
};

export const getAllBusinessesBpsTrackDetails = (businessId: number) => {
  return api.get(
    `BusinessesBpsTracks/GetAllBusinessesBpsTrackDetails/${businessId}`,
  );
};

export const addBusinessesBPSTracksPayments = (
  data: Partial<BusinessBPSTrackPaymentSchema>,
) => {
  return api.post(
    'BusinessesBpsTracksPaymentControllerIds/AddBusinessesBpsTracksPaymentControllerIds',
    data,
  );
};

export const updateBusinessesBPSTracksPayments = (
  data: Partial<BusinessBPSTrackPaymentSchema>,
) => {
  return api.put(
    'BusinessesBpsTracksPaymentControllerIds/UpdateBusinessesBpsTracksPaymentControllerIds',
    data,
  );
};

export const searchBusinessesBPSTracksPayments = (
  data: Partial<BusinessBPSTrackPaymentSchema>,
) => {
  return api.post(
    'BusinessesBpsTracksPaymentControllerIds/SearchBusinessesBpsTracksPaymentControllerIds',
    data,
  );
};

export const addBusinessesBPSTracksPaymentXrefs = (
  data: Partial<BusinessBPSTrackPaymentXrefSchema>,
) => {
  return api.post(
    'BusinessesBpsTracksPaymentControllerIdsXrefs/AddBusinessesBpsTracksPaymentControllerIdsXrefs',
    data,
  );
};

export const searchBusinessesBPSTracksPaymentXrefs = (
  data: Partial<BusinessBPSTrackPaymentXrefSchema>,
) => {
  return api.post(
    'BusinessesBpsTracksPaymentControllerIdsXrefs/SearchBusinessesBpsTracksPaymentControllerIdsXrefs',
    data,
  );
};

export const getBusinessesRequests = (id: number) => {
  return api.get(`BusinessesRequests/GetBusinessesRequests/${id}`);
};

export const addBusinessesRequests = (data: Partial<BusinessRequestSchema>) => {
  return api.post('BusinessesRequests/AddBusinessesRequests', data);
};

export const searchBusinessesRequests = (
  data: Partial<BusinessRequestSchema>,
) => {
  return api.post('BusinessesRequests/SearchBusinessesRequests', data);
};

export const getBankAccountBlobFile = (
  businessid: number,
  accountId: number,
  blobId: string,
) => {
  return api.get(
    `Businesses/${businessid}/Accounts/${accountId}/Blobs/${blobId}`,
  );
};

export const getBusinessesPersons = (busienssId: number) => {
  return api.get(`Businesses/${busienssId}/Persons`);
};

export interface BusinessMerchantSchema {
  businessesId: number;
  createdBy: string;
  createdOn: string;
  isActive: boolean;
  merchantsId: number;
  merchantsProductsId: number;
  modifiedBy: string;
  modifiedOn: string;
  name: string;
  processingInfo: {
    json?: string;
  };
  processor_Name: string;
  processor_MerchantsId: string;
  settlementBankAccountsId: number | null;
  captureMoreThanPreAuth: boolean;
  contact: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
}

export const getBusinessesMerchants = (busienssId: number) => {
  return api.get(`Businesses/${busienssId}/Merchants`);
};

export const addBusinessesMerchants = (
  busienssId: number,
  data: Partial<BusinessMerchantSchema>,
) => {
  return api.post(`Businesses/${busienssId}/Merchants`, data);
};

export const updateBusinessesMerchants = (
  busienssId: number,
  merchantId: number,
  data: Partial<BusinessMerchantSchema>,
) => {
  return api.put(`Businesses/${busienssId}/Merchants/${merchantId}`, data);
};

export const deleteBusinessesMerchants = (
  busienssId: number,
  merchantId: number,
  data: Partial<BusinessMerchantSchema>,
) => {
  return api.delete(`Businesses/${busienssId}/Merchants/${merchantId}`, {
    data,
  });
};

export const getBusinessSourceTypes = async (businessId: number) => {
  const results = await api.get(
    `Businesses/${businessId}/SystemsOfRecord/SourceTypes`,
  );
  return Array.isArray(results) ? results : [];
};

export interface UpdateSourceTypesPayload {
  sourceTypes: number[];
  createdBy: string;
}

export const updateBusinessSourceTypes = async (
  businessId: number,
  payload: UpdateSourceTypesPayload,
) => {
  const results = await api.post(
    `Businesses/${businessId}/SystemsOfRecord/SourceTypes`,
    payload,
  );
  return Array.isArray(results) ? results : [];
};

export interface CrossBorderSchema {
  id: number;
  createdBy: string;
  createdOn: string;
  modifiedBy: string | null;
  modifiedOn: string | null;
  businessesId: number;
  isActive: boolean;
  transactionEstimate12Month: number | null;
  transactionAverage: number | null;
  transactionHigh: number | null;
  transactionPurpose: string | null;
  regulatedActivities: boolean | null;
}

export interface CrossBorderProviderXrefSchema {
  id: number;
  createdBy: string;
  createdOn: string;
  modifiedBy: string | null;
  modifiedOn: string | null;
  businessesId: number;
  isActive: boolean;
  crossBorder_ProvidersId: number;
}

export const addCrossBorder = async (
  businessId: number,
  payload: Partial<CrossBorderSchema>,
) => {
  const result: any = await api.post(
    `Businesses/${businessId}/Transactions/CrossBorder`,
    payload,
  );
  return result.id;
};

export const updateCrossBorder = async (
  businessId: number,
  id: number,
  payload: Partial<CrossBorderSchema>,
) => {
  const result: any = await api.put(
    `Businesses/${businessId}/Transactions/CrossBorder/${id}`,
    payload,
  );
  return result.id;
};

export const searchCrossBorder = async (
  businessId: number,
  payload: Partial<CrossBorderSchema>,
) => {
  const results: CrossBorderSchema[] | null = await api.post(
    `Businesses/${businessId}/Transactions/CrossBorder/Searches`,
    payload,
  );

  return results || [];
};

export const addBusinessCrossBorderProvider = async (
  businessId: number,
  payload: Partial<CrossBorderProviderXrefSchema>,
) => {
  const result: any = await api.post(
    `Businesses/${businessId}/Transactions/CrossBorder/Providers`,
    payload,
  );

  return result.id;
};

export const deleteBusinessCrossBorderProvider = async (
  businessId: number,
  id: number,
) => {
  const result: any = await api.delete(
    `Businesses/${businessId}/Transactions/CrossBorder/Providers/${id}`,
  );

  return result.id;
};

export const searchBusinessCrossBorderProviders = async (
  businessId: number,
  payload: Partial<CrossBorderProviderXrefSchema>,
) => {
  const results: CrossBorderProviderXrefSchema[] | null = await api.post(
    `Businesses/${businessId}/Transactions/CrossBorder/Providers/Searches`,
    payload,
  );

  return results || [];
};

export default {
  addCrossBorder,
  updateCrossBorder,
  searchCrossBorder,
  getBusinesses,
  getAllDataBusinesses,
  getBusinessDetailsById,
  addBusinesses,
  updateBusinesses,
  searchBusinessesBPSTracks,
  addBusinessesPersonsXrefs,
  updateBusinessesPersonsXrefs,
  searchBusinessesPersonsXrefs,
  getBusinessesRequests,
  addBusinessesRequests,
  searchBusinessesRequests,
  addBusinessesBPSTracksPayments,
  updateBusinessesBPSTracksPayments,
  searchBusinessesBPSTracksPayments,
  getBankAccountBlobFile,
  getBusinessesPersons,
  getBusinessesMerchants,
  addBusinessesMerchants,
  updateBusinessesMerchants,
  deleteBusinessesMerchants,
  getBusinessSourceTypes,
  addBusinessCrossBorderProvider,
  deleteBusinessCrossBorderProvider,
  searchBusinessCrossBorderProviders,
};
