import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';

import { useAppDispatch, useAppSelector } from 'hooks';
import { Checkbox } from 'components/Inputs';
import path from 'common/path';

import { getBusinessSignatory } from 'pages/Profile/selectors';
import { setIsAgreed } from '../submitDashboardSlice';
import { getIsAgreed } from '../selectors';

const TermsAgreement = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useAppDispatch();
  const signatory = useAppSelector(getBusinessSignatory);
  const isAgreed = useAppSelector(getIsAgreed);

  const handleChange = () => {
    dispatch(setIsAgreed(!isAgreed));
  };

  return (
    <div
      className={classNames('mt-9 bg-gray-50 p-6 rounded-md', {
        'opacity-50 pointer-events-none': !signatory,
      })}
    >
      <p className="text-gray-900 text-sm mb-5">
        {t('dashboard:submitModal.termsTitle')}
      </p>
      <div className="flex justify-start">
        <label htmlFor="terms-checkbox" className="flex items-center">
          <Checkbox
            id="terms-checkbox"
            onChange={handleChange}
            checked={isAgreed}
          />
          <span className="ml-4 text-sm text-gray-900">
            <Trans ns="dashboard" i18nKey="submitModal.termsLabel">
              Agree to
              <a
                href={path.terms}
                target="_blank"
                className="font-medium text-blue-600 hover:text-blue-500"
                rel="noreferrer"
              >
                Terms and Conditions
              </a>{' '}
              and{' '}
              <a
                href={path.privacy}
                target="_blank"
                className="font-medium text-blue-600 hover:text-blue-500"
                rel="noreferrer"
              >
                Privacy Policy
              </a>
              for SMART Suite
            </Trans>
          </span>
        </label>
      </div>
    </div>
  );
};

export default TermsAgreement;
