import { useTranslation, Trans } from 'react-i18next';

import PermissionSection from 'components/permission/PermissionSection';
import { ConnectorTypes, Permissions } from 'entities/dashboard';
import { hasPermission } from 'pages/dashboard/selectors';
import BatchIcon from 'assets/icons/connectors/Batch.svg';
import LinkIcon from 'assets/icons/connectors/Link.svg';
import { DataSourceWidgets } from 'entities/accounting';
import { useAppDispatch, useAppSelector } from 'hooks';
import ERPIcon from 'assets/icons/connectors/ERP.svg';
import HideComponent from 'components/HideComponent';
import { isUATDomain } from 'utils/domainService';
import { UserAddIcon } from 'components/icons';
import Button from 'components/Button';
import Link from 'components/Link';
import {
  setInvitedAndWasInviteState,
  setInviteIsOpen,
} from 'components/InvitePersons/invitePersonsSlice';

import ERPSoftwareList from '../AccountingSoftware/ERPSoftwareList';
import DataSourceWidget, { WidgetType } from './DataSourceWidget';
import ConnectorWrapper from '../Connectors/ConnectorWrapper';
import BatchWidgetModal from './BatchWidgetModal';
import APIWidgetModal from './APIWidgetModal';
import ERPWidgetModal from './ERPWidgetModal';
import {
  getErpAccountsLength,
  getShowWidgets,
} from '../AccountingSoftware/selectors';

const widgets: WidgetType[] = [
  {
    id: DataSourceWidgets.erp,
    title: 'integration:dataSource.erpTitle',
    description: 'integration:dataSource.erpDescription',
    icon: ERPIcon,
    modal: <ERPWidgetModal />,
    active: true,
  },
  {
    id: DataSourceWidgets.batch,
    title: 'integration:dataSource.batchTitle',
    description: 'integration:dataSource.batchDescription',
    icon: BatchIcon,
    modal: <BatchWidgetModal />,
    active: true,
  },
  {
    id: DataSourceWidgets.api,
    title: 'integration:dataSource.apiTitle',
    description: (
      <Trans i18nKey="integration:dataSource.apiDescription">
        text
        <Link
          href={
            isUATDomain()
              ? 'https://developer-sandbox.transcard.com/'
              : 'https://www.transcard.com/smart-suite-api'
          }
          target="_blank"
          rel="noreferrer"
        >
          link
        </Link>
        .
      </Trans>
    ),
    icon: LinkIcon,
    modal: <APIWidgetModal />,
    active: true,
  },
];

const DataSource = () => {
  const { t } = useTranslation('integration');
  const dispatch = useAppDispatch();

  const erpAccounts: number = useAppSelector(getErpAccountsLength);
  const showWidgets: boolean = useAppSelector(getShowWidgets);
  const sourcePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.erpIntegration),
  );
  const invitePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.invite),
  );

  const handleInvite = () => {
    dispatch(setInvitedAndWasInviteState());
    dispatch(setInviteIsOpen(true));
  };

  return showWidgets || !sourcePermissions.view ? (
    <div className="w-full mb-6 bg-white rounded-10 p-6">
      <div className="flex items-center justify-between mb-4">
        <p className="heading-2xl font-semibold">{t('dataSource.title')}</p>
        <HideComponent show={sourcePermissions.view && invitePermissions.view}>
          <PermissionSection permission={Permissions.invite} showPopover edit>
            <Button
              variant="link"
              size="medium"
              paddingClass="px-3"
              onClick={handleInvite}
              disabled={!invitePermissions.edit}
            >
              {t('integration:inviteHelp')}
              <UserAddIcon className="ml-2" />
            </Button>
          </PermissionSection>
        </HideComponent>
      </div>
      <PermissionSection permission={Permissions.erpIntegration} showMessage>
        <>
          <p className="text-secondary mt-4 mb-6">
            {t('dataSource.description')}
          </p>
          <div className="grid grid-cols-3 gap-6">
            {widgets.map((widget: WidgetType) => (
              <DataSourceWidget data={widget} key={widget.id} />
            ))}
          </div>
        </>
      </PermissionSection>
    </div>
  ) : (
    <ConnectorWrapper type={ConnectorTypes.erp} isEmpty={!erpAccounts}>
      <ERPSoftwareList />
    </ConnectorWrapper>
  );
};

export default DataSource;
