import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

import { FilesGray } from 'components/icons';
import Modal from 'components/Modal';
import { useAppDispatch, useAppSelector } from 'hooks';
import Loading from 'components/Loading';

import {
  getStep,
  getStepsLength,
  getPlanAttachedState,
  getIsBusinessPlanLoading,
  getIsDownalod,
  getIsDocumentLoading,
} from '../selectors';
import { setStep, setAttachedPlanState } from '../businessPlanSlice';
import StepsContainer from './StepsContainer';
import AboutYourBusiness from './AboutYourBusiness';
import GetStarted from './GetStarted';
import AboutRevenueAndExpenses from './AboutRevenueAndExpenses';
import AboutCustomers from './AboutCustomers';
import AboutCompetitors from './AboutCompetitors';
import AboutMarketing from './AboutMarketing';
import SuccessCreatedBusinessPlan from './SuccessCreatedBusinessPlan';
import Buttons from './Buttons';
import {
  getBusinessPlanDocumentById,
  createBusinessPlan,
  getBusinessPlanById,
} from '../thunks';

const GetStartedModal = ({ isOpen, closeModal }) => {
  const step = useAppSelector(getStep);
  const isPlanAttachedState = useAppSelector(getPlanAttachedState);
  const stepsLength = useAppSelector(getStepsLength);
  const isLoading = useAppSelector(getIsBusinessPlanLoading);
  const isDownload = useAppSelector(getIsDownalod);
  const isDocumentLoading = useAppSelector(getIsDocumentLoading);

  const dispatch = useAppDispatch();
  const { t } = useTranslation('dashboard');
  const content = {
    maxWidth: 'calc(100vw - 120px)',
    maxHeight: 'calc(100vh - 120px)',
    width: '100%',
    height: '100%',
  };

  useEffect(() => {
    if (isOpen) {
      dispatch(getBusinessPlanById());
    }
  }, [isOpen, dispatch]);

  const handleToggle = () => {
    dispatch(setAttachedPlanState());
  };

  const handleNextStep = () => {
    const nextStep = step + 1;

    if (nextStep > 6) {
      closeModal();
      return;
    }
    if (step === 5) {
      dispatch(createBusinessPlan());
      return;
    }
    dispatch(setStep(nextStep));
  };

  const getBusinessPlan = () => {
    dispatch(getBusinessPlanDocumentById());
  };

  const handlBackStep = () => {
    const backStep = step - 1;
    dispatch(setStep(backStep));
  };

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      content={content}
      title={t('dashboard:businessPlan.modal.title')}
      icon={<FilesGray />}
    >
      <div className="flex flex-1 h-full flex-col ">
        <div className="relative flex  flex-1 h-full overflow-auto max-h-[700px]">
          {isDocumentLoading && <Loading />}
          <GetStarted step={step} getStarted={handleNextStep} />
          <StepsContainer step={step} stepsLength={stepsLength}>
            <AboutYourBusiness step={step} />
            <AboutRevenueAndExpenses step={step} />
            <AboutCustomers step={step} />
            <AboutCompetitors step={step} />
            <AboutMarketing step={step} />
          </StepsContainer>
          <SuccessCreatedBusinessPlan
            step={step}
            isOn={isPlanAttachedState}
            handleToggle={handleToggle}
            onDownload={getBusinessPlan}
            isDownload={isDownload}
          />
        </div>
        <div>
          <Buttons
            loading={isLoading}
            step={step}
            handlBackStep={handlBackStep}
            handleNextStep={handleNextStep}
          />
        </div>
      </div>
    </Modal>
  );
};

export default GetStartedModal;
