import { useTranslation } from 'react-i18next';

import BankAccountModal from 'pages/dashboard/components/BankAccounts/BankAccountModal';
import PermissionSection from 'components/permission/PermissionSection';
import { Permissions } from 'entities/dashboard';

import EarlyPaymentPreferences from './Configuration/EarlyPaymentPreferences';
import SmartExchange from './Configuration/SmartExchange';
import DashboardTabHeader from './DashboardTabHeader';

const ConfigurationTab = () => {
  const { t } = useTranslation('configuration');

  return (
    <div>
      <DashboardTabHeader
        title={t('configuration:title')}
        description={t('configuration:description')}
      />
      <SmartExchange />
      <EarlyPaymentPreferences />
      <PermissionSection permission={Permissions.configuration}>
        <BankAccountModal />
      </PermissionSection>
    </div>
  );
};

export default ConfigurationTab;
