import { useTranslation } from 'react-i18next';
import { useState } from 'react';

import { notify } from 'components/notification/notificationSlice';
import { useAppSelector, useAppDispatch } from 'hooks';
import { ClipboardCopyIcon } from 'components/icons';
import { BankAccount } from 'entities/bankAccounts';

import { getBankAccount } from '../selectors';

const BankAccountReference = () => {
  const { t } = useTranslation('bank');
  const dispatch = useAppDispatch();

  const account: BankAccount = useAppSelector(getBankAccount);

  const [isGreen, setIsGreen] = useState<boolean>(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(account.tgpaccountReferenceId || '');
    setIsGreen(true);
    dispatch(
      notify({
        message: 'common:notifications.copiedToClipboard',
        duration: 3000,
      }),
    );
  };

  if (!account.tgpaccountReferenceId) {
    return null;
  }

  return (
    <div className="mt-6 pt-6 border-t border-gray-200">
      <p className="text-sm font-medium text-gray-500 mb-1">
        {t('fields.referenceId.label')}
      </p>
      <p className="text-sm flex items-center">
        <span>{account.tgpaccountReferenceId}</span>
        <button className="px-2" onClick={handleCopy} title={t('common:copy')}>
          <ClipboardCopyIcon
            className={`text-${isGreen ? 'green' : 'gray'}-500 w-4 h-4`}
          />
        </button>
      </p>
    </div>
  );
};

export default BankAccountReference;
