import { createAsyncThunk } from '@reduxjs/toolkit';
import fileDownload from 'js-file-download';

import { BlobFileTypes, DashboardState, Permissions } from 'entities/dashboard';
import { hasPermission } from 'pages/dashboard/selectors';
import { OwnershipState } from 'entities/ownership';
import { getBusinessesId } from 'utils/authService';
import { ProfileState } from 'entities/profile';
import { getUploadOptions } from 'utils/files';
import { buildFormData } from 'utils/helpers';
import { RootState } from 'state/store';

import { setLoadingProgress } from './ownershipSlice';
import {
  getBusinessesOwnersPersons,
  getBusinessesOwnersBusinesses,
  addBusinessesOwnersPersons,
  updateBusinessesOwnersPersons,
  searchBusinessesOwnersPersons,
  searchBusinessesOwnersBusinesses,
  addBusinessesOwnersBusinesses,
  updateIsActiveBusinessesOwnersPersons,
  updateIsActiveBusinessesOwnersBusinesses,
  updateBusinessesOwnersBusinesses,
  addBusinessesOwnersBlobReference,
  getBusinessesOwnersBlobReference,
  searchBusinessesOwnersBlobReferences,
  updateBusinessesOwnersPersonsBlobReference,
  updateIsActiveBusinessesOwnersBlobReference,
  getBusinessesOwnersBlobReferenceFile,
  getBusinessesOwnersPersonsFile,
} from './api';
import {
  getMappedCreateOwnerPersonFields,
  getMappedUpdateOwnerPersonFields,
  getMappedCreateOwnerBusinessFields,
  getMappedUpdateOwnerBusinessFields,
  getMappedOwners,
  getMappedOwner,
  getMappedCreateBlobReferenceFields,
  calcOwnershipPercentage,
} from './utils';

export const checkOwnershipProgress: any = createAsyncThunk(
  'financial/checkOwnershipProgress',
  async (data: any, thunk) => {
    try {
      const { getState } = thunk;
      const { profile } = getState() as { profile: ProfileState };
      const { singleOwner25Percent } = profile.business;

      return calcOwnershipPercentage(data, singleOwner25Percent);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const fetchOwners: any = createAsyncThunk(
  'ownership/fetchOwners',
  async (_: void, thunkAPI) => {
    try {
      const { view: viewOwnershipPermission } = hasPermission(
        thunkAPI.getState() as RootState,
        Permissions.ownership,
      );

      if (!viewOwnershipPermission) {
        return {
          owners: [],
          blobReferences: [],
        };
      }

      const { getState, dispatch } = thunkAPI;
      const { dashboard } = getState() as { dashboard: DashboardState };

      const businessesId = getBusinessesId();

      const ownersPersons: any = await searchBusinessesOwnersPersons({
        isActive: true,
        businessesId,
      });

      const ownersBusinesses: any = await searchBusinessesOwnersBusinesses({
        isActive: true,
        businessesId,
      });

      const blobReferences: any = await searchBusinessesOwnersBlobReferences({
        isActive: true,
        businessesId,
        blobReferencesTypesId:
          dashboard.dropdowns.mappedBlobReferencesTypes[
            BlobFileTypes.organization
          ],
      });

      const ownersData = getMappedOwners([
        ...ownersPersons,
        ...ownersBusinesses,
      ]);

      dispatch(
        checkOwnershipProgress({
          owners: ownersData,
          hasFile: blobReferences.length,
        }),
      );

      return {
        owners: ownersData,
        blobReferences: blobReferences,
      };
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createOwnerPerson: any = createAsyncThunk(
  'ownership/createOwnerPerson',
  async (data: any, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person, business } = profile;
      const owners = ownership.owners.slice();

      const fields = getMappedCreateOwnerPersonFields({
        data,
        person,
        business,
      });

      const formData = buildFormData(fields);

      const personId = await addBusinessesOwnersPersons(formData);

      if (personId) {
        const persons: any = await getBusinessesOwnersPersons(personId);
        const newOwner = getMappedOwner(persons[0]);

        owners.push(newOwner);

        dispatch(
          checkOwnershipProgress({
            owners: owners,
            hasFile: ownership.blobReferences.length,
          }),
        );

        return owners.filter((o) => o.isActive);
      }

      return owners;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createOwnerBusiness: any = createAsyncThunk(
  'ownership/createOwnerBusiness',
  async (data: any, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person, business } = profile;
      const owners = ownership.owners.slice();

      const fields = getMappedCreateOwnerBusinessFields({
        data,
        person,
        business,
      });

      const businessId = await addBusinessesOwnersBusinesses(fields);

      if (businessId) {
        const businesses: any = await getBusinessesOwnersBusinesses(businessId);
        const newOwner = getMappedOwner(businesses[0]);

        owners.push(newOwner);

        dispatch(
          checkOwnershipProgress({
            owners: owners,
            hasFile: ownership.blobReferences.length,
          }),
        );

        return owners.filter((o) => o.isActive);
      }

      return owners;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateOwnerPerson: any = createAsyncThunk(
  'ownership/updateOwnerPerson',
  async (data: any, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person, business } = profile;
      const owners = ownership.owners.slice();

      const fields = getMappedUpdateOwnerPersonFields({
        data,
        person,
        business,
      });

      if (fields.frontBlobFile === null && fields.idfrontGuid) {
        await updateBusinessesOwnersPersonsBlobReference({
          businessesOwnersPersonsId: fields.businessesOwnersPersonsId,
          modifiedBy: fields.modifiedBy,
          idFrontGuid: fields.idfrontGuid,
        });
        fields.idfrontGuid = null;
      }

      const formData = buildFormData(fields);

      const ownerId = await updateBusinessesOwnersPersons(formData);

      if (ownerId) {
        const persons: any = await getBusinessesOwnersPersons(ownerId);

        const index = owners.findIndex(
          (owner: any) =>
            owner.businessesOwnersPersonsId === data.businessesOwnersPersonsId,
        );

        owners[index] = getMappedOwner(persons[0]);

        dispatch(
          checkOwnershipProgress({
            owners: owners,
            hasFile: ownership.blobReferences.length,
          }),
        );
      }

      return owners;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const updateOwnerBusiness: any = createAsyncThunk(
  'ownership/updateOwnerBusiness',
  async (data: any, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person, business } = profile;
      const owners = ownership.owners.slice();

      const fields = getMappedUpdateOwnerBusinessFields({
        data,
        person,
        business,
      });

      const ownerId = await updateBusinessesOwnersBusinesses(fields);

      if (ownerId) {
        const businesses: any = await getBusinessesOwnersBusinesses(ownerId);

        const index = owners.findIndex(
          (owner: any) =>
            owner.businessesOwnersBusinessesId ===
            data.businessesOwnersBusinessesId,
        );

        owners[index] = getMappedOwner(businesses[0]);

        dispatch(
          checkOwnershipProgress({
            owners: owners,
            hasFile: ownership.blobReferences.length,
          }),
        );
      }

      return owners;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const deleteOwnerBusiness: any = createAsyncThunk(
  'ownership/deleteOwnerBusiness',
  async (id: number, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person } = profile;
      const owners = ownership.owners.slice();

      await updateIsActiveBusinessesOwnersBusinesses({
        isActive: false,
        modifiedBy: person.emailAddress,
        businessesOwnersBusinessesId: id,
      });

      const result = owners.filter(
        (owner: any) => owner.businessesOwnersBusinessesId !== id,
      );

      dispatch(
        checkOwnershipProgress({
          owners: result,
          hasFile: ownership.blobReferences.length,
        }),
      );

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const deleteOwnerPerson: any = createAsyncThunk(
  'ownership/deleteOwnerPerson',
  async (id: number, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person } = profile;
      const owners = ownership.owners.slice();

      await updateIsActiveBusinessesOwnersPersons({
        isActive: false,
        modifiedBy: person.emailAddress,
        businessesOwnersPersonsId: id,
      });

      const result = owners.filter(
        (owner: any) => owner.businessesOwnersPersonsId !== id,
      );

      dispatch(
        checkOwnershipProgress({
          owners: result,
          hasFile: ownership.blobReferences.length,
        }),
      );

      return result;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const deleteOwnerBlobReference: any = createAsyncThunk(
  'ownership/deleteOwnerBlobReference',
  async (id: number, thunk) => {
    try {
      const { getState, dispatch } = thunk;
      const { profile, ownership } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
      };
      const { person } = profile;
      const blobReferences = ownership.blobReferences.slice();

      await updateIsActiveBusinessesOwnersBlobReference({
        businessesOwnersBlobReferencesID: id,
        isActive: false,
        modifiedBy: person.emailAddress,
      });

      const references = blobReferences.filter(
        (blob) => blob.businessesOwnersBlobReferencesId !== id,
      );

      dispatch(
        checkOwnershipProgress({
          owners: ownership.owners.slice(),
          hasFile: references.length,
        }),
      );

      return references;
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const downloadOwnerBlobReference: any = createAsyncThunk(
  'businessInfo/downloadOwnerBlobReference',
  async ({ guid, fileName }: any) => {
    try {
      const file: any = await getBusinessesOwnersBlobReferenceFile(guid);

      fileDownload(file, fileName);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const downloadPersonFile: any = createAsyncThunk(
  'businessInfo/downloadPersonFile',
  async ({ guid, fileName }: any) => {
    try {
      const file: any = await getBusinessesOwnersPersonsFile(guid);

      fileDownload(file, fileName);
    } catch (e) {
      return Promise.reject(e);
    }
  },
);

export const createOwnerBlobReference: any = createAsyncThunk(
  'ownership/createOwnerBlobReference',
  async ({ files, typeCode }: any, thunk) => {
    const { getState, dispatch } = thunk;
    try {
      const { profile, ownership, dashboard } = getState() as {
        profile: ProfileState;
        ownership: OwnershipState;
        dashboard: DashboardState;
      };
      const { person, business } = profile;
      const { mappedBlobReferencesTypes } = dashboard.dropdowns;

      const blobReferences = ownership.blobReferences.slice();

      const fields = getMappedCreateBlobReferenceFields({
        file: files[0],
        person,
        business,
        blobReferencesTypesId: mappedBlobReferencesTypes[typeCode],
      });

      dispatch(setLoadingProgress(1));

      const options = getUploadOptions((value) =>
        dispatch(setLoadingProgress(value)),
      );

      const blobId = await addBusinessesOwnersBlobReference(fields, options);

      if (blobId) {
        dispatch(setLoadingProgress(100));
        setTimeout(() => {
          dispatch(setLoadingProgress(0));
        }, 2000);

        const blobs = await getBusinessesOwnersBlobReference(blobId);
        blobReferences.push(blobs[0]);

        dispatch(
          checkOwnershipProgress({
            owners: ownership.owners.slice(),
            hasFile: blobReferences.length,
          }),
        );
      }

      return blobReferences;
    } catch (e) {
      dispatch(setLoadingProgress(0));
      return Promise.reject(e);
    }
  },
);
