import api from 'api';

export const T_AND_C_VERSION = 'v1';

export interface TandCLogSchema {
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
  businessesId: number;
  personsId: number;
  tandCversion: string;
}

export interface TandCAcceptLogSchema extends TandCLogSchema {
  tandCacceptLogsId: number;
}

export interface TandCViewLogSchema extends TandCLogSchema {
  tandCviewLogsId: number;
}

export const addTandCacceptLogs = (data: Partial<TandCAcceptLogSchema>) => {
  return api.post('TandCacceptLogs/AddTandCacceptLogs', data);
};

export const addTandCviewLogs = (data: Partial<TandCViewLogSchema>) => {
  return api.post('TandCviewLogs/AddTandCviewLogs', data);
};

export default {
  addTandCacceptLogs,
  addTandCviewLogs,
};
