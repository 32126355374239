import LayoutSignUp from 'components/layouts/LayoutSignUp';
import PoweredBy from 'components/layouts/PoweredBy';
import { CheckCircleIcon } from 'components/icons';
import { formatDate } from 'utils/date';
import Link from 'components/Link';
import path from 'common/path';

const Title = ({ children, mb = 6 }) => (
  <h4 className={`heading-3xl mb-${mb}`}>{children}</h4>
);

const SubTitle = ({ children }) => (
  <h5 className="text-[24px] mb-6 text-gray-900 font-bold">{children}</h5>
);

const Typography = ({ children, last = false }) => (
  <p className={`${last ? 'mb-12' : 'mb-6'} text-gray-700`}>{children}</p>
);

const TermsOfUse = () => {
  return (
    <LayoutSignUp fullWidth>
      <div className="max-w-[1096px] m-auto">
        <div className="flex mt-3 mb-5">
          <div className="flex items-center rounded-full bg-gray-50 text-sm p-[5px]">
            <CheckCircleIcon className="mr-[14px] text-green-500" />
            <span className="text-gray-500">Last updated:</span>&nbsp;
            <span className="font-medium text-gray-900">
              {formatDate('2023-09-18', 'LL')}
            </span>
          </div>
        </div>

        <h2 className="text-5xl font-extrabold tracking-tight mb-12">
          <span className="text-blue-600">Transcard</span> SMART Suite Terms
          &amp; Conditions
        </h2>

        <div className="mb-12">
          <Typography>
            These Terms &amp; Conditions (these <b>“Terms”</b>) are a legally
            binding agreement governing access to and use of our Service between
            you (the <b>“you”</b>, <b>“your”</b>) and Transcard Payments, LLC, a
            Nevada limited liability company, it&apos;s officers, directors,
            consultants, agents, employees and affiliates (collectively,{' '}
            <b>“Company”</b>, <b>“we”</b>, <b>“our”</b>, <b>“us”</b>) with
            offices located at 1010 Market Street, Chattanooga, TN, 37402 (the{' '}
            <b>“Licensee”</b>). For direct communication you can reach us at
            sales@transcard.com.
          </Typography>
          <Typography>
            As used in these Terms, “Service” refers to our online platform,
            website, the App, any software, programs, documentation, tools, and
            components, and any updates (including software maintenance, service
            information, help content, bug fixes or maintenance releases)
            provided to you by us, directly or indirectly. To use the Service,
            you must agree to all the terms in these Terms. We may present to
            you an offer from a Bank to open one or more of the following: a
            Transcard deposit account (<b>“Banking Account”</b>), a Transcard
            debit card (<b>“Transcard Debit Card”</b>) or Transcard credit card
            (<b>“Transcard Credit Card”</b> and, individually and collectively,
            with the Banking Account and Transcard Card, the{' '}
            <b>“Bank Products”</b>). Our Services may be provided to you to help
            you view, manage and access your Bank Products. The Bank Products
            are made available by a bank , member of the Federal Deposit
            Insurance Corporation (each, a <b>“Bank”</b>), in partnership with
            us. We operate software that allows you to access services of Bank
            but we do not provide banking services. All banking, lending and
            money transmission services are provided by the Bank. The Bank
            Products are each governed by the terms and conditions you enter
            into with each Bank (the <b>“Bank Agreement”</b>). The Bank Products
            are not offered by us, and instead are offered by Bank.
          </Typography>
          <Typography>
            By accessing or using the Service, or clicking a button or checking
            a box marked “I Agree” or something similar, you agree to be bound
            by these Terms, whether you are a <b>“Visitor”</b> (which means that
            you simply browse our website or download our App), or a{' '}
            <b>“Customer”</b>
            (which means that you have registered for an account with us or have
            otherwise registered with us to access certain portions of the
            Service). You also acknowledge that you have read and understood our
            Privacy Policy, available at{' '}
            <Link href={path.privacy} target="_blank" rel="noreferrer">
              SMART-Hub.com
            </Link>
            , which is incorporated into these Terms by reference. The term
            “you” or “User” refers to a Visitor and/or Customer.
          </Typography>
          <Typography>
            Certain services may be subject to additional terms and conditions
            specified by us from time to time, and your use of such services is
            subject to those additional terms and conditions, which are hereby
            incorporated into these Terms by reference.
          </Typography>
          <Typography last>
            PLEASE READ THIS AGREEMENT CAREFULLY TO ENSURE THAT YOU UNDERSTAND
            EACH PROVISION. THESE TERMS CONTAIN A MANDATORY INDIVIDUAL
            ARBITRATION AND CLASS ACTION/JURY TRIAL WAIVER PROVISION THAT
            REQUIRES THE USE OF ARBITRATION ON AN INDIVIDUAL BASIS TO RESOLVE
            DISPUTES, RATHER THAN JURY TRIALS OR CLASS ACTIONS.
          </Typography>

          <Title>1. Use of Our Service</Title>
          <SubTitle>A. Eligibility</SubTitle>
          <Typography>
            This is a contract between you and Company. You must read and agree
            to these terms before using the Service. If you do not agree, you
            may not use the Service. You may use the Service only if you can
            form a binding contract with Company, and only in compliance with
            these Terms and all applicable local, state, national, and
            international laws, rules and regulations. Any use or access to the
            Service by anyone under 18 is strictly prohibited and in violation
            of these Terms. The Service is not available to any Users previously
            removed from the Service by Company.
          </Typography>
          <SubTitle>B. Company Service and App</SubTitle>
          <Typography>
            Subject to the terms and conditions of these Terms, you are hereby
            granted a nonexclusive, limited, non-transferable, revocable license
            to use the Service for your personal, non-commercial use only and as
            permitted by the features of the Service. Company reserves all
            rights not expressly granted herein in the Service and the Company
            Content (as defined below).
          </Typography>
          <Typography>
            We may make available software to access the Service via a mobile
            device (the <b>“App”</b>). To use the App you must have a mobile
            device that is compatible with the App. Company does not warrant
            that the App will be compatible with your mobile device. You may use
            mobile data in connection with the App and may incur additional
            charges from your wireless provider for these services. You agree
            that you are solely responsible for any such charges. Company hereby
            grants you a non-exclusive, non-transferable, revocable license to
            use a compiled code copy of the App for one Company account on one
            mobile device owned or leased solely by you, for your personal use.
            You shall not: (i) modify, disassemble, decompile or reverse
            engineer the App, except to the extent that such restriction is
            expressly prohibited by law; (ii) rent, lease, loan, resell,
            sublicense, distribute or otherwise transfer the App to any third
            party or use the App to provide time sharing or similar services for
            any third party; (iii) make any copies of the App; (iv) remove,
            circumvent, disable, damage or otherwise interfere with
            security-related features of the App, features that prevent or
            restrict use or copying of any content accessible through the App,
            or features that enforce limitations on use of the App; or (v)
            delete the copyright and other proprietary rights notices on the
            App. You acknowledge that Company may from time to time issue
            upgraded versions of the App, and may automatically electronically
            upgrade the version of the App that you are using on your mobile
            device. You consent to such automatic upgrading on your mobile
            device, and agree that the terms and conditions of these Terms will
            apply to all such upgrades. Any third-party code that may be
            incorporated in the App is covered by the applicable open source or
            third-party license end user license agreement, if any, authorizing
            use of such code. The foregoing license grant is not a sale of the
            App or any copy thereof, and Company or its third-party partners or
            suppliers retain all right, title, and interest in the App (and any
            copy thereof). Any attempt by you to transfer any of the rights,
            duties or obligations hereunder, except as expressly provided for in
            these Terms, is void. Company reserves all rights not expressly
            granted under these Terms.
          </Typography>
          <Typography>
            The Service is controlled from facilities in the United States.
            Company makes no representations that the Service is appropriate or
            available for use in other locations. Those who access or use the
            Service from other jurisdictions do so at their own volition and are
            entirely responsible for compliance with all applicable United
            States and local laws and regulations, including but not limited to
            export and import regulations. You may not use the Service if you
            are a resident of a country embargoed by the United States, or are a
            foreign person or entity blocked or denied by the United States
            government. You agree to comply with all United States and foreign
            laws related to use of the Service.
          </Typography>
          <SubTitle>C. Company Accounts</SubTitle>
          <Typography>
            Company allows you to register with us to access certain portions of
            the Service. To register with us, you need to provide information,
            including email address, personal information (e.g., street address,
            telephone number, date of birth, etc.) and a self-selected password,
            in order to create an account (<b>“Account”</b>). You authorize us,
            directly or through third parties, to make inquiries or verify that
            any and all information you provide to us is accurate and complete
            (for example, through social media or third party databases). You
            specifically authorize us to request a consumer report that contains
            your name and address.
          </Typography>
          <Typography>
            Once your request to register with us has been approved and you have
            been provisioned an Account, Company may grant you access to certain
            services and functionality that we may establish and maintain from
            time to time and in our sole discretion. We may maintain different
            types of accounts for different types of Users.
          </Typography>
          <Typography>
            You may never use another person’s account without permission. When
            creating your Account, you must provide accurate and complete
            information, and you must keep this information up to date. You are
            solely responsible for the activity that occurs on your Account, and
            you must keep your Account password secure. We encourage you to use
            “strong” passwords (passwords that use a combination of upper- and
            lower-case letters, numbers and symbols) with your Account. You must
            notify Company immediately of any breach of security or unauthorized
            use of your Account. Company will not be liable for any losses
            caused by any unauthorized use of your Account.
          </Typography>
          <SubTitle>D. Services Related to Bank Products</SubTitle>
          <Typography>
            If you open, obtain or otherwise access Bank Products, you will be
            able to manage your Bank Products, view your transaction history and
            other account information, perform certain transactions, and access
            various features of your Bank Products using the Service through the
            App. Your use of the Service is governed by these Terms, but the
            Banking Products and any transactions you make in connection with
            your Banking Products (including transactions you initiate through
            the Service) are covered by the Bank Agreement.
          </Typography>
          <Typography>
            The Service allows you to perform certain functions and transactions
            on or related to your Bank Products, including, viewing your balance
            and transaction history, and submitting transaction instructions to
            Bank, including instructs to transfer funds between your Banking
            Account and any bank account you link using the Service. Through the
            App, you may direct us to retrieve your account transaction history,
            balance information, and/or other information maintained by
            third-parties with which you have relationships, maintain accounts
            or engage in financial transactions (
            <b>“Third-Party Account Information”</b>). We work with one or more
            third-party service providers to access this Third-Party Account
            Information. We will use this information to provide you with the
            Service you request, for our own internal business purposes and to
            offer you other products and services that may be of interest to
            you. By requesting an account link, you authorize us to access this
            information maintained by identified third parties, on your behalf
            as your agent, and you expressly authorize such third parties to
            disclose your information to us. You also agree that you are
            responsible for keeping any passwords and usernames you provide to
            us so we can retrieve this Third-Party Account Information secure,
            and for keeping those passwords and user names up to date in the
            App. We do not review the Third-Party Account Information for
            accuracy, legality or non-infringement, and we are not responsible
            for your Third-Party Account Information or products and services
            offered by or on third-party sites. You acknowledge that any
            Third-Party Account Information that is displayed through the
            Service will be the information we most recently accessed, and that
            this information may not reflect pending transactions or other
            recent activity. Any transfer between your Banking Account and
            linked account are governed by the Bank Agreement. Certain
            transaction limitations may apply to the linked account transfers.
            Please refer to the Bank Agreement for details.
          </Typography>
          <Typography>
            By using the Service, you agree that we and Bank are entitled to act
            on transaction or any other instructions we receive under your login
            ID and password for the Service, and you agree that any actions
            taken under your login ID and password will have the same effect as
            a signature authorizing the transaction or other action. Subject to
            applicable law, we reserve the right to deny transactions or any
            other actions you authorize through the Service without notice to
            you.
          </Typography>
          <SubTitle>E. Service Rules</SubTitle>
          <Typography>
            You agree not to engage in any of the following prohibited
            activities: (i) copying, distributing, or disclosing any part of the
            Service in any medium, including without limitation by any automated
            or non - automated “scraping”; (ii) using any automated system,
            including without limitation “robots,” “spiders,” “offline readers,”
            etc., to access the Service in a manner that sends more request
            messages to the Company servers than a human can reasonably produce
            in the same period of time by using a conventional on-line web
            browser (except that Company grants the operators of public search
            engines revocable permission to use spiders to copy publicly
            available materials from the Company Site for the sole purpose of
            and solely to the extent necessary for creating publicly available
            searchable indices of the materials, but not caches or archives of
            such materials); (iii) transmitting spam, chain letters, or other
            unsolicited email; (iv) attempting to interfere with, compromise the
            system integrity or security or decipher any transmissions to or
            from the servers running the Service; (v) taking any action that
            imposes, or may impose at our sole discretion an unreasonable or
            disproportionately large load on our infrastructure; (vi) uploading
            invalid data, viruses, worms, or other software agents through the
            Service; (vii) collecting or harvesting any personally identifiable
            information, including account names, from the Service; (viii) using
            the Service for any commercial solicitation purposes; (ix)
            impersonating another person or otherwise misrepresenting your
            affiliation with a person or entity, conducting fraud, hiding or
            attempting to hide your identity; (x) interfering with the proper
            working of the Service; (xi) accessing any content on the Service
            through any technology or means other than those provided or
            authorized by the Service; or (xii) bypassing the measures we may
            use to prevent or restrict access to the Service, including without
            limitation features that prevent or restrict use or copying of any
            content or enforce limitations on use of the Service or the content
            therein.
          </Typography>
          <Typography>
            We may, without prior notice, change the Service; stop providing the
            Service or features of the Service, to you or to Users generally; or
            create usage limits for the Service. We may permanently or
            temporarily terminate or suspend your access to the Service without
            notice and liability for any reason, including if in our sole
            determination you violate any provision of these Terms, or for no
            reason. Upon termination for any reason or no reason, you continue
            to be bound by these Terms.
          </Typography>
          <Typography>
            You are solely responsible for your interactions with other Company
            Users. We reserve the right, but have no obligation, to monitor
            disputes between you and other Users. Company shall have no
            liability for your interactions with other Users, or for any User’s
            action or inaction.
          </Typography>
          <SubTitle>F. Fees</SubTitle>
          <Typography>
            There may be fees associated with your Banking Products. Please
            refer to your Bank Agreement for information concerning such fees.
          </Typography>
          <SubTitle>G. Pricing</SubTitle>
          <Typography last>
            Pricing terms for the SMART Suite products are available at{' '}
            <Link href="https://pricing.smart-hub.com" target="_blank">
              Pricing.SMART-hub.com
            </Link>
            , which is incorporated herein by reference. We may change the
            pricing at any time and without prior notice. You are encouraged to
            review the latest pricing at{' '}
            <Link href="https://pricing.smart-hub.com" target="_blank">
              Pricing.SMART-hub.com
            </Link>
            . If you do not agree to the price change, you must cancel and stop
            using the SMART Suite products before the price change takes effect.
            If there is a fixed term and price for your Service offer, that
            price will remain in force for the fixed term.
          </Typography>

          <Title>2. User Content</Title>
          <Typography>
            We do not claim ownership of user-generated content and material.
            Any and all photographs, articles, images, graphics, videos, sounds,
            music, audio recordings, text, files, profiles, communications,
            comments, feedback, suggestions, ideas, concepts, questions, data or
            other content that you: (i) submit or post on or through the
            Service, on any of our blogs, social media accounts or through tools
            or applications we provide for posting or sharing such content with
            us; or (ii) have posted or uploaded to your social media accounts,
            including but not limited to Instagram, Twitter, Facebook, Tumblr
            and Pinterest, which are tagged with any Company promoted hashtag
            (collectively “User Content”) shall be deemed non-confidential and
            non-proprietary. By submitting or posting any User Content, you
            grant to Company and its affiliates a perpetual, irrevocable,
            royalty-free, worldwide, sublicensable and transferable license to
            copy, publish, translate, modify, reformat, create derivative works
            from, distribute, reproduce, sell, display, transmit, publish,
            broadcast, host, archive, store, cache, use or otherwise exploit all
            or any portion of the User Content, as well as your name, persona
            and likeness included in any User Content and your social media
            account handle, username, real name, profile picture and/or any
            other information associated with the User Content, in any
            commercial or non-commercial manner whatsoever, in whole or in part,
            in any and all distribution channels, forms, media or technology,
            whether now known or hereafter developed, including but not limited
            to in stores, printed marketing materials, emails, web pages, social
            media accounts and for any other marketing, advertising, public
            relations, sales or promotional purposes with or without attribution
            and without further notice to you. Neither you, nor any other person
            or entity, will have the right to: (i) receive any royalty or
            consideration of any kind for the use of the User Content pursuant
            to these Terms; or (ii) inspect or approve the editorial copy or
            other material that may be used in connection with the User Content.
            Company will be free to use any ideas, concepts, know-how or
            techniques contained in such User Content for any purpose
            whatsoever, including but not limited to developing, manufacturing,
            and marketing products that incorporate or otherwise rely upon such
            information. Company shall have no obligation to monitor User
            Content, use or display User Content, compensate you for submitting
            User Content or respond to any User Content. Company retains the
            right, in its sole discretion and without prior notice, to remove,
            revise or refuse to post any User Content for any reason or no
            reason. Subject to the licenses granted in these Terms, you retain
            ownership of any copyright and other rights you may have in the User
            Content.
          </Typography>
          <Typography>
            By submitting or posting User Content on the Service, on your social
            media accounts or through any tools or applications we provide for
            posting or sharing your User Content with us, you represent and
            warrant that: (i) you own or control any and all rights in and to
            the User Content, and the right to grant all of the rights and
            licenses in these Terms, and if you are not the holder of such
            rights, the holder of such rights has completely and effectively
            waived all such rights and irrevocably granted you the right to
            grant the licenses stated above without the need for payment to you
            or any other person or entity; (ii) you have obtained permission
            from any individuals that appear in the User Content to use, and
            grant others the right to use, their name, image, voice and/or
            likeness without the need for payment to you or any other person or
            entity; (iii) you are 18 years of age or older; and (iv) the User
            Content does not: (a) contain false or misleading information; (b)
            infringe on the intellectual property, privacy, publicity,
            statutory, contractual or other rights of any third party; (c)
            contain any libelous, defamatory, obscene, offensive, threatening or
            otherwise harassing or hateful content; (d) contain any addresses,
            email addresses, phone numbers or any contact information; or (e)
            contain computer viruses, worms or other harmful files. Upon request
            by Company, you will furnish Company any documentation,
            substantiation or releases necessary to verify your compliance with
            these Terms. You are solely responsible for the User Content and you
            hereby agree to indemnify, defend and hold Company harmless from any
            and all damages, claims, expenses, costs or fees arising from or in
            connection with a breach of any of the foregoing representations and
            warranties, or your violation of any law or rights of a third party.
          </Typography>
          <Typography>
            Company does not guarantee the truthfulness, accuracy or reliability
            of any User Content or endorse any opinions expressed by you or
            anyone else. By submitting or posting the User Content you fully and
            unconditionally release and forever discharge Company from any and
            all claims, demands and damages (actual or consequential, direct or
            indirect), whether now known or unknown, of every kind and nature
            relating to, arising out of or in any way connected with: (i)
            disputes between you and one or more users or any other person or
            entity; or (ii) the use by Company or you of the User Content,
            including, without limitation, any and all claims that use of the
            User Content pursuant to these Terms violates any of your
            Intellectual Property Rights, rights of privacy or rights of
            integrity. You acknowledge and agree that Company has no control
            over, and shall have no liability for any damages resulting from,
            the use (including, without limitation, re-publication) or misuse by
            you or any third party of any User Content. Company acts as a
            passive conduit for User Content and has no obligation to screen or
            monitor User Content. If Company becomes aware of any User Content
            that allegedly may not conform to these Terms, Company may
            investigate the allegation and determine in its sole discretion
            whether to take action in accordance with these Terms. Company has
            no liability or responsibility to Users for performance or
            non-performance of such activities.{' '}
            <b>“Intellectual Property Rights”</b> means all patent rights,
            copyright rights, mask work rights, moral rights, rights of
            publicity, rights of attribution, trademark, trade dress and service
            mark rights, goodwill, trade secret rights and other intellectual
            property rights as may now exist or hereafter come into existence,
            and all applications therefore and registrations, renewals and
            extensions thereof, under the laws of any state, country, territory
            or other jurisdiction.
          </Typography>
          <Typography last>
            COMPANY HAS THE ABSOLUTE RIGHT TO REMOVE AND/OR DELETE WITHOUT
            NOTICE ANY USER CONTENT WITHIN ITS CONTROL THAT IT DEEMS
            OBJECTIONABLE. YOU CONSENT TO SUCH REMOVAL AND/OR DELETION AND WAIVE
            ANY CLAIM AGAINST COMPANY FOR SUCH REMOVAL AND/OR DELETION. COMPANY
            IS NOT RESPONSIBLE OR LIABLE FOR FAILURE TO STORE POSTED CONTENT OR
            OTHER MATERIALS YOU TRANSMIT THROUGH THE SERVICE. YOU SHOULD TAKE
            MEASURES TO PRESERVE COPIES OF ANY DATA, MATERIAL, CONTENT OR
            INFORMATION YOU POST ON THE SERVICE OR ANY OTHER SITES OR PLATFORMS.
          </Typography>
          <Title>3. Our Proprietary Rights</Title>
          <Typography>
            Except for your User Content, the Service and all materials therein
            or transferred thereby, including, without limitation, software,
            images, text, graphics, illustrations, logos, patents, trademarks,
            service marks, copyrights, photographs, audio, videos, music, and
            User Content belonging to other Users (the <b>“Company Content”</b>
            ), and all Intellectual Property Rights related thereto, are the
            exclusive property of Company and its licensors (including other
            Users who post User Content to the Service). Except as explicitly
            provided herein, nothing in these Terms shall be deemed to create a
            license in or under any such Intellectual Property Rights, and you
            agree not to sell, license, rent, modify, distribute, copy,
            reproduce, transmit, publicly display, publicly perform, publish,
            adapt, edit or create derivative works from any Company Content. Use
            of the Company Content for any purpose not expressly permitted by
            these Terms is strictly prohibited.
          </Typography>
          <Typography last>
            You may choose to or we may invite you to submit comments or ideas
            about the Service, including without limitation about how to improve
            the Service or our products (<b>“Ideas”</b>). By submitting any
            Idea, you agree that your disclosure is gratuitous, unsolicited and
            without restriction and will not place Company under any fiduciary
            or other obligation, and that we are free to use the Idea without
            any additional compensation to you, and/or to disclose the Idea on a
            non-confidential basis or otherwise to anyone. You further
            acknowledge that, by acceptance of your submission, Company does not
            waive any rights to use similar or related ideas previously known to
            Company, or developed by its employees, or obtained from sources
            other than you.
          </Typography>

          <Title>4. Privacy</Title>
          <Typography>
            We care about the privacy of our Users. In compliance with the U.S.
            federal and state privacy laws, including but not limited to the
            California Consumer Privacy Act (CCPA) and the Federal Trade
            Commission Act (FTC Act), you understand that by using the Service
            you consent to the collection, use and disclosure of your personally
            identifiable information and aggregate data as set forth in our{' '}
            <Link href={path.privacy} target="_blank" rel="noreferrer">
              Privacy Policy
            </Link>
            . Transcard will process a users personal data in accordance with
            its Privacy Notice (
            <Link
              href="https://www.transcard.com/privacy-policy"
              target="_blank"
              rel="noreferrer"
            >
              https://www.transcard.com/privacy-policy
            </Link>
            ), the terms which are incorporated into these Terms in adherence to
            the transparency provisions set out in Articles 13 and 14 of the UK
            GDPR.
          </Typography>
          <Typography>
            We also may share some or all of the information about you with our
            partners, (who may share it with their respective affiliates,
            agents, subcontractors, and employees), who may use this information
            to perform their obligations under their agreements with us, to
            operate and promote their respective services, to perform analytics
            and create reports, to prevent fraud, and for any other lawful
            purpose. At any time, we, and our partners may conclude that you
            will not be permitted to use the Service. You agree that we are
            permitted to contact and share information about you and your
            Account with partners, banks and financial institutions. This
            includes sharing information (a) about your transactions for
            regulatory or compliance purposes, (b) for use in connection with
            the management and maintenance of the Service, (c) to create and
            update their customer records about you and to assist them in better
            serving you, and (d) to conduct our risk management process, all in
            compliance with the U.S. Electronic Communications Privacy Act
            (ECPA).
          </Typography>
          <Typography last>
            By using our Services, you grant Company, its payment processors,
            and any financial institution partnering with Company, including
            Bank, the right, power, and authority to act on your behalf to
            access and transmit this information from the relevant payment
            processors and financial institutions, including from Bank, for the
            applicable Banking Products. You further consent to us submitting an
            I-94 Form with the U.S. Customs and Border Protection on your behalf
            or to request any information from any governmental authority in
            order to validate information you provide to us, pursuant to the
            U.S. Immigration and Nationality Act (INA). You represent and
            warrant that you have the authority to grant us authorization to
            file Form I-94 or to make any request to the U.S. Customs and Border
            Protection or any other governmental authority on your behalf.
          </Typography>

          <Title>5. Security</Title>
          <Typography last>
            Company cares about the integrity and security of your personal
            information. However, we cannot guarantee that unauthorized third
            parties will never be able to defeat our security measures or use
            your personal information for improper purposes. You acknowledge
            that you provide your personal information at your own risk.
          </Typography>

          <Title>6. Copyright Complaints</Title>
          <Typography>
            It is our policy to respond to alleged infringement notices that
            comply with the Digital Millennium Copyright Act of 1998 (
            <b>“DMCA”</b>). If you believe that your copyrighted work has been
            copied in a way that constitutes copyright infringement and is
            accessible via the Service, please notify Company’s copyright agent
            as set forth in the DMCA. For your complaint to be valid under the
            DMCA, you must provide the following information in writing:
          </Typography>
          <Typography>
            <b className="mr-4">A.</b>An electronic or physical signature of a
            person authorized to act on behalf of the copyright owner;
          </Typography>
          <Typography>
            <b className="mr-4">B.</b>Identification of the copyrighted work
            that you claim has been infringed;
          </Typography>
          <Typography>
            <b className="mr-4">C.</b>Identification of the material that is
            claimed to be infringing and where it is located on the Service;
          </Typography>
          <Typography>
            <b className="mr-4">D.</b>Information reasonably sufficient to
            permit Company to contact you, such as your address, telephone
            number, and, email address;
          </Typography>
          <Typography>
            <b className="mr-4">E.</b>A statement that you have a good faith
            belief that use of the material in the manner complained of is not
            authorized by the copyright owner, its agent, or law; and
          </Typography>
          <Typography>
            <b className="mr-4">F.</b>A statement, made under penalty of
            perjury, that the above information is accurate, and that you are
            the copyright owner or are authorized to act on behalf of the owner.
          </Typography>
          <Typography>
            The above information must be submitted to the following DMCA Agent:
          </Typography>
          <Typography>Attn: DMCA Notice, Transcard Payments, LLC</Typography>
          <Typography>Email: support@smart-hub.com</Typography>
          <Typography>
            UNDER FEDERAL LAW, IF YOU KNOWINGLY MISREPRESENT THAT ONLINE
            MATERIAL IS INFRINGING, YOU MAY BE SUBJECT TO CRIMINAL PROSECUTION
            FOR PERJURY AND CIVIL PENALTIES, INCLUDING MONETARY DAMAGES, COURT
            COSTS, AND ATTORNEYS’ FEES.
          </Typography>
          <Typography>
            Please note that this procedure is exclusively for notifying Company
            and its affiliates that your copyrighted material has been
            infringed. The preceding requirements are intended to comply with
            Company’s rights and obligations under the DMCA, including 17 U.S.C.
            §512(c), but do not constitute legal advice. It may be advisable to
            contact an attorney regarding your rights and obligations under the
            DMCA and other applicable laws.
          </Typography>
          <Typography last>
            In accordance with the DMCA and other applicable law, Company has
            adopted a policy of terminating, in appropriate circumstances, Users
            who are deemed to be repeat infringers. Company may also at its sole
            discretion limit access to the Service and/or terminate the accounts
            of any Users who infringe any Intellectual Property Rights of
            others, whether or not there is any repeat infringement.
          </Typography>

          <Title>7. Third-Party Links and Information</Title>
          <Typography last>
            The Service may contain links to third-party materials that are not
            owned or controlled by Company. Company does not endorse or assume
            any responsibility for any such third party sites, information,
            materials, products, or services. If you access a third-party
            website or service from the Service or share your User Content on or
            through any third-party website or service, you do so at your own
            risk, and you understand that these Terms and Company’s{' '}
            <Link href={path.privacy} target="_blank" rel="noreferrer">
              Privacy Policy
            </Link>{' '}
            do not apply to your use of such sites. You expressly relieve
            Company from any and all liability arising from your use of any
            third-party website, service, or content, including without
            limitation User Content submitted by other Users. Additionally, your
            dealings with or participation in promotions of advertisers found on
            the Service, including payment and delivery of goods, and any other
            terms (such as warranties) are solely between you and such
            advertisers. You agree that Company shall not be responsible for any
            loss or damage of any sort relating to your dealings with such
            advertisers.
          </Typography>

          <Title>8. Bank Products</Title>
          <Typography>
            SMART Products. You may connect your bank accounts and other
            financial accounts to Bank Products and our Service and access third
            party account information pursuant to the services made available
            under this section (“Account Access Services,” which shall be
            considered a Service). All such Account Access Services are
            performed in partnership with your bank However, our Service is
            offered independently, and not on behalf of any third party. For
            purposes of this Section 8, “Bank” shall include any financial
            institution partnering with provider, as well as any financial
            institution holding or offering your linked account. Your use of the
            Account Access Services constitutes your acceptance of the terms
            herein.
          </Typography>
          <Typography>
            <b className="mr-4">A.</b>
            <b className="mr-1">Provide Accurate Information.</b>
            You represent and agree that all information you provide to us in
            connection with the Account Access Services is accurate, current,
            and complete. You agree not to misrepresent your identity or account
            information. You agree to keep account information secure, up to
            date and accurate. You represent that you are a legal owner, or an
            authorized user, of the accounts at third party sites which you
            include or access through the Account Access Services, and that you
            have the authority to (i) designate us and our service providers as
            your agent, (ii) use the Account Access Services, and (iii) give us
            and our service providers the passwords, usernames, and all other
            information you provide.
          </Typography>
          <Typography>
            <b className="mr-4">B.</b>
            <b className="mr-1">Content You Provide.</b>
            Your use of the Account Access Services is your authorization for
            us, any, Bank and our respective service providers, as your agent,
            to access third party sites which you designate in order to retrieve
            information or to link to the Bank Product or Service. You are
            licensing to us and Bank and their respective service providers any
            information, data, passwords, usernames, PINS, personally
            identifiable information or other content you provide through the
            Account Access Services. You authorize us, Bank and our respective
            service providers to use any information, data, passwords,
            usernames, PINS, personally identifiable information or other
            content you provide through the Account Access Services or that we
            or our service providers retrieve on your behalf for purposes of
            providing the Account Access Services, to offer products and
            services, and for other permissible business purposes. Except as
            otherwise provided herein, we, Bank and our respective service
            providers may store, use, change, or display such information or
            create new content using such information.
          </Typography>
          <Typography>
            <b className="mr-4">C.</b>
            <b className="mr-1">Authority to Access Information.</b>
            Unless and until these Terms are terminated, you grant us, Bank and
            our respective service providers the right to access information at
            third-party sites on your behalf and to link your designated
            accounts to the Bank Products or the Service. Third-party sites
            shall be entitled to rely on the authorizations granted by you or
            through your account. For all purposes hereof, you hereby grant us,
            Bank and our respective service providers the right to access
            third-party sites to retrieve information, use such information, as
            described herein, with the full power and authority to do and
            perform each and every act and thing required and necessary to be
            done in connection with such activities, as fully to all intents and
            purposes as you might or could do in person. Upon notice to us, you
            may revoke our or Bank’s right to access information at third party
            sites on your behalf. You understand and agree that the Account
            Access Services are not sponsored or endorsed by any third-party
            site. YOU ACKNOWLEDGE AND AGREE THAT WHEN WE, BANK OR OUR SERVICE
            PROVIDERS ACCESS OR RETRIEVE INFORMATION FROM THIRD-PARTY SITES,
            THEY ARE ACTING AT YOUR REQUEST AND WITH YOUR PERMISSION AND
            AUTHORIZATION, AND NOT ON BEHALF OF THE THIRD-PARTY SITES.
          </Typography>
          <Typography>
            <b className="mr-4">D.</b>
            <b className="mr-1">Third Party Accounts.</b>
            With respect to any third-party sites, we may enable you to access
            through the Account Access Services or with respect to any non- Bank
            accounts you include in the Account Access Services, you agree to
            the following: (i) you are responsible for all fees charged by the
            third party in connection with any non- Bank accounts and
            transactions. You agree to comply with the terms and conditions of
            those accounts and agree that these Terms does not amend any of
            those terms and conditions. If you have a dispute or question about
            any transaction on a non-Bank account, you agree to direct these to
            the account provider; and (ii) any links to third party sites that
            we may provide are for your convenience only, and we, Bank and our
            respective service providers do not sponsor or endorse those sites.
            Any third-party services, which you may be able to access through
            the Account Access Services, are services of the listed
            institutions. We nor our service providers have responsibility for
            any transactions and inquiries you initiate at third party sites.
            The third-party sites you select are solely responsible for their
            services to you. We nor our service providers are liable for any
            damages or costs of any type arising out of or in any way connected
            with your use of the services of those third parties.
          </Typography>
          <Typography>
            <b className="mr-4">E.</b>
            <b className="mr-1">Limitations of Account Access Services.</b>
            Without limiting any other terms of these Terms, when using the
            Account Access Services, you may incur technical or other
            difficulties. We nor our service providers are responsible for any
            technical or other difficulties or any resulting damages that you
            may incur. Any information displayed or provided as part of the
            Account Access Services is for informational purposes only, does not
            represent an official record of your account, may not reflect your
            most recent transactions, and should not be relied on for
            transactional purposes. We and our service providers reserve the
            right to change, suspend or discontinue any or all of the Account
            Access Services at any time without prior notice. In the event that
            Account Access Services are discontinued, your information shall be
            retained in accordance with these Terms, applicable law and our
            privacy policies.
          </Typography>
          <Typography>
            <b className="mr-4">F.</b>
            <b className="mr-1">Aggregated Data.</b>
            Anonymous, aggregate information, comprising financial account
            balances, other financial account data, or other available data that
            is collected through your use of the Account Access Services, may be
            used by us and our service providers in compliance with U.S. federal
            and state privacy laws, including the CCPA and FTC Act, as well as
            the GDPR. This data is used for conducting certain analytical
            research, performance tracking and benchmarking. Our service
            providers may publish summary or aggregate results relating to
            metrics comprised of research data, from time to time, and
            distribute or license such anonymous, aggregated research data for
            any purpose, including but not limited to, helping to improve
            products and services and assisting in troubleshooting and technical
            support. In adherence to the transparency and data protection
            provisions set out in Articles 13 and 14 of the GDPR, the U.S.
            Privacy Act, the Privacy Shield Frameworks, and the ECPA, your
            personally identifiable information will not be shared with or sold
            to third parties.
          </Typography>
          <Typography>
            <b className="mr-4">G.</b>
            <b className="mr-1">Ownership.</b>
            You agree that we, Bank and our respective service providers, as
            applicable, retain all ownership and proprietary rights in the
            Account Access Services, associated content, technology, mobile
            applications and websites.
          </Typography>
          <Typography>
            <b className="mr-4">H.</b>
            <b className="mr-1">User Conduct.</b>
            You agree not to use the Account Access Services or the content or
            information delivered through the Account Access Services in any way
            that would: (a) be fraudulent or involve the sale of counterfeit or
            stolen items, including but not limited to use of the Account Access
            Services to impersonate another person or entity; (b) violate any
            law, statute, ordinance or regulation (including without limitation
            those governing export control, consumer protection, unfair
            competition, anti-discrimination or false advertising); (c) create
            liability for us, Bank or our respective service provider or cause
            us or Bank to lose the services of our service providers; (d) access
            the information and content programmatically by macro or other
            automated means; or (e) gain unauthorized entry or access to
            computer systems.
          </Typography>
          <Typography>
            <b className="mr-4">I.</b>
            <b className="mr-1">Indemnification.</b>
            In addition to indemnification obligations otherwise set forth in
            these Terms, you agree to defend, indemnify and hold harmless us,
            Bank, and our respective third party service providers and their
            officers, directors, employees and agents from and against any and
            all third party claims, liabilities, damages, losses or expenses,
            including settlement amounts and reasonable attorneys’ fees and
            costs, arising out of or in any way connected with your access to or
            use of the Account Access Services, your violation of these terms or
            your infringement, or infringement by any other user of your
            account, of any intellectual property or other right of anyone.
          </Typography>
          <Typography>
            <b className="mr-4">J.</b>
            <b className="mr-1">Disclaimer.</b>
            The Account Access Services are not intended to provide legal, tax
            or financial advice. The Account Access Services, or certain
            portions and/or functionalities thereof, are provided as strictly
            educational in nature and are provided with the understanding that
            neither we, Bank nor our respective third-party providers are
            engaged in rendering accounting, investment, tax, legal, or other
            professional services. If legal or other professional advice
            including financial, is required, the services of a competent
            professional person should be sought. We, Bank and our respective
            third-party providers specifically disclaim any liability, loss, or
            risk which is incurred as consequence, directly or indirectly, of
            the use and application of any of the content on this site. Further,
            we, Bank and our respective third-party providers are not
            responsible for any credit, insurance, employment or investment
            decisions or any damages or other losses resulting from decisions
            that arise in any way from the use of the Account Access Services or
            any materials or information accessible through it. Past performance
            does not guarantee future results. Financial Institution and its
            third-party providers do not warrant that the Account Access
            Services comply with the requirements of the FINRA or those of any
            other organization anywhere in the world.
          </Typography>
          <Typography>
            <b className="mr-4">K.</b>
            <b className="mr-1">DISCLAIMER OF WARRANTIES.</b>
            IN ADDITION TO DISCLAIMERS OF WARRANTIES OTHERWISE SET FORTH IN
            THESE TERMS, YOU AGREE YOUR USE OF THE ACCOUNT ACCESS SERVICES AND
            ALL INFORMATION AND CONTENT (INCLUDING THAT OF THIRD PARTIES) IS AT
            YOUR RISK AND IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
            WE, AND OUR SERVICE PROVIDERS, DISCLAIM ALL WARRANTIES OF ANY KIND
            AS TO THE USE OF THE ACCOUNT ACCESS SERVICES, WHETHER EXPRESS OR
            IMPLIED, INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
            NON-INFRINGEMENT. WE, AND OUR SERVICE PROVIDERS, MAKE NO WARRANTY
            THAT THE ACCOUNT ACCESS SERVICES: (i) WILL MEET YOUR REQUIREMENTS;
            (ii) THE ACCOUNT ACCESS SERVICES WILL BE UNINTERRUPTED, TIMELY,
            SECURE, OR ERROR-FREE; (iii) THE RESULTS THAT MAY BE OBTAINED FROM
            THE ACCOUNT ACCESS SERVICES WILL BE ACCURATE OR RELIABLE; (iv) THE
            QUALITY OF ANY PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
            OBTAINED BY YOU THROUGH THE ACCOUNT ACCESS SERVICES WILL MEET YOUR
            EXPECTATIONS; OR (v) ANY ERRORS IN THE ACCOUNT ACCESS SERVICES OR
            TECHNOLOGY WILL BE CORRECTED. ANY MATERIAL DOWNLOADED OR OTHERWISE
            OBTAINED THROUGH THE USE OF THE ACCOUNT ACCESS SERVICES IS DONE AT
            YOUR OWN DISCRETION AND RISK AND YOU ARE SOLELY RESPONSIBLE FOR ANY
            DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE
            DOWNLOAD OF SUCH MATERIAL. WE, ON BEHALF OF OURSELVES AND ALL THIRD
            PARTY DATA PROVIDERS, EXPRESSLY DISCLAIMS ANY TYPE OF REPRESENTATION
            OR WARRANTY REGARDING THE AVAILABILITY OR RESPONSE TIME OF THE
            SERVICE OR CONTENT OR INFORMATION OBTAINED THROUGH THE SERVICE OR
            THAT SUCH ACCESS WILL BE UNINTERRUPTED OR ERROR-FREE AND, EXCEPT AS
            EXPRESSLY PROVIDED FOR HEREIN, EXPRESSLY DISCLAIMS THE ACCURACY,
            COMPLETENESS AND CURRENCY OF ALL INFORMATION COLLECTED ON YOUR
            BEHALF. NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED
            BY YOU FROM US, BANK OR THEIR RESPECTIVE SERVICE PROVIDERS THROUGH
            OR FROM THE ACCOUNT ACCESS SERVICES WILL CREATE ANY WARRANTY NOT
            EXPRESSLY STATED IN THESE TERMS.
          </Typography>
          <Typography>
            <b className="mr-4">L.</b>
            <b className="mr-1">LIMITATION OF LIABILITY.</b>
            IN ADDITION TO AND WITHOUT LIMITING ANY OTHER LIMITATIONS OF
            LIABILITY SET FORTH IN THESE TERMS, AND EXCEPT AS PROHIBITED BY LAW,
            YOU AGREE THAT WE, BANK AND OUR AND THEIR RESPECTIVE THIRD PARTY
            SERVICE PROVIDERS WILL NOT BE LIABLE FOR ANY INDIRECT, INCIDENTAL,
            SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING, BUT NOT
            LIMITED TO DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER
            LOSSES, EVEN IF WE OR THEY HAVE BEEN ADVISED OF THE POSSIBILITY OF
            SUCH DAMAGES, RESULTING FROM: (i) THE USE OR THE INABILITY TO USE
            THE ACCOUNT ACCESS SERVICES AT OUR WEBSITE/MOBILE APPLICATION OR OF
            ANY THIRD PARTY ACCOUNT PROVIDER’S WEBSITE/MOBILE APPLICATION; (ii)
            THE COST OF GETTING SUBSTITUTE GOODS AND SERVICES; (iii) ANY
            PRODUCTS, DATA, INFORMATION OR SERVICES PURCHASED OR OBTAINED OR
            MESSAGES RECEIVED OR TRANSACTIONS ENTERED INTO, THROUGH OR FROM THE
            ACCOUNT ACCESS SERVICES; (iv) UNAUTHORIZED ACCESS TO OR ALTERATION
            OF YOUR TRANSMISSION OR DATA; (v) STATEMENTS OR CONDUCT OF ANYONE ON
            THE ACCOUNT ACCESS SERVICES; (vi) THE USE, INABILITY TO USE,
            UNAUTHORIZED USE, PERFORMANCE OR NON-PERFORMANCE OF ANY THIRD PARTY
            ACCOUNT PROVIDER SITE, EVEN IF THE PROVIDER HAS BEEN ADVISED
            PREVIOUSLY OF THE POSSIBILITY OF SUCH DAMAGES; OR (vii) ANY OTHER
            MATTER RELATING TO THE ACCOUNT ACCESS SERVICES. ANY CLAIM ARISING
            UNDER OR IN CONNECTION WITH THIS AGREEMENT MUST BE RBOUGHT WITHIN 12
            MONTHS OF SUCH ACTION ACCRUING.
          </Typography>
          <Typography>
            <b className="mr-4">M.</b>
            You acknowledge that the Account Access Services and any software
            underlying such Account Access Services, as well as any product are
            subject to the U.S. Export Administration Regulations (15 CFR,
            Chapter VII) and that you will comply with these regulations. You
            will not export or re-export the software or Account Access
            Services, directly or indirectly, to: (1) any countries that are
            subject to U.S. export restrictions; (2) any end user who has been
            prohibited from participating in U.S. export transactions by any
            federal agency of the U.S. government; or (3) any end user who you
            know or have reason to know will utilize them in the design,
            development or production of nuclear, chemical or biological
            weapons. You further acknowledge that the Services may include
            technical data subject to export and re-export restrictions imposed
            by U.S. law.
          </Typography>
          <Typography last>
            <b className="mr-4">N.</b>
            <b className="mr-1">Third Party Beneficiary.</b>
            Bank, MX and their service providers are third party beneficiaries
            to any terms set forth in this Sections 8, 10, and 13(c) and may
            enforce such terms against to you the extent permitted by law. You
            release MX and its third-party service providers of all liability
            and obligation related to any delays, inaccuracies or incomplete
            Service caused by the failure of Client or its third-party service
            providers to properly or timely meet their obligations or
            requirements.
          </Typography>

          <Title>9. Method Services</Title>
          <Typography>
            You may remit funds from your Bank Products to designated recipients
            through services offered by your bank, (<b>“Method”</b>), as set
            forth in this section (<b>“Method Services”</b>). We do not
            transfer, custody or remit funds. Instead, Method Services are
            performed by Method and its bank partners. Method Services are
            independent of our Service and are governed by the Method Agreement,
            as well as this Section 9. For purposes of this Section 9, “Bank”
            shall include any financial institution partnering with Method. Your
            use of the Method Services constitutes your acceptance of the terms
            herein and the Method Agreements.
          </Typography>
          <Typography>
            <b className="mr-4">A.</b>
            <b className="mr-1">Origination Authorization.</b>
            You agree to authorize Method, as your payments service provider, to
            originate funds transfers according to your instructions as provided
            by you via the Service or Method Services.
          </Typography>
          <Typography>
            <b className="mr-4">B.</b>
            <b className="mr-1">Disclosures.</b>
          </Typography>
          <Typography>
            <b className="mr-4">(a)</b>
            <b className="mr-1">Funding Source.</b>
            It may be permitted to choose different funding sources or create a
            hierarchy of funding sources for sending or receiving funds using
            the Method Services. If this is permitted, the funding source or
            hierarchy of funding sources will be disclosed to you for any given
            transfer before you authorize the transfer.
          </Typography>
          <Typography>
            <b className="mr-4">(b)</b>
            <b className="mr-1">Peer-to-Peer Transfers.</b>
            If your Method Services are used to for a peer-to-peer payment, you
            must complete the memo field for noting the purpose of the payment.
          </Typography>
          <Typography>
            <b className="mr-4">(c)</b>
            <b className="mr-1">Transfer Times.</b>
            Subject to any delays caused by financial institutions independent
            of the Method platform or delays due to fraud or compliance reviews
            initiated by Method, transfers will normally be processed in the
            following time frame: within 3 to 4 business days for transfers from
            a Bank Product to a Bank. Transfer times are not guaranteed in any
            way.
          </Typography>
          <Typography>
            <b className="mr-4">(d)</b>
            <b className="mr-1">Recurring Payments.</b>
            In the event you are permitted to make recurring payments using the
            Method Services, we will disclose to you the payment amount(s) and
            payment schedule. You have the right to receive advance notice of
            the amount and date of each payment that will occur in the future at
            least 14 days before the payment is initiated if the amount varies
            from the previous payment, unless you are given a range for the
            payment amount, in which case, notice will only be provided if the
            payment falls outside of the range. You may cancel any recurring
            payment by through the Service.
          </Typography>
          <Typography>
            <b className="mr-4">C.</b>
            <b className="mr-1">Dispute Resolution for End Users.</b>
            In the event of an Error as defined by 12 CFR Part 1005 (Regulation
            E), please see your Bank Agreement for your error resolution rights.
          </Typography>
          <Typography>
            <b className="mr-4">D.</b>
            <b className="mr-1">
              Accuracy of End User Data Provided to Method.
            </b>
            You represent and agree that all information you provide to us in
            connection with the Method Services is accurate, current, and
            complete. You agree not to misrepresent your identity or account
            information. You agree to keep account information secure, up to
            date and accurate. You represent that you are a legal owner, or an
            authorized user, of the accounts at third party sites which you
            include or access through the Method Services, and that you have the
            authority to: (i) designate us and our service providers as your
            agent; (ii) use the Method Services; and (iii) give us and our
            service providers the passwords, usernames, and all other
            information you provide.
          </Typography>
          <Typography>
            <b className="mr-4">E.</b>
            <b className="mr-1">Transaction and End User Risk Management.</b>
            You understand and agree that Method may decline, restrict, or
            otherwise limit any transaction and/or your ability to use the
            Method Services in accordance with Method’s risk management
            policies. Method reserves the right to restrict, suspend or
            terminate your use of the Method Services at any time if: (a) Method
            determines that your activities violate the Method Terms, any other
            applicable Method agreement or policy, or any applicable laws; (b)
            Method reasonably determines that the risk associated with you or
            your activities poses unacceptable risk to Method and/or its users;
            (c) you do not provide Method with all information as required or
            requested by Method; or (d) for any other reason, in Method’s
            reasonable discretion. If Method suspends or restricts your use of
            the Method Services, we will use reasonable efforts to provide
            timely notice to you upon us learning of the suspension or
            restriction. Such notice may occur after the suspension or
            restriction has occurred. Method may also, in its reasonable
            discretion, contact you for Method’s fraud investigation and/or risk
            management purposes.
          </Typography>
          <Typography>
            <b className="mr-4">F.</b>
            <b className="mr-1">Your Eligibility.</b>
            You may only access or use the Method Services if you are a resident
            of the United States. You agree that you will not enroll or attempt
            to enroll in Method Services if you do not meet these criteria.
          </Typography>
          <Typography>
            <b className="mr-4">G.</b>
            <b className="mr-1">Required Disclosures.</b>
            To access the Method Services, you hereby accept the Method Terms
            and Method Privacy Policy (collectively, <b>“Method Agreements”</b>
            ).
          </Typography>
          <Typography last>
            <b className="mr-4">H.</b>
            <b className="mr-1">Third Party Beneficiary.</b>
            Bank, Method and their service providers are third party
            beneficiaries to any terms set forth in this Sections 9 and may
            enforce such terms against to you the extent permitted by law.
          </Typography>

          <Title>10. Indemnity</Title>
          <Typography last>
            You agree to defend, indemnify and hold harmless Company and its
            subsidiaries, agents, licensors, managers, and other affiliated
            companies, and their employees, contractors, agents, officers and
            directors, from and against any and all claims, damages,
            obligations, losses, liabilities, costs or debt, and expenses
            (including but not limited to attorney’s fees) arising from: (i)
            your use of and access to the Service, including any data or content
            transmitted or received by you; (ii) your violation of any term of
            these Terms, including without limitation your breach of any of the
            representations and warranties above; (iii) your violation of any
            third-party right, including without limitation any right of privacy
            or Intellectual Property Rights; (iv) your violation of any
            applicable law, rule or regulation; (v) User Content or any content
            that is submitted via your Account including without limitation
            misleading, false, or inaccurate information; (vi) your negligent or
            willful misconduct; or (vii) any other party’s access and use of the
            Service with your unique username, password or other appropriate
            security code.
          </Typography>

          <Title>11. No Warranty</Title>
          <Typography>
            THE SERVICE IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS. USE
            OF THE SERVICE IS AT YOUR OWN RISK. TO TH E MAXIMUM EXTENT PERMITTED
            BY APPLICABLE LAW, THE SERVICE IS PROVIDED WITHOUT WARRANTIES OF ANY
            KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
            IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
            PURPOSE, OR NON-INFRINGEMENT. NO ADVICE OR INFORMATION, WHETHER ORAL
            OR WRITTEN, OBTAINED BY YOU FROM COMPANY OR THROUGH THE SERVICE WILL
            CREATE ANY WARRANTY NOT EXPRESSLY STATED HEREIN. WITHOUT LIMITING
            THE FOREGOING, COMPANY, ITS SUBSIDIARIES, ITS AFFILIATES, AND ITS
            LICENSORS DO NOT WARRANT THAT THE CONTENT IS ACCURATE, RELIABLE OR
            CORRECT; THAT THE SERVICE WILL MEET YOUR REQUIREMENTS; THAT THE
            SERVICE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION,
            UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE
            CORRECTED; OR THAT THE SERVICE IS FREE OF VIRUSES OR OTHER HARMFUL
            COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
            USE OF THE SERVICE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE
            SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR MOBILE
            DEVICE OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD OR YOUR USE
            OF THE SERVICE.
          </Typography>
          <Typography>
            COMPANY DOES NOT WARRANT, ENDORSE, GUARANTEE, OR ASSUME
            RESPONSIBILITY FOR ANY PRODUCT OR SERVICE ADVERTISED OR OFFERED BY A
            THIRD PARTY THROUGH THE SERVICE OR ANY HYPERLINKED WEBSITE OR
            SERVICE, AND COMPANY WILL NOT BE A PARTY TO OR IN ANY WAY MONITOR
            ANY TRANSACTION BETWEEN YOU AND THIRD-PARTY PROVIDERS OF PRODUCTS OR
            SERVICES.
          </Typography>
          <Typography last>
            FEDERAL LAW, SOME STATES, PROVINCES AND OTHER JURISDICTIONS DO NOT
            ALLOW THE EXCLUSION AND LIMITATIONS OF CERTAIN IMPLIED WARRANTIES,
            SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. THIS AGREEMENT GIVES
            YOU SPECIFIC LEGAL RIGHTS, AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH
            VARY FROM STATE TO STATE. THE DISCLAIMERS AND EXCLUSIONS UNDER THIS
            AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
          </Typography>

          <Title>12. Limitation of Liability</Title>
          <Typography>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL
            COMPANY, ITS AFFILIATES, AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS OR
            LICENSORS BE LIABLE FOR ANY INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL,
            CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION
            DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE
            LOSSES, ARISING OUT OF OR RELATING TO THE USE OF, OR INABILITY TO
            USE, THIS SERVICE. UNDER NO CIRCUMSTANCES WILL COMPANY BE
            RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING,
            TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICE OR YOUR
            ACCOUNT OR THE INFORMATION CONTAINED THEREIN.
          </Typography>
          <Typography>
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, COMPANY ASSUMES
            NO LIABILITY OR RESPONSIBILITY FOR ANY: (I) ERRORS, MISTAKES, OR
            INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF
            ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO OR USE OF OUR
            SERVICE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE
            SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV)
            ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE
            SERVICE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE TH AT MAY
            BE TRANSMITTED TO OR THROUGH OUR SERVICE BY ANY THIRD PARTY; (VI)
            ANY ERRORS OR OMISSIONS IN ANY CONTENT OR FOR ANY LOSS OR DAMAGE
            INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, EMAILED,
            TRANSMITTED, OR OTHERWISE MADE AVAILABLE THROUGH THE SERVICE; AND/OR
            (VII) USER CONTENT OR THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT
            OF ANY THIRD PARTY. IN NO EVENT SHALL COMPANY, ITS AFFILIATES,
            AGENTS, DIRECTORS, EMPLOYEES, SUPPLIERS, OR LICENSORS BE LIABLE TO
            YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES,
            LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE AMOUNT YOU PAID TO
            COMPANY HEREUNDER OR $100.00, WHICHEVER IS GREATER.
          </Typography>
          <Typography>
            THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED
            LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY,
            OR ANY OTHER BASIS, EVEN IF COMPANY HAS BEEN ADVISED OF THE
            POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY
            SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY LAW IN THE APPLICABLE
            JURISDICTION.
          </Typography>
          <Typography last>
            SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF INCIDENTAL
            OR CONSEQUENTIAL DAMAGES, SO THE ABOVE LIMITATIONS OR EXCLUSIONS MAY
            NOT APPLY TO YOU. THIS AGREEMENT GIVES YOU SPECIFIC LEGAL RIGHTS,
            AND YOU MAY ALSO HAVE OTHER RIGHTS WHICH VARY FROM STATE TO STATE.
            THE DISCLAIMERS, EXCLUSIONS, AND LIMITATIONS OF LIABILITY UNDER THIS
            AGREEMENT WILL NOT APPLY TO THE EXTENT PROHIBITED BY APPLICABLE LAW.
          </Typography>

          <Title>
            13. Governing Law, Arbitration, and Class Action/Jury Trial Waiver
          </Title>
          <Typography>
            <b className="mr-4">A.</b>
            <b className="mr-1">Governing Law for U.S. Users.</b>
            You agree that: (i) the Service shall be deemed solely based in the
            United States and Delaware; and (ii) the Service shall be deemed a
            passive one that does not give rise to personal jurisdiction over
            us, either specific or general, in jurisdictions other than
            Delaware. These Terms shall be governed by the internal substantive
            laws of the State of Delaware, without respect to its conflict of
            laws principles. The parties acknowledge that these Terms evidence a
            transaction involving interstate commerce. Notwithstanding the
            preceding sentences with respect to the substantive law, any
            arbitration conducted pursuant to the terms of these Terms shall be
            governed by the Federal Arbitration Act (9 U.S.C. §§ 1-16). The
            application of the United Nations Convention on Contracts for the
            International Sale of Goods is expressly excluded. You agree to
            submit to the personal jurisdiction of the federal and state courts
            located in Delaware for any actions for which we retain the right to
            seek injunctive or other equitable relief in a court of competent
            jurisdiction to prevent the actual or threatened infringement,
            misappropriation or violation of a our copyrights, trademarks, trade
            secrets, patents, or other intellectual property or proprietary
            rights, as set forth in the Arbitration provision below, including
            any provisional relief required to prevent irreparable harm. You
            agree that Delaware is the proper forum for any appeals of an
            arbitration award or for trial court proceedings in the event that
            the arbitration provision below is found to be unenforceable.
          </Typography>
          <Typography>
            <b className="mr-4">B.</b>
            <b className="mr-1">Arbitration.</b>
            READ THIS SECTION CAREFULLY BECAUSE IT REQUIRES THE PARTIES TO
            ARBITRATE THEIR DISPUTES AND LIMITS THE MANNER IN WHICH YOU CAN SEEK
            RELIEF FROM COMPANY. For any dispute with Company, you agree to
            first contact us at support@smart-enroll.com and attempt to resolve
            the dispute with us informally. In the unlikely event that Company
            has not been able to resolve a dispute it has with you after sixty
            (60) days, we each agree to resolve any claim, dispute, or
            controversy (excluding any claims for injunctive or other equitable
            relief as provided below) arising out of or in connection with or
            relating to these Terms, or the breach or alleged breach thereof
            (collectively, <b>“Claims”</b>), by binding arbitration by JAMS,
            under the Optional Expedited Arbitration Procedures then in effect
            for JAMS, except as provided herein. JAMS may be contacted at
            www.jamsadr.com. The arbitration will be conducted in Delaware,
            unless you and Company agree otherwise. If you are using the Service
            for commercial purposes, each party will be responsible for paying
            any JAMS filing, administrative and arbitrator fees in accordance
            with JAMS rules, and the award rendered by the arbitrator shall
            include costs of arbitration, reasonable attorneys’ fees and
            reasonable costs for expert and other witnesses. If you are an
            individual using the Service for non- commercial purposes: (i) JAMS
            may require you to pay a fee for the initiation of your case, unless
            you apply for and successfully obtain a fee waiver from JAMS; (ii)
            the award rendered by the arbitrator may include your costs of
            arbitration, your reasonable attorney’s fees, and your reasonable
            costs for expert and other witnesses; and (iii) you may sue in a
            small claims court of competent jurisdiction without first engaging
            in arbitration, but this does not absolve you of your commitment to
            engage in the informal dispute resolution process. Any judgment on
            the award rendered by the arbitrator may be entered in any court of
            competent jurisdiction. Nothing in this Section shall be deemed as
            preventing Company from seeking injunctive or other equitable relief
            from the courts as necessary to prevent the actual or threatened
            infringement, misappropriation, or violation of our data security,
            Intellectual Property Rights or other proprietary rights.
          </Typography>
          <Typography last>
            <b className="mr-4">C.</b>
            <b className="mr-1">Class Action/Jury Trial Waiver.</b>
            WITH RESPECT TO ALL PERSONS AND ENTITIES, REGARDLESS OF WHETHER THEY
            HAVE OBTAINED OR USED THE SERVICE FOR PERSONAL, COMMERCIAL OR OTHER
            PURPOSES, ALL CLAIMS MUST BE BROUGHT IN THE PARTIES’ INDIVIDUAL
            CAPACITY, AND NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED
            CLASS ACTION, COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION OR
            OTHER REPRESENTATIVE PROCEEDING. THIS WAIVER APPLIES TO CLASS
            ARBITRATION, AND, UNLESS WE AGREE OTHERWISE, THE ARBITRATOR MAY NOT
            CONSOLIDATE MORE THAN ONE PERSON’S CLAIMS. YOU AGREE THAT, BY
            ENTERING INTO THIS AGREEMENT, YOU AND COMPANY ARE EACH WAIVING THE
            RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A CLASS ACTION,
            COLLECTIVE ACTION, PRIVATE ATTORNEY GENERAL ACTION, OR OTHER
            REPRESENTATIVE PROCEEDING OF ANY KIND.
          </Typography>

          <Title>14. Additional App Store Terms</Title>
          <Typography>
            <b className="mr-4">A.</b>
            <b className="mr-1">App from Apple App Store.</b>
            The following applies to any App you acquire from the Apple App
            Store (<b>“Apple-Sourced Software”</b>): You acknowledge and agree
            that these Terms is solely bet ween you and Company, not Apple, Inc.
            (<b>“Apple”</b>) and that Apple has no responsibility for the Apple-
            Sourced Software or content thereof. Your use of the Apple-Sourced
            Software must comply with the App Store Terms of Service. You
            acknowledge that Apple has no obligation whatsoever to furnish any
            maintenance and support services with respect to the Apple-Sourced
            Software. In the event of any failure of the Apple-Sourced Software
            to conform to any applicable warranty, you may notify Apple, and
            Apple will refund the purchase price for the Apple-Sourced Software
            to you; to the maximum extent permitted by applicable law, Apple
            will have no other warranty obligation whatsoever with respect to
            the Apple-Sourced Software, and any other claims, losses,
            liabilities, damages, costs or expenses attributable to any failure
            to conform to any warranty will be solely governed by these Terms
            and any law applicable to Company as provider of the software. You
            acknowledge that Apple is not responsible for addressing any claims
            of you or any third party relating to the Apple-Sourced Software or
            your possession and/or use of the Apple-Sourced Software, including,
            but not limited to: (i) product liability claims; (ii) any claim
            that the Apple-Sourced Software fails to conform to any applicable
            legal or regulatory requirement; and (iii) claims arising under
            consumer protection or similar legislation; and all such claims are
            governed solely by these Terms and any law applicable to Company as
            provider of the software. You acknowledge that, in the event of any
            third-party claim that the Apple-Sourced Software or your possession
            and use of that Apple-Sourced Software infringes that third party’s
            Intellectual Property Rights, Company, not Apple, will be solely
            responsible for the investigation, defense, settlement and discharge
            of any such intellectual property infringement claim to the extent
            required by these Terms. You and Company acknowledge and agree that
            Apple, and Apple’s subsidiaries, are third-party beneficiaries of
            these Terms as relates to your license of the Apple-Sourced
            Software, and that, upon your acceptance of the terms and conditions
            of these Terms, Apple will have the right (and will be deemed to
            have accepted the right) to enforce these Terms as relates to your
            license of the Apple-Sourced Software against you as a third- party
            beneficiary thereof.
          </Typography>
          <Typography last>
            <b className="mr-4">B.</b>
            <b className="mr-1">App from Google Play Store.</b>
            The following applies to any App you acquire from the Google Play
            Store (<b>“Google-Sourced Software”</b>): (i) you acknowledge that
            these Terms are between you and Company only, and not with Google,
            Inc. (<b>“Google”</b>); (ii) your use of Google-Sourced Software
            must comply with Google’s then-current Google Play Store Terms of
            Service; (iii) Google is only a provider of the Google Play Store
            where you obtained the Google-Sourced Software; (iv) Company, and
            not Google, is solely responsible for its Google-Sourced Software;
            (v) Google has no obligation or liability to you with respect to
            Google-Sourced Software or these Terms; and (vi) you acknowledge and
            agree that Google is a third-party beneficiary to these Terms as it
            relates to Company’s Google-Sourced Software.
          </Typography>

          <Title>15. Rewards Terms</Title>
          <Typography>
            If you hold a Transcard Credit Card or Transcard Debit Card
            (individually and collectively the <b>“Transcard Card”</b>) and have
            been approved to participate in the rewards program (the{' '}
            <b>“Program”</b>) offered by Transcard in connection with the
            Transcard Card and to obtain a rewards account (the{' '}
            <b>“Rewards Account”</b>), this Section applies to you and the
            Program. Acceptance or use of your Transcard Card or related credit
            or deposit account, as applicable (each, an <b>“Account”</b>), or
            participation in the Program constitutes your acceptance of each of
            the terms described herein. The Transcard Credit Card and the
            Transcard Debit Card may earn and be eligible for different Rewards.
            You consent to the terms of the Program and the establishing and
            maintaining of a Rewards Account for you by us or our service
            provider, including Dosh and other third parties. By enrolling in or
            participating the Program, you also agree to Dosh’s terms of use:
            <Link
              href="https://www.dosh.com/Transcard-rewards-powered-by-dosh-terms"
              target="_blank"
            >
              https://www.dosh.com/Transcard-rewards-powered-by-dosh-terms
            </Link>
            .
          </Typography>
          <Typography>
            <b className="mr-4">A.</b>
            <b className="mr-1">Earning Rewards.</b>
            You can earn points on eligible retail purchases from eligible
            merchants, as each is determined by us in our sole discretion (
            <b>“Eligible Purchases”</b>), charged to the Transcard Card during
            each billing cycle and posted to your Account during such billing
            cycle (<b>“Rewards”</b>). The amount of Rewards you earn on an
            Eligible Purchase will depend on the category of purchase and
            special program, promotions or transactions that we may offer. All
            offers made available to you are temporary and may become
            unavailable without notice. For the current list of participating
            merchants and offers, please check the App. Participating merchants
            and offer terms may be changed from time to time without notice.
            Cash advances, including purchases of cash equivalents of any kind,
            balance transfers, convenience check amounts, purchases we believe
            to have been made by or for a commercial purpose, Transcard Card
            fees, interest charges, ATM transactions, cash withdrawals or cash
            back transactions, credit insurance, gambling fees, gaming chips,
            credit protection, debt cancellation charges and unauthorized or
            fraudulent transactions do not qualify as Eligible Purchases and do
            not earn Rewards. If a merchant is not an eligible merchant, as
            determined by us, you will not earn Rewards based on any transaction
            with such merchant. All Rewards received are exclusive of any
            applicable withholding, sales, use, excise, value added, or other
            taxes. Merchants who accept the Transcard Card are assigned a
            merchant code, which is determined by the merchant or its processor
            in accordance with card brand procedures based on the kinds of
            products and services they primarily sell. As such, even though a
            merchant may sell retail items that may be similar to retail items
            sold by another merchant, the merchants may not have the same
            merchant code, and therefore the purchases at the merchants may not
            be included in the same category for the purposes of earning
            Rewards. Purchases must be submitted by merchants using the
            identified merchant category codes to qualify for earning Rewards.
            We are not responsible for incorrectly coded transactions.
            Additionally, purchases made through third parties, including online
            marketplaces and resellers, or using a third-party payment account
            will not be eligible for accruing Rewards. Questions concerning
            Eligible Purchases and what constitutes Eligible Purchases will be
            determined in the sole discretion of Transcard.
          </Typography>
          <Typography>
            If you return, charge back, cancel, dispute, or otherwise request a
            refund for an Eligible Purchase for which you have already received
            Rewards, we reserve the right to remove any related Rewards amount
            from your Rewards Account or withhold future Rewards to cover any
            such amount.
          </Typography>
          <Typography>
            The Rewards you earn will be credited to your Rewards Account on a
            daily basis when transactions post to your Account. We encourage you
            to frequently review your Rewards Account, accessible through the
            App. Once posted to your Rewards Account, these Rewards are
            available to you should you choose to redeem them. There is no limit
            on the number of Rewards you can earn each year. You cannot earn
            Rewards during any period in which your Transcard Card or Account is
            not open, is lost, stolen, revoked or closed or is otherwise in
            default of any of the Bank Agreement governing the Transcard Card or
            Account or these Terms. If it has been more than thirty (30) days
            since the transaction and Rewards are not reflected on your Rewards
            Account summary, please contact customer service. Inquiries must be
            made within sixty (60) days of the transaction. If you do not file
            your claim within sixty (60) days, Rewards earned or otherwise
            applied to your Rewards Account will be deemed accurate and you will
            have waived any claim for adjustment.
          </Typography>
          <Typography>
            No Rewards will be retroactively applied to your Rewards Account for
            otherwise qualifying transactions occurring during any period in
            which your Transcard Card or Account was not open, was lost, stolen,
            revoked or closed or was otherwise in default of the Bank Agreement
            or these Terms or when you were not enrolled into this Program. All
            transactions in U.S. Dollars for which Rewards are awarded to you
            will be rounded to a whole-dollar amount for purposes of determining
            the amount of Rewards to be awarded.
          </Typography>
          <Typography>
            Rewards Account statements will be made available through the App.
            Individual transactions forming the basis for awarding Rewards will
            not be shown on your Transcard Card statement or your Rewards
            Account statement. The frequency, timing, content or layout of the
            Rewards Account statements are subject to change from time-to-time
            at the discretion of Transcard. Rewards cannot be combined with
            other discount or reward programs unless specifically authorized by
            us.
          </Typography>
          <Typography>
            <b className="mr-4">B.</b>
            <b className="mr-1">Redemption.</b>
            You may redeem any Rewards credited to the Rewards Account by using
            the App. Redemptions are subject to Rewards availability and other
            requirements contained herein. Your Rewards will not be available
            for redemption until posted to your Rewards Account regardless of
            the date of the transaction for which Rewards are earned.
          </Typography>
          <Typography>
            Your Rewards may only be redeemed if you are in compliance with all
            terms and conditions of these Terms and the Bank Agreement at the
            time of any Rewards redemption. Rewards earned in connection with
            any authorized user will be consolidated and reported in a single
            Rewards Account. Consolidation of Rewards into one Rewards Account
            will be based upon the person, as determined by us, identified by
            name and social security number as the primary accountholder on each
            Transcard Card.
          </Typography>
          <Typography>
            Rewards may be redeemed for cash back in the form of a statement
            credit to your Transcard Credit Card or a credit to your Account for
            the Transcard Debit Card (each, a <b>“Cash Back Redemption”</b>) or
            may be redeemed for eligible gift cards. Cash Back Redemption
            provides a specific U.S. Dollar amount that, upon redemption of
            Rewards, is applied as a credit to the Account. A Cash Back
            Redemption credited to your Account will not substitute for your
            payment obligations under the Bank Agreement. Cash Back Redemptions
            will generally be credited to your Account within two (2) business
            days of your redemption. If you elect to redeem Rewards for an
            eligible gift card, the gift card may be delivered electronically or
            traditional plastic gift cards. Traditional gift cards will be
            delivered within approximately 21 days. If you do not receive your
            redemption within the above-specified timeframes or if it is damaged
            or is defective upon delivery, please contact us via the App. The
            names and logos of merchants are used with permission of the
            merchants and all trademarks are the property of their respective
            owners. The merchants for which gift cards are offered are not
            affiliated with the Program. We do not guarantee the availability of
            a specific gift card, and the choices available may change without
            notice. Access to an electronic gift card may be suspended in the
            event of suspected fraud. Additional terms and conditions apply to
            each gift card.
          </Typography>
          <Typography>
            <b className="mr-4">C.</b>
            <b className="mr-1">
              Restriction and Cancellation on Rewards and Forfeiture.
            </b>
            Rewards do not expire; however, we may temporarily or permanently
            suspend your participation in the Program as well as Rewards or any
            redemption of Rewards if: (1) you violate, or your Account is in
            default under, the Bank Agreement or you violate any provision of
            these Terms; (2) you engage in any fraudulent or other illegal
            activity in connection with the Program, or otherwise engage in
            activity that we deem to be abusive or “gaming” conduct under the
            Program, as determined in our sole discretion; (3) you engage in
            fraudulent activity on any other loan, account or product we may
            offer or service; (4) we or our bank partner close your Rewards
            Account or Account for any reason; or (5) we terminate your
            participation in the Program.
          </Typography>
          <Typography>
            <b className="mr-4">D.</b>
            <b className="mr-1">Ownership and Proprietary Rights.</b>
            You acknowledge and agree that the Program and related services are
            protected by applicable copyright, patent, trademark, and other
            intellectual property laws. All words and logos displayed in
            connection with the Program that are marked by the ™ or ® symbols
            are trademarks and service marks of their respective owners. The
            display of a third-party trademark in connection with the Services
            does not mean that we have any relationship with that third party or
            that such third party endorses the Program.
          </Typography>
          <Typography>
            <b className="mr-4">E.</b>
            <b className="mr-1">Miscellaneous.</b>
            Rewards you receive may qualify as taxable income to you. You
            acknowledge and agree that you are responsible for determining,
            paying, withholding, filing, and reporting all taxes, duties, and
            other governmental assessments associated with your activity in
            connection with the Program. We are not responsible for determining
            whether you owe taxes in connection with your participation in the
            Program or use of Rewards or for collecting, reporting, or remitting
            taxes arising from your participation in the Program or use of
            Rewards, except for our own income taxes.
          </Typography>
          <Typography>
            You represent and warrant that you will not access or use the
            Services to engage in any illegal, fraudulent, or other illicit
            activity. The Program is owned and administered by us, and is
            subject to all applicable laws and regulations. It is void where
            prohibited.
          </Typography>
          <Typography>
            Rewards are not your property and may not be assigned or transferred
            to any person (including upon death or as a part of a domestic
            relations matter). Rewards have neither cash value, surrender value,
            transfer value nor any other value of any kind until and to the
            extent they are actually redeemed by us. Rewards cannot be used to
            offset any amount due from you to us or any bank partner. Rewards
            used by you for redemption will be those unredeemed Rewards that
            have been on the Rewards Account the longest time. All redemptions
            are final. The sale or barter of any Rewards, other than by us, is
            expressly prohibited.
          </Typography>
          <Typography>
            We may terminate your participation in the Program at any time. We
            may end this Program at any time for any reason or no reason, upon
            notice to you. We may also in our sole discretion cancel, change,
            amend, modify, or restrict the Program or any aspects, terms or
            features of the Program or this Section. You agree that you will
            abide by all such changes and that they will apply to you and the
            Program.
          </Typography>
          <Typography>
            All interpretations of this Section will be determined by us in our
            sole discretion. We may temporarily or permanently disqualify you
            from participating in the Program and/or adjust or cause to be
            forfeited any or all Rewards accrued as a result of your abusive
            behavior, fraud, misrepresentation, any violation of law or any
            other violation of any of the terms or conditions of these Terms or
            the Bank Agreement, in each case as determined by us in our sole
            discretion.
          </Typography>
          <Typography>
            In addition to any other indemnification obligations under these
            Terms, you agree to indemnify, defend and hold us and our
            third-party service providers and their respective affiliates,
            directors, officers, employees, agents and contractors harmless from
            and against any loss, damage, liability, cost, or expense of any
            kind (including attorneys’ fees) arising from your or an authorized
            user’s: (i) participation in the Program; or (ii) any fraud or
            misuse of the Program.
          </Typography>
          <Typography last>
            If we do not credit, or improperly deny, a Rewards redemption to
            which you were otherwise entitled, then your exclusive remedy will
            be the issuance of the improperly denied Rewards redemption, if
            available, or such other alternative benefit as we in our sole
            discretion may determine. Neither we nor any of our affiliates,
            agents or representatives have any other or additional liability to
            you or any other person for such error(s), subject to applicable
            law.
          </Typography>

          <Title>16. General</Title>
          <Typography>
            <b className="mr-4">A.</b>
            <b className="mr-1">Assignment.</b>
            These Terms, and any rights and licenses granted hereunder, may not
            be transferred or assigned by you, but may be assigned by Company
            without restriction. Any attempted transfer or assignment in
            violation hereof shall be null and void.
          </Typography>
          <Typography>
            <b className="mr-4">B.</b>
            <b className="mr-1">
              Notification Procedures and Changes to These Terms.
            </b>
            Company may provide notifications, whether such notifications are
            required by law or are for marketing or other business related
            purposes, to you via email notice, written or hard copy notice, or
            through posting of such notice on our website, as determined by
            Company in our sole discretion. Company reserves the right to
            determine the form and means of providing notifications to our
            Users, provided that you may opt out of certain means of
            notification as described in these Terms. Company is not responsible
            for any automatic filtering you or your network provider may apply
            to email notifications we send to the email address you provide us.
            Company may, in its sole discretion, modify or update these Terms
            from time to time, and so you should review this page periodically.
            We have the right to change or add to the terms of these Terms at
            any time, and to change, delete, discontinue, or impose conditions
            on any feature or aspect of the Service without notice, unless
            required by applicable law. In the event we are required to provide
            notice, we may post notice on our website or any other website
            maintained or owned by us and identified to you or email you at the
            email address associated with your Account. Any use of our Service
            after our publication of any such changes shall constitute your
            acceptance of future Terms of Use as modified. If you do not agree
            to any of these terms or any future Terms of Use, do not use or
            access (or continue to access) the Service. No modification or
            amendment to these Terms shall be binding upon Company unless in a
            written instrument signed by a duly authorized representative of
            Company.
          </Typography>
          <Typography>
            <b className="mr-4">C.</b>
            <b className="mr-1">Suspension.</b>
            Notwithstanding anything to the contrary in these Terms and without
            limiting any other right Company may have under these Terms, Company
            may temporarily suspend User’s access to any portion or all of the
            Service if: (i) Company reasonably determines that: (A) there is a
            threat or attack on any aspect of the Service or any server, system,
            IP address or network associated with a Service; (B) User’s or any
            User’s use of the Service disrupts or poses a security risk to
            Company, the Service or to any other User or vendor of Company; (C)
            User, or any User, is using the Service for fraudulent or illegal
            activities; (D) subject to applicable law, User made an assignment
            for the benefit of creditors or similar disposition of its assets,
            or become the subject of any bankruptcy, reorganization,
            liquidation, dissolution, or similar proceeding; or (E) Company’s
            provision of the Service to User is prohibited by applicable law; or
            (ii) any vendor of Company has suspended or terminated Company’s
            access to or use of any third-party services or products required to
            enable User to access the Service (any such suspension described in
            subclause (i), or (ii), a <b>“Service Suspension”</b>). Company will
            not provide written notice of any Service Suspension to User, unless
            required by law.
          </Typography>
          <Typography>
            <b className="mr-4">D.</b>
            <b className="mr-1">Aggregated Statistics.</b>
            As between Company and User, all right, title, and interest in
            Aggregated Statistics, and all Intellectual Property Rights therein,
            belong to and are retained solely by Company. User acknowledges and
            agrees that Company may derive Aggregated Statistics from any data
            or information submitted by or collected in connection with User,
            including, without limitation, User Content and use Aggregated
            Statistics for its own business purposes, including training its
            machine learning models, benchmarking vendor product and service
            offerings and otherwise improving Company’s products and services.
            In processing and making Aggregated Statistics publicly available,
            Company will act in compliance with applicable privacy regulations,
            including but not limited to the U.S. federal and state privacy laws
            such as the CCPA and the FTC Act, as well as the UK’s GDPR.
            <b>“Aggregated Statistics”</b> means: (a) any and all data and
            information related to your use of the Service that is used by
            Company in an aggregate manner that is not reasonably capable of
            being associated with you or your company, including compiled
            statistical and performance information related to the provision and
            operation of the Service; and (b) any and all data and information
            derived from any data or information submitted by or collected in
            connection with User that is not reasonably capable of being
            associated with you.
          </Typography>
          <Typography>
            <b className="mr-4">E.</b>
            <b className="mr-1">Monitoring.</b>
            Notwithstanding anything to the contrary in these Terms, we will
            have the right to monitor User’s use of the Service to: (a) confirm
            User is complying with these Terms; and (b) collect and compile
            Aggregated Statistics.
          </Typography>
          <Typography>
            <b className="mr-4">F.</b>
            <b className="mr-1">Entire Agreement.</b>
            These Terms, together with any amendments and any additional
            agreements you may enter into with Company in connection with the
            Service, shall constitute the entire agreement between you and
            Company concerning the Service. You acknowledge that in agreeing to
            these Terms, you have not relied on any statement, promise,
            representation, assurance, or warranty other than those expressly
            set out in the Terms.
          </Typography>
          <Typography>
            <b className="mr-4">G.</b>
            <b className="mr-1">Severability.</b>
            If any provision of these Terms is deemed invalid or unenforceable
            by a court of competent jurisdiction, the invalidity or
            unenforceability of such provision shall not affect the validity or
            enforceability of the remaining provisions of these Terms, which
            shall remain in full force and effect. Specifically, in the event of
            the unenforceability of the universal Class Action/Jury Trial
            Waiver, the entire arbitration agreement shall be unenforceable.
          </Typography>
          <Typography>
            <b className="mr-4">H.</b>
            <b className="mr-1">Non-solicitation.</b>
            You agree not to solicit for employment or hire any of
            Company&apos;s current employees or contractors during the term of
            these Terms and for a period of one year after the termination or
            expiration of these Terms, without express written consent from the
            Company.
          </Typography>
          <Typography>
            <b className="mr-4">I.</b>
            <b className="mr-1">Further Assurance.</b>
            You agree to undertake any necessary actions, including the
            execution of any necessary documents, to give effect to the
            provisions and intent of these Terms.
          </Typography>
          <Typography>
            <b className="mr-4">J.</b>
            <b className="mr-1">Interpretation.</b>
            Section headings have been added for convenience of reference and
            will not be deemed part of these Terms. For purposes of this
            Agreement, “will” and “shall” are intended to have equivalent
            meaning, and “including” will mean “including without limitation.”
            Any references to statutes or statutory provisions in this Agreement
            include those statutes or provisions as amended, replaced, or
            re-enacted from time to time.
          </Typography>
          <Typography>
            <b className="mr-4">K.</b>
            <b className="mr-1">Survival.</b>
            Any provision that is reasonably necessary to accomplish or enforce
            the purpose of these Terms shall survive and remain in effect in
            accordance with its terms upon the termination of these Terms.
          </Typography>
          <Typography>
            <b className="mr-4">L.</b>
            <b className="mr-1">Additional Terms.</b>
            By consenting to these Terms, you consent to allowing the location
            and identity information (from your mobile carrier) of your mobile
            phone to be used by us to locate and verify the device and that the
            location and identity information may be shared with third parties
            for anti-fraud purposes. All collection, use, and disclosure of your
            location and identity information will be in accordance with US
            privacy laws, the UK GDPR, and any other applicable privacy laws and
            regulations. We reserve the right to refuse or limit access to our
            Service if you withdraw your consent to use of location and identity
            information or do not provide such consent in the first instance.
            You have the right to withdraw your consent at any time; however,
            doing so may affect the functionality of the Service or its
            features.
          </Typography>
          <Typography last>
            <b className="mr-4">M.</b>
            <b className="mr-1">
              Consent to Be Contacted & Marking Communications.
            </b>
            <Typography>
              1. General Consent: By accepting these Terms, you expressly
              consent to be contacted by us for any and all purposes, at any
              telephone number, or physical or electronic address you provide or
              at which you may be reached. This includes, but is not limited to,
              calls, SMS messages (including text messages to your mobile
              device), emails, and other forms of communication.
            </Typography>
            <Typography>
              2. Direct Marketing (US & UK Compliance): In compliance with both
              US regulations and the UK&apos;s Privacy and Electronic
              Communications Regulations (PECR), we often require your explicit
              consent before sending you any marketing messages. This consent
              must be knowingly and freely given, clear, and specific. You might
              be asked to tick an opt-in box, confirming you&apos;re agreeable
              to receive our marketing communications.
            </Typography>
            <Typography>
              3. Telephone Marketing (UK Compliance): We won&apos;t make
              marketing calls to any number listed on the UK&apos;s Telephone
              Preference Service (TPS) or Corporate TPS (CTPS) unless
              you&apos;ve specifically given us permission to do so. However,
              numbers not listed on the TPS or CTPS are approachable, excluding
              for marketing claims management services. Thus, we screen call
              lists against the TPS and CTPS.
            </Typography>
            <Typography>
              4. Telephone Marketing (US Compliance): As per the Telephone
              Consumer Protection Act (TCPA) in the US, we are required to
              obtain your express written consent before making telemarketing
              calls, sending text messages, or faxes. Your consent to these
              Terms satisfies this requirement.
            </Typography>
            <Typography>
              5. PECR Compliance: In the UK, the general rules for marketing
              apply equally to both corporate and individual subscribers under
              the PECR. However, marketing through electronic mail, like email
              or text messages, does not necessarily require opt-in consent from
              corporate subscribers.
            </Typography>
            <Typography>
              6. Telemarketing Calls: You agree that we, our affiliates, and
              third parties might call, email, or send SMS messages to the
              details you&apos;ve provided. This is to describe potentially
              interesting goods and services. These might be executed using
              automatic systems and may involve prerecorded or artificial voice
              messaging. Even if your number is registered on a Do-Not-Call
              list, your consent will still stand. This consent stays until you
              revoke it, which can be done as described below.
            </Typography>
            <Typography>
              7. Opt-Out: At any point, you can withdraw your consent for
              marketing communications. To do so, contact us at
              support@smart-enroll.com. If you ever wish to unsubscribe from our
              text messages, simply reply STOP to any of our texts. You might
              receive a final message from us confirming your request.
            </Typography>
            <Typography>
              8. Call Recording and Monitoring: For purposes like quality
              assurance, training, risk management, or collection, we might
              record or monitor calls made to or by us. By agreeing to these
              Terms, you consent to such recording and monitoring.
            </Typography>
            <Typography>
              9. Right of Refusal: We reserve the right to refuse or limit
              access to our services if you do not provide the required
              consents, or if you revoke them.
            </Typography>
            <Typography>
              10. Verification: The contact details you provide must be your
              own. By providing them, you assure us they&apos;re yours, not
              someone else&apos;s. You must notify us if you cease using any
              contact method you&apos;ve given us, especially telephone numbers.
            </Typography>
          </Typography>
          <Typography>
            <b className="mr-4">N.</b>
            <b className="mr-1">Force Majeure.</b>
            In no event will Company be liable to User, or be deemed to have
            breached these Terms, for any failure or delay in performing its
            obligations under these Terms, if and to the extent such failure or
            delay is caused by any circumstances beyond Company’s reasonable
            control, including but not limited to acts of God, flood, fire,
            earthquake, explosion, war, terrorism, invasion, riot or other civil
            unrest, strikes, labor stoppages or slowdowns or other industrial
            disturbances, or passage of law or any action taken by a
            governmental or public authority, including imposing an embargo,
            pandemic, epidemic, or any other health crisis or public health
            emergency.
          </Typography>
          <Typography>
            <b className="mr-4">O.</b>
            <b className="mr-1">No Waiver.</b>
            No waiver of any term of these Terms shall be deemed a further or
            continuing waiver of such term or any other term, and Company’s
            failure to assert any right or provision under these Terms shall not
            constitute a waiver of such right or provision.
          </Typography>
          <Typography>
            <b className="mr-4">P.</b>
            <b className="mr-1">Contact.</b>
            Please contact us at support@smart-enroll.com with any questions
            regarding these Terms.
          </Typography>
          <Typography last>
            <b className="mr-4">Q.</b>
            Your acceptance of any services is an express acknowledgment that
            you have not relied on any statement, promise or representation or
            assurance or warranty that is not set out in the Terms.
          </Typography>
        </div>
        <PoweredBy />
      </div>
    </LayoutSignUp>
  );
};

export default TermsOfUse;
