import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import classNames from 'classnames';

import PermissionSection from 'components/permission/PermissionSection';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppSelector, useAppDispatch } from 'hooks';
import HideComponent from 'components/HideComponent';
import imageLabel from 'assets/images/hakuna.png';
import { Permissions } from 'entities/dashboard';
import { getTheme } from 'theme/selectors';
import {
  ChevronUpIcon,
  CommunicationIcon,
  FinanceIcon,
} from 'components/icons';

import ConnectedBankAccount from './Connectors/ConnectedBankAccount';
import ConnectedAccounts from './Connectors/ConnectedAccounts';
import BadgeConnector from './Connectors/BadgeConnector';
import NotConnected from './Connectors/NotConnected';
import {
  setErpAccount,
  setIsAccountingOpen,
  setStep,
} from './AccountingSoftware/accountingSlice';
import {
  getBankAccounts,
  getIsBankAccountInfoOpen,
  getisBankAccountOpen,
  getConnectedBankAccount,
} from './BankAccounts/selectors';
import {
  getErpAccounts,
  getConnectedErpAccount,
  getIsLoading,
} from './AccountingSoftware/selectors';
import {
  setIsBankAccountInfoOpen,
  setIsBankAccountOpen,
} from './BankAccounts/bankAccountsSlice';

type CompleteSmarterProps = {
  className?: string;
  showModalTitle?: boolean;
};

const CompleteSmarter: React.FC<CompleteSmarterProps> = ({
  className,
  showModalTitle = false,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('dashboard');

  const [opened, setOpened] = useState(true);

  const bankAccounts = useAppSelector(getBankAccounts);
  const erpAccounts = useAppSelector(getErpAccounts);
  const connectedErpAccount = useAppSelector(getConnectedErpAccount);
  const connectedBankAccount = useAppSelector(getConnectedBankAccount);
  const isLoading = useAppSelector(getIsLoading);
  const isBankAccountInfoOpen = useAppSelector(getIsBankAccountInfoOpen);
  const isBankAccountOpen = useAppSelector(getisBankAccountOpen);
  const { textColor } = useAppSelector(getTheme);
  const bankPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.bankIntegration),
  );
  const erpPermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.erpIntegration),
  );

  const handleAccountingModal = () => {
    dispatch(setIsAccountingOpen(true));
  };

  const handleBankAccountModal = () =>
    dispatch(setIsBankAccountOpen(!isBankAccountOpen));
  const handleBankAccountInfoModal = () =>
    dispatch(setIsBankAccountInfoOpen(!isBankAccountInfoOpen));

  const handleSelectedAccounts = (value) => {
    dispatch(setStep(3));
    dispatch(setErpAccount(value));
    handleAccountingModal();
  };

  return (
    <div className={classNames(className, 'rounded-10 p-6')}>
      <div className="flex items-start justify-between">
        <div className="flex-1">
          <div className="flex item-center flex-1">
            <p className="font-semibold text-gray-900 mb-0.5 self-center mr-1">
              {t(
                `dashboard:connectors.${
                  showModalTitle ? 'modalTitle' : 'title'
                }`,
              )}
            </p>
            {!opened && (
              <div className="flex items-center px-[5px] flex-1">
                <HideComponent show={erpPermissions.view}>
                  <BadgeConnector
                    image={
                      <CommunicationIcon className="w-6 h-6 text-blue-600" />
                    }
                    text={t('dashboard:connectors.accounting.title')}
                    connected={connectedErpAccount}
                    started={erpAccounts.length > 0}
                    fullWidth
                  />
                </HideComponent>
                <HideComponent show={bankPermissions.view}>
                  <BadgeConnector
                    image={<FinanceIcon className="w-6 h-6 text-blue-600" />}
                    text={
                      bankAccounts.length > 1
                        ? t('dashboard:connectors.bank.multiTitle', {
                            value: bankAccounts.length,
                          })
                        : t('dashboard:connectors.bank.title')
                    }
                    connected={connectedBankAccount}
                    started={bankAccounts.length > 0}
                    fullWidth
                  />
                </HideComponent>
              </div>
            )}
          </div>
          {opened && (
            <p className="text-secondary">
              {t('dashboard:connectors.subTitle')}
            </p>
          )}
        </div>
        <button
          className="p-2 cursor-pointer rounded-10 hover:bg-gray-100"
          onClick={() => setOpened(!opened)}
        >
          <ChevronUpIcon
            className={classNames({ 'transform rotate-180': !opened })}
          />
        </button>
      </div>

      {opened && (
        <div className="flex mt-6 -mx-3">
          <PermissionSection
            permission={Permissions.erpIntegration}
            showMessage
          >
            <NotConnected
              image={
                <CommunicationIcon
                  styles={{
                    color: textColor.primary.base,
                  }}
                />
              }
              title={t('dashboard:connectors.accounting.connect')}
              text={t('dashboard:connectors.accounting.description')}
              onConnect={handleAccountingModal}
              isConnected={erpAccounts.length > 0}
            >
              <ConnectedAccounts
                isLoading={isLoading}
                accounts={erpAccounts}
                image={<img src={imageLabel} alt="label" />}
                onUpdate={handleSelectedAccounts}
              />
            </NotConnected>
          </PermissionSection>

          <PermissionSection
            permission={Permissions.bankIntegration}
            showMessage
          >
            <NotConnected
              image={
                <FinanceIcon
                  styles={{
                    color: textColor.primary.base,
                  }}
                />
              }
              title={t('dashboard:connectors.bank.connect')}
              text={t('dashboard:connectors.bank.description')}
              onConnect={handleBankAccountModal}
              isConnected={bankAccounts.length > 0}
            >
              <ConnectedBankAccount
                accounts={bankAccounts}
                onConnect={handleBankAccountModal}
                setIsOpened={handleBankAccountInfoModal}
              />
            </NotConnected>
          </PermissionSection>
        </div>
      )}
    </div>
  );
};

export default CompleteSmarter;
