import { useEffect } from 'react';

import { getOnboardingTabs, OnboardingTab } from 'pages/dashboard/utils';
import { setOnboardingTab } from 'pages/dashboard/dashboardSlice';
import { getOnboardingTab } from 'pages/dashboard/selectors';
import { useAppDispatch, useAppSelector } from 'hooks';
import Tabs from 'components/Tabs';
import {
  ShowErrorsProps,
  ProcentByTabProps,
  ProductTypeCodes,
} from 'entities/progress';
import {
  getActiveProductsTypeCodes,
  getTabProgresses,
  getShowErrors,
  getNewProductType,
} from 'pages/dashboard/components/Progress/selectors';
import {
  clearNewProductType,
  setShowErrors,
} from 'pages/dashboard/components/Progress/progressSlice';

export default function OnboardingTabs() {
  const dispatch = useAppDispatch();

  const currentTab: number = useAppSelector(getOnboardingTab);
  const tabProgress: ProcentByTabProps = useAppSelector(getTabProgresses);
  const showErrors: ShowErrorsProps = useAppSelector(getShowErrors);
  const newProductType: string = useAppSelector(getNewProductType);
  const activeProductTypes: ProductTypeCodes[] = useAppSelector(
    getActiveProductsTypeCodes,
  );

  const tabList: OnboardingTab[] = getOnboardingTabs(
    { tabProgress, showErrors },
    currentTab,
  );

  const activeTabs = tabList.filter((tab: OnboardingTab) =>
    activeProductTypes.includes(tab.product),
  );

  useEffect(() => {
    if (!activeProductTypes.includes(activeTabs[currentTab]?.product)) {
      dispatch(setOnboardingTab(0));
    }
  }, [activeTabs, activeProductTypes, currentTab, dispatch]);

  useEffect(() => {
    if (newProductType) {
      const tabIndex = activeTabs.findIndex(
        (tab: OnboardingTab) =>
          tab[newProductType] && tab.progress && tab.progress.progress !== 100,
      );

      if (tabIndex >= 0) {
        dispatch(setOnboardingTab(tabIndex));
      }

      dispatch(clearNewProductType());
    }
  }, [newProductType, dispatch, activeTabs]);

  const handleChange = (value: number) => {
    dispatch(setOnboardingTab(value));
    dispatch(setShowErrors({ [activeTabs[currentTab]?.code || '']: true }));
  };

  return (
    <div id="onboarding-tabs" className="bg-white shadow rounded-lg">
      <Tabs
        tabs={activeTabs}
        currentTab={currentTab}
        onChange={handleChange}
        className="px-6 pt-4 border-b border-gray-200 space-x-8"
        tabClassName="border-b-2"
        footerNavigation
      >
        {activeTabs[currentTab]?.component || null}
      </Tabs>
    </div>
  );
}
