import { OwnerTypes } from 'entities/ownership';
import { getCountryCallingCodeAndNationalNumber } from 'utils/phone';
import * as yup from 'yup';
import moment from 'moment';

import { calcProcentege } from 'pages/dashboard/utils';
import { CountryISOCodes } from 'common/countries';

export const getOwnersPersonSchema = (t, totalShare) =>
  yup
    .object()
    .shape({
      ownershipPercentage: yup
        .number()
        .typeError(
          t('ownership:fields.ownershipPercentage.validation.required'),
        )
        .min(
          0.01,
          t('ownership:fields.ownershipPercentage.validation.min', {
            value: 0,
          }),
        )
        .max(
          100 - totalShare,
          t('ownership:fields.ownershipPercentage.validation.total'),
        )
        .required(
          t('ownership:fields.ownershipPercentage.validation.required'),
        ),
      givenName1: yup
        .string()
        .trim()
        .required(t('common:fields.firstname.validation.required')),
      surnameFirst: yup
        .string()
        .trim()
        .required(t('common:fields.lastname.validation.required')),
      dateOfBirth: yup
        .date()
        .typeError(t('ownership:fields.dateOfBirth.validation.valid'))
        .test(
          'dateOfBirth',
          t('ownership:fields.dateOfBirth.validation.valid'),
          (value) => {
            const years = moment().diff(moment(value), 'years');
            return years > 16 && years < 120;
          },
        )
        .required(t('ownership:fields.dateOfBirth.validation.required')),
      countryIsocode: yup
        .string()
        .trim()
        .required(t('common:fields.country.validation.required')),
      addressLine1: yup
        .string()
        .trim()
        .required(t('common:fields.address.validation.required')),
      city: yup
        .string()
        .trim()
        .required(t('common:fields.city.validation.required')),
      postalCode: yup
        .string()
        .trim()
        .required(t('common:fields.postal.validation.required')),
      stateOrProvince: yup
        .string()
        .trim()
        .required(t('common:fields.province.validation.required')),
      emailAddress: yup
        .string()
        .trim()
        .required(t('common:fields.email.validation.required'))
        .email(t('common:fields.email.validation.valid')),
      phone: yup
        .string()
        .trim()
        .required(t('common:fields.phone.validation.required')),
      idnumber: yup
        .string()
        .transform((value, originalValue) =>
          originalValue == null ? '' : value,
        )
        .trim()
        .when('countryIsocode', {
          is: (countryIsocode) => countryIsocode !== CountryISOCodes.Canada,
          then: yup
            .string()
            .required(t('ownership:fields.idnumber.validation.required'))
            .length(11, t('ownership:fields.idnumber.validation.length')),
          otherwise: yup.string().notRequired(),
        }),
    })
    .required();

export const getOwnersBusinessSchema = (t, totalShare) =>
  yup
    .object()
    .shape({
      businessLegalName: yup
        .string()
        .trim()
        .required(t('common:fields.businessLegalName.validation.required')),
      ownershipPercentage: yup
        .number()
        .typeError(
          t('ownership:fields.ownershipPercentage.validation.required'),
        )
        .min(
          0.01,
          t('ownership:fields.ownershipPercentage.validation.min', {
            value: 0,
          }),
        )
        .max(
          100 - totalShare,
          t('ownership:fields.ownershipPercentage.validation.total'),
        )
        .required(
          t('ownership:fields.ownershipPercentage.validation.required'),
        ),
      addressLine1: yup
        .string()
        .trim()
        .required(t('common:fields.address.validation.required')),
      city: yup
        .string()
        .trim()
        .required(t('common:fields.city.validation.required')),
      postalCode: yup
        .string()
        .trim()
        .required(t('common:fields.postal.validation.required')),
      stateOrProvince: yup
        .string()
        .trim()
        .required(t('common:fields.province.validation.required')),
      countryIsocode: yup
        .string()
        .trim()
        .required(t('common:fields.country.validation.required')),
      emailAddress: yup
        .string()
        .trim()
        .email(t('common:fields.email.validation.valid'))
        .nullable(),
      phone: yup
        .string()
        .trim()
        .required(t('common:fields.phone.validation.required')),
      countryofIncorporationIsocode: yup
        .string()
        .trim()
        .required(t('common:fields.country.validation.required')),
    })
    .required();

export const getMappedOwner = (owner) => ({
  ...owner,
  addressLine2: owner.addressLine2 ? owner.addressLine2.trim() : '',
  addressLine3: owner.addressLine3 ? owner.addressLine3.trim() : '',
  emailAddress: owner.emailAddress ? owner.emailAddress.trim() : '',
  type: owner.businessLegalName ? OwnerTypes.business : OwnerTypes.person,
  dateOfBirth: owner.dateOfBirth ? new Date(owner.dateOfBirth) : null,
  phone: `${owner.countryCallingCode || ''}${
    owner.telephoneNumber || owner.businessContactNumber || ''
  }`,
});

export const getMappedOwners = (owners) =>
  owners.map((owner) => getMappedOwner(owner));

export const getMappedOwnerPersonFields = ({ data, business }) => {
  const { countryCallingCode, nationalNumber } =
    getCountryCallingCodeAndNationalNumber(data.phone);

  return {
    ...data,
    isActive: true,
    businessesId: business.businessesId,
    countryCallingCode: countryCallingCode,
    telephoneNumber: nationalNumber,
  };
};

export const getFormFields = (values) => {
  const fields = {
    ownershipPercentage: values.ownershipPercentage,
    countryIsocode: values.countryIsocode,
    addressLine1: values.addressLine1,
    city: values.city,
    postalCode: values.postalCode,
    stateOrProvince: values.stateOrProvince,
    phone: values.phone,
  };

  if (values.type === OwnerTypes.person) {
    const personFields: any = {
      ...fields,
      givenName1: values.givenName1,
      surnameFirst: values.surnameFirst,
      dateOfBirth: values.dateOfBirth,
      emailAddress: values.emailAddress,
      idfrontGuid: values.idfrontGuid,
    };

    if (fields.countryIsocode === CountryISOCodes.USA) {
      personFields.idnumber = values.idnumber;
    }

    return personFields;
  }

  if (values.type === OwnerTypes.business) {
    return {
      ...fields,
      businessLegalName: values.businessLegalName,
      countryofIncorporationIsocode: values.countryofIncorporationIsocode,
    };
  }

  return fields;
};

export const getMappedCreateOwnerPersonFields = ({
  data,
  person,
  business,
}) => {
  const fields = getMappedOwnerPersonFields({ data, business });

  return {
    ...fields,
    createdBy: person.emailAddress,
  };
};

export const getMappedUpdateOwnerPersonFields = ({
  data,
  person,
  business,
}) => {
  const fields = getMappedOwnerPersonFields({ data, business });

  return {
    ...fields,
    addressLine2: data.addressLine2 || ' ',
    modifiedBy: person.emailAddress,
    businessesOwnersPersonsId: data.businessesOwnersPersonsId,
  };
};

export const getMappedOwnerBusinessFields = ({ data, business }) => {
  const { countryCallingCode, nationalNumber } =
    getCountryCallingCodeAndNationalNumber(data.phone);

  return {
    isActive: true,
    businessesId: business.businessesId,
    businessLegalName: data.businessLegalName,
    ownershipPercentage: data.ownershipPercentage,
    addressLine1: data.addressLine1,
    addressLine2: data.addressLine2,
    addressLine3: data.addressLine3,
    city: data.city,
    postalCode: data.postalCode,
    stateOrProvince: data.stateOrProvince,
    countryIsocode: data.countryIsocode,
    businessContactName: data.countryIsocode,
    emailAddress: data.emailAddress,
    countryCallingCode: countryCallingCode,
    businessContactNumber: nationalNumber,
    countryofIncorporationIsocode: data.countryofIncorporationIsocode,
    noDUNSListed: true,
  };
};

export const getMappedCreateOwnerBusinessFields = ({
  data,
  person,
  business,
}) => {
  const fields = getMappedOwnerBusinessFields({ data, business });

  return {
    createdBy: person.emailAddress,
    ...fields,
  };
};

export const getMappedUpdateOwnerBusinessFields = ({
  data,
  person,
  business,
}) => {
  const fields = getMappedOwnerBusinessFields({ data, business });

  return {
    ...fields,
    addressLine2: data.addressLine2 || ' ',
    addressLine3: data.addressLine3 || ' ',
    emailAddress: data.emailAddress || ' ',
    modifiedBy: person.emailAddress,
    businessesOwnersBusinessesId: data.businessesOwnersBusinessesId,
  };
};

export const getMappedCreateBlobReferenceFields = ({
  file,
  person,
  business,
  blobReferencesTypesId,
}) => {
  const fileName = file.name.split('.');

  const formData = new FormData();

  formData.append('isActive', 'true');
  formData.append('createdBy', person.emailAddress);
  formData.append('businessesId', business.businessesId.toString());
  formData.append('blobReferencesTypesId', blobReferencesTypesId.toString());
  formData.append('blobFileSize', file.size.toString());
  formData.append('blobFileName', file.name);
  formData.append('blobFileExtension', fileName[1]);
  formData.append('file', file);

  return formData;
};

export const calcOwnershipPercentage = (
  { owners, hasFile },
  singleOwner25Percent,
) => {
  const owner = owners.find(
    (item) => item.isActive && item.ownershipPercentage >= 20,
  );

  const fields =
    singleOwner25Percent === false ? {} : getFormFields(owner || {});

  return calcProcentege({ ...fields, hasFile });
};

export const getMappedOwnerBlobReferences = (blobs) =>
  blobs.map((blob) => ({
    ...blob,
    id: blob.businessesOwnersBlobReferencesId,
  }));
