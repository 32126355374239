export interface ApplicantProps {
  applicantName: string;
  applicantEmail: string;
  applicantPhone: string;
  applicantRole: string;
}

export interface ERPPreferenceProps {
  erppreferenceDataId: number;
  businessesId: number;
  isActive: boolean;
  requiresDigitalSignature: boolean;
  paymentTypeText: string;
  paymentMessage: string;
  countryCallingCode: string;
  telephoneNumber: string;
  contactEmailAddress: string;
  paymentMessageHeaderText: string;
  signatureHeaderText: string;
  documentsHeaderText: string;
  documentMessage: string;
  dateSentToErp: string;
  tokenOid: string;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
}

export interface BusinessDocument {
  businessesDocumentsId: 0;
  createdOn: string;
  createdBy: string;
  createdBySp: string;
  modifiedOn: string;
  modifiedBy: string;
  modifiedBySp: string;
  isActive: boolean;
  businessesId: number;
  businessesDocumentsTypesId: number;
  idnumber: number;
  effectiveDate: string;
  expirationDate: string;
  issuedBy: string;
  documentsBlobId: number;
}

export interface BusinessDocumentType {
  businessesDocumentsTypesId: number;
  isActive: boolean;
  description: string;
  createdOn?: string;
  createdBy?: string;
  createdBySp?: string;
  modifiedOn?: string;
  modifiedBy?: string;
  modifiedBySp?: string;
}

export interface BusinessInfoState {
  documents: BusinessDocument[];
  applicant: ApplicantProps;
  erpPreferences: ERPPreferenceProps | null;
  isFormUpdate: boolean;
  showDoingBusinessAs: boolean;
  loadingProgress: number;
}

export enum BusinessDocumentTypes {
  federalTaxId = 1,
  dbaStatement = 2,
  centralIndexKey = 3,
}
