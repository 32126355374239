import { FieldValues, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';

import { closeDialog, openDialog } from 'components/dialog/modalSlice';
import DangerDialog from 'components/dialog/templates/DangerDialog';
import ApplicationStatus from 'components/ApplicationStatus';
import { hasPermission } from 'pages/dashboard/selectors';
import { useAppSelector, useAppDispatch } from 'hooks';
import { ComplianceStatuses } from 'common/compliance';
import { getBusiness } from 'pages/Profile/selectors';
import { Permissions } from 'entities/dashboard';
import { FileDoneIcon } from 'components/icons';
import { Input } from 'components/Inputs';
import Modal from 'components/Modal';
import Tabs from 'components/Tabs';

import { setIsModalOpened } from '../complianceSlice';
import RequestInfoTab from './RequestInfoTab';
import ModalActions from './ModalActions';
import {
  suspendCompliance,
  approveCompliance,
  declineCompliance,
  activateCompliance,
  fetchBusinessRequests,
} from '../thunks';
import {
  getIsModalOpened,
  getComplianceInfo,
  getRequestsTotal,
} from '../selectors';

const allowUpdate = ['complianceReference'];

const ComplianceModal = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation('dashboard');

  const isModalOpened: boolean = useAppSelector(getIsModalOpened);
  const requestsTotal: number = useAppSelector(getRequestsTotal);
  const complianceInfo = useAppSelector(getComplianceInfo);
  const { applicationStatusTypeCode } = useAppSelector(getBusiness);
  const compliancePermissions = useAppSelector((state) =>
    hasPermission(state, Permissions.compliance),
  );

  const [currentTab, setCurrentTab] = useState<number>(0);

  const disabled =
    complianceInfo.complianceStatusID &&
    complianceInfo.complianceStatusID !== ComplianceStatuses.pending;

  const { register, getValues, reset } = useForm<FieldValues>({
    defaultValues: complianceInfo,
  });

  useEffect(() => {
    reset(complianceInfo);
  }, [complianceInfo, reset]);

  useEffect(() => {
    if (isModalOpened) {
      dispatch(fetchBusinessRequests());
    }
  }, [isModalOpened, dispatch]);

  const getReference = () => {
    const values = getValues();
    const { complianceReference } = values;

    return complianceReference;
  };

  const closeConfirmationDialog = () => {
    dispatch(closeDialog());
  };

  const confirmAction = (action, button) => {
    dispatch(
      openDialog({
        maxWidth: '512px',
        onClose: closeConfirmationDialog,
        onCancel: closeConfirmationDialog,
        content: (
          <DangerDialog
            title={t('common:confirmationText')}
            buttonText={t(`compliance.${button}`)}
            onCancel={closeConfirmationDialog}
            onConfirm={() => {
              const complianceReference = getReference();
              dispatch(action(complianceReference));
              closeConfirmationDialog();
            }}
          />
        ),
      }),
    );
  };

  const handleSuspend = () => {
    confirmAction(suspendCompliance, 'suspend');
  };

  const handleApprove = () => {
    confirmAction(approveCompliance, 'approve');
  };

  const handleDecline = () => {
    confirmAction(declineCompliance, 'decline');
  };

  const handleActivate = () => {
    confirmAction(activateCompliance, 'reActivate');
  };

  const handleClose = () => {
    dispatch(setIsModalOpened(false));
  };

  const tabs = [
    {
      name: t('dashboard:compliance.clientTab'),
    },
    {
      name: t('dashboard:compliance.requestTab'),
      badge: `${requestsTotal || ''}`,
    },
  ];

  return (
    <Modal
      isOpen={isModalOpened}
      closeModal={handleClose}
      title={
        <div className="flex items-center">
          <FileDoneIcon className="text-gray-500" />
          <span className="mx-2.5">{t('dashboard:compliance.title')}</span>
          <ApplicationStatus status={applicationStatusTypeCode} />
        </div>
      }
      actions={
        <ModalActions
          handleApprove={handleApprove}
          handleSuspend={handleSuspend}
          handleDecline={handleDecline}
          handleActivate={handleActivate}
          status={applicationStatusTypeCode}
        />
      }
      height="100%"
    >
      <Tabs
        tabs={tabs}
        currentTab={currentTab}
        onChange={(index) => setCurrentTab(index)}
        className="px-6 pt-2 border-b border-gray-200 space-x-8"
        tabClassName="border-b-2"
      >
        {currentTab === 0 && (
          <div className="h-full px-3 py-6 flex flex-wrap">
            {Object.keys(complianceInfo).map((key) => (
              <Input
                key={key}
                label={key}
                name={key}
                className="mb-6 w-1/2 px-3"
                register={register}
                readOnly={
                  disabled ||
                  !allowUpdate.includes(key) ||
                  !compliancePermissions.edit
                }
              />
            ))}
          </div>
        )}
        {currentTab === 1 && <RequestInfoTab />}
      </Tabs>
    </Modal>
  );
};

export default ComplianceModal;
