import { FieldValues, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useMsal } from '@azure/msal-react';
import { equals } from 'ramda';

import { useAppDispatch, useAppSelector, useCurrentUser } from 'hooks';
import ErrorMessage from 'components/Inputs/components/ErrorMessage';
import { ChevronRightIcon } from 'components/icons/ChevronRightIcon';
import { USER_WITHOUT_OID, USER_WITH_OID } from 'services/persons';
import { Select, Input, PhoneInput } from 'components/Inputs';
import { ExsitingEmailParams } from 'entities/profile';
import HideComponent from 'components/HideComponent';
import AddressState from 'components/AddressState';
import { verifyPerson } from 'pages/login/thunks';
import { setData } from 'pages/login/loginSlice';
import { getOID } from 'utils/authService';
import Loading from 'components/Loading';
import Button from 'components/Button';
import { loginRequest } from 'config';
import Link from 'components/Link';
import path from 'common/path';

import TermsAndConditionCheckBox from './TermsAndConditionCheckBox';
import { getBusinessInfoSchema, onboardingSteps } from '../utils';
import { saveBusiness, fetchCountries } from '../thunks';
import { setStep } from '../profileSlice';
import {
  setBusinessAddress,
  addExistingEmail,
  clearExistingEmail,
  setFormUpdateState,
} from '../profileSlice';
import {
  getIsAutoAdmin,
  getBusiness,
  getBusinessInfo,
  getCountries,
  getIsFormUpdate,
  getIsLoadingAddresses,
  getBusinessesAddress,
  getSelectedCountry,
  getExistingEmails,
  getBusinessesAdmin,
  getPerson,
} from '../selectors';

enum Fields {
  address = 'addressLine1',
  city = 'city',
  country = 'countryIsocode',
  code = 'postalCode',
  state = 'stateOrProvince',
  name = 'businessLegalName',
  email = 'emailAddress',
  phone = 'phone',
}

const BusinessProfileInformation = () => {
  const { t } = useTranslation('profile');
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();

  const isAutoAdmin = useAppSelector(getIsAutoAdmin);
  const bussinessProfileInfo = useAppSelector(getBusinessInfo);
  const businessAddress = useAppSelector(getBusinessesAddress);
  const countries = useAppSelector(getCountries);
  const isLoadingAddresses = useAppSelector(getIsLoadingAddresses);
  const isFormUpdate = useAppSelector(getIsFormUpdate);
  const selectedCountry = useAppSelector(getSelectedCountry);
  const existingEmails = useAppSelector(getExistingEmails);
  const businessAdmin = useAppSelector(getBusinessesAdmin);
  const person = useAppSelector(getPerson);
  const { businessesId } = useAppSelector(getBusiness);

  const { isAuthenticated } = useCurrentUser();
  const oid = getOID();

  const [isTerms, setTerms] = useState<boolean>(false);

  const {
    register,
    control,
    handleSubmit,
    setValue,
    setError,
    reset,
    watch,
    clearErrors,
    formState: { errors, isSubmitting },
  } = useForm<FieldValues>({
    defaultValues: bussinessProfileInfo,
    resolver: yupResolver(getBusinessInfoSchema(t)),
  });

  const emailAddress = watch('emailAddress');

  const emailExists: ExsitingEmailParams | undefined = existingEmails.find(
    (existingEmail: ExsitingEmailParams) =>
      existingEmail.email === emailAddress.trim(),
  );

  useEffect(() => {
    if (emailExists) {
      setError('emailAddress', {
        type: 'manual',
        message: '',
      });
    } else {
      clearErrors('emailAddress');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [emailAddress, existingEmails]);

  useEffect(() => {
    if (isFormUpdate) {
      reset(bussinessProfileInfo);
      dispatch(setFormUpdateState());
    }
  }, [
    bussinessProfileInfo,
    isFormUpdate,
    existingEmails,
    reset,
    dispatch,
    setError,
  ]);

  useEffect(() => {
    if (!businessAddress.countryIsocode && selectedCountry?.value) {
      dispatch(
        setBusinessAddress({
          ...businessAddress,
          country: selectedCountry.name,
          countryIsocode: selectedCountry.value,
          alpha2Code: selectedCountry.alpha2Code,
        }),
      );
      setValue(Fields.country, selectedCountry.value);
    }
  }, [selectedCountry, businessAddress, dispatch, setValue]);

  useEffect(() => {
    if (!isLoadingAddresses && !countries.length) {
      dispatch(fetchCountries());
    }
  }, [isLoadingAddresses, countries, dispatch]);

  const handleTermsAndCondition = () => {
    setTerms(!isTerms);
  };

  const handleLogin = () => {
    if (emailExists?.hasOID) {
      instance.loginRedirect(loginRequest);
    } else {
      dispatch(setData({ email: emailExists?.email }));
      dispatch(clearExistingEmail());
      navigate(path.login);
    }
  };

  const onSubmit = async (data: any) => {
    if (equals(bussinessProfileInfo, data)) {
      dispatch(setStep(onboardingSteps.dnb));
      return;
    }

    data.isAuthenticated = isAuthenticated || oid;

    if (!isAuthenticated && !oid) {
      const result = await dispatch(verifyPerson({ email: data.emailAddress }));

      if (result.payload === USER_WITH_OID) {
        dispatch(addExistingEmail({ email: data.emailAddress, hasOID: true }));
        return;
      } else if (
        (result.payload === USER_WITHOUT_OID && !businessesId) ||
        (Array.isArray(result.payload) && result.payload.length > 0)
      ) {
        dispatch(addExistingEmail({ email: data.emailAddress, hasOID: false }));
        return;
      }
    }

    await dispatch(saveBusiness(data));
  };

  const handleCountryOnChange = ({ name, value, alpha2Code }) => {
    dispatch(
      setBusinessAddress({
        ...businessAddress,
        country: name,
        countryIsocode: value,
        alpha2Code: alpha2Code,
      }),
    );
    setValue(Fields.country, value);
  };

  const countryISOCode = watch('countryIsocode');

  if (isLoadingAddresses) {
    return <Loading />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="divide-y divide-gray-200"
    >
      <div>
        <h2 className="heading-2xl sm:heading-4xl mb-4">
          {t('profile:bussinessProfile.title')}
        </h2>

        <h6 className="heading-lg mb-2 capitalize">
          {t('profile:bussinessProfile.subTitle')}
        </h6>
        <p className="text-secondary mb-5">
          {t('profile:bussinessProfile.descriptionInfo')}
        </p>
      </div>

      <div>
        <div className="w-full sm:w-1/2 my-4 sm:pr-3">
          <Input
            label={t('profile:bussinessProfile.name.label')}
            name={Fields.name}
            error={errors?.[Fields.name]}
            register={register}
          />
        </div>

        <div className="w-full mb-4">
          <Input
            label={t('profile:bussinessProfile.address.label')}
            name={Fields.address}
            error={errors?.[Fields.address]}
            register={register}
          />
        </div>

        <div className="mb-4 w-full sm:w-1/2 sm:pr-3">
          <Select
            label={t('profile:bussinessProfile.country.label')}
            name={Fields.country}
            onChange={handleCountryOnChange}
            value={selectedCountry}
            error={errors?.[Fields.country]}
            register={register}
            setValue={setValue}
            options={countries}
            withIcon
          />
        </div>

        <div className="flex flex-wrap">
          <div className="w-full sm:w-1/2 mb-4 sm:pr-3">
            <Input
              label={t('profile:bussinessProfile.city.label')}
              name={Fields.city}
              error={errors?.[Fields.city]}
              register={register}
            />
          </div>

          <div className="w-full sm:w-1/2 mb-4 sm:pl-3">
            <AddressState
              label={t('profile:bussinessProfile.state.label')}
              name={Fields.state}
              countryISOCode={countryISOCode}
              value={bussinessProfileInfo.stateOrProvince}
              error={errors?.[Fields.state]}
              register={register}
              setValue={setValue}
            />
          </div>
        </div>

        <div className="mb-4 w-full sm:w-1/2 sm:pr-3">
          <Input
            label={t('profile:bussinessProfile.code.label')}
            name={Fields.code}
            error={errors?.[Fields.code]}
            register={register}
          />
        </div>

        <div className="flex flex-wrap pb-8">
          <div className="w-full sm:w-1/2 mb-4 sm:pr-3">
            <Input
              label={t('profile:personInformation.businessEmail.label')}
              info={t('profile:personInformation.businessEmail.info')}
              placeholder={t(
                'profile:personInformation.businessEmail.placeholder',
              )}
              name={Fields.email}
              register={register}
              error={errors?.[Fields.email] || emailExists}
              readOnly={
                businessesId || ((isAuthenticated || oid) && !isAutoAdmin)
              }
            />
            <HideComponent show={!!emailExists}>
              <ErrorMessage
                error={{
                  message: (
                    <span>
                      {t(
                        'profile:personInformation.businessEmail.validation.exists',
                      )}{' '}
                      <Link onClick={handleLogin}>Login</Link> using this email
                      instead.
                    </span>
                  ),
                }}
              />
            </HideComponent>
          </div>

          <div className="w-full sm:w-1/2 mb-4 sm:pl-3">
            <PhoneInput
              label={t('profile:bussinessProfile.phone.label')}
              name={Fields.phone}
              error={errors?.[Fields.phone]}
              control={control}
              readOnly={
                businessAdmin.emailAddress &&
                businessAdmin.emailAddress !== person.emailAddress
              }
            />
          </div>
        </div>

        <TermsAndConditionCheckBox
          state={isTerms}
          handleChange={handleTermsAndCondition}
        />
      </div>

      <div className="pt-6 flex flex-wrap justify-center sm:justify-end items-center">
        <p className="text-sm text-gray-500 text-right sm:mr-6 mb-6 sm:mb-0">
          {t('profile:bussinessProfile.validateComponyDetails')}
        </p>
        <Button
          type="submit"
          disabled={!isTerms || !countries.length || emailExists}
          loading={isSubmitting}
          className="w-full sm:max-w-[200px]"
          heightClass="h-11"
        >
          {t('common:nextStep')}
          <ChevronRightIcon className="ml-3" />
        </Button>
      </div>
    </form>
  );
};

export default BusinessProfileInformation;
