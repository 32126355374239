import Radium from 'radium';

export const InfoCircleIcon = Radium(
  ({ className, styles }: { className?: string; styles?: any }) => (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={styles}
      className={className}
    >
      <path
        opacity="0.3"
        d="M10.0003 18.3346C14.6027 18.3346 18.3336 14.6037 18.3336 10.0013C18.3336 5.39893 14.6027 1.66797 10.0003 1.66797C5.39795 1.66797 1.66699 5.39893 1.66699 10.0013C1.66699 14.6037 5.39795 18.3346 10.0003 18.3346Z"
        fill="#6B7280"
      />
      <path
        d="M10.8337 9.16536C10.8337 8.70513 10.4606 8.33203 10.0003 8.33203C9.54009 8.33203 9.16699 8.70513 9.16699 9.16536V13.332C9.16699 13.7923 9.54009 14.1654 10.0003 14.1654C10.4606 14.1654 10.8337 13.7923 10.8337 13.332V9.16536Z"
        fill="#6B7280"
      />
      <path
        d="M10.8337 6.66536C10.8337 6.20513 10.4606 5.83203 10.0003 5.83203C9.54009 5.83203 9.16699 6.20513 9.16699 6.66536C9.16699 7.1256 9.54009 7.4987 10.0003 7.4987C10.4606 7.4987 10.8337 7.1256 10.8337 6.66536Z"
        fill="#6B7280"
      />
    </svg>
  ),
);
