import { parsePhoneNumber } from 'react-phone-number-input';
import { whereEq } from 'ramda';

import { BusinessDocument, BusinessDocumentTypes } from 'entities/businessInfo';
import { CountryCallingCodes } from 'components/Inputs/PhoneInput';
import { calcProcentege } from 'pages/dashboard/utils';
import { trimStr } from 'utils/helpers';

const mapApplicantData = (applicant) => ({
  applicantName: `${applicant.givenName1} ${applicant.surnameFirst}`,
  applicantEmail: applicant.emailAddress,
  applicantPhone: `${applicant.personCountryCallingCode || ''}${
    applicant.personTelephoneNumber || ''
  }`,
  applicantRole: applicant.businessesPersonsRole,
});

const getNumberByDocumentTypeId = (id, documents) => {
  const document = documents.find(
    (doc: BusinessDocument) => doc.businessesDocumentsTypesId === id,
  );
  return document?.idnumber || '';
};

const mapBusinessDocuments = (documents) =>
  documents.map((doc: BusinessDocument) => ({
    ...doc,
    id: doc.businessesDocumentsId,
  }));

const getUpdateBusinessFields = ({ data, showDoingBusinessAs, business }) => {
  const {
    businessLegalName,
    businessEmailAddress,
    businessesLegalEntityFormTypesId,
    doingBusinessAs,
    dunsnumber,
    primaryURL,
  } = data;

  const fields = {
    businessLegalName,
    emailAddress: businessEmailAddress,
    businessesLegalEntityFormTypesId,
    doingBusinessAs: showDoingBusinessAs ? doingBusinessAs : '',
    dunsnumber,
    primaryURL,
  };

  return whereEq(fields, {
    ...business,
    doingBusinessAs: business.doingBusinessAs || '',
  })
    ? null
    : { ...fields, emailAddress: businessEmailAddress };
};

export const getMappedBusinessInfo = (businessInfo, profile) => {
  const { documents, applicant, erpPreferences } = businessInfo;
  const { business, businessAddress, businessTelephoneNumber } = profile;

  const {
    businessLegalName,
    emailAddress,
    businessesLegalEntityFormTypesId,
    doingBusinessAs,
    dunsnumber,
    primaryURL,
  } = business;
  const { applicantName, applicantEmail, applicantPhone, applicantRole } =
    applicant;
  const {
    city,
    countryIsocode,
    postalCode,
    stateOrProvince,
    addressLine1,
    addressLine2,
  } = businessAddress;

  const businessDocument = documents.find(
    (document) =>
      document.businessesDocumentsTypesId !==
        BusinessDocumentTypes.centralIndexKey &&
      document.businessesDocumentsTypesId !==
        BusinessDocumentTypes.dbaStatement,
  );

  return {
    businessLegalName,
    businessEmailAddress: emailAddress,
    businessesLegalEntityFormTypesId,
    doingBusinessAs: trimStr(doingBusinessAs),
    dunsnumber,
    idNumber: businessDocument?.idnumber || '',
    businessesDocumentsTypesId:
      businessDocument?.businessesDocumentsTypesId || '',
    centralIndexKey: getNumberByDocumentTypeId(
      BusinessDocumentTypes.centralIndexKey,
      documents,
    ),
    dbaFile: documents.find(
      (doc) =>
        doc.businessesDocumentsTypesId === BusinessDocumentTypes.dbaStatement,
    ),
    city,
    addressLine1,
    addressLine2,
    countryIsocode,
    postalCode,
    stateOrProvince,
    phone: `${businessTelephoneNumber.countryCallingCode || ''}${
      businessTelephoneNumber.telephoneNumber || ''
    }`,
    applicantName,
    applicantEmail,
    applicantPhone,
    applicantRole,
    primaryURL,
    contactEmailAddress: erpPreferences?.contactEmailAddress
      ? erpPreferences.contactEmailAddress.trim()
      : '',
    contactTelephoneNumber:
      erpPreferences?.countryCallingCode && erpPreferences?.telephoneNumber
        ? `${erpPreferences.countryCallingCode}${erpPreferences.telephoneNumber}`.trim()
        : '',
  };
};

export const getUpdateBusinessERPPreferences = ({ data, erpPreferences }) => {
  const parsePhone = parsePhoneNumber(data.contactTelephoneNumber);

  const fields: any = {
    contactEmailAddress: data.contactEmailAddress || '',
    countryCallingCode: parsePhone ? `+${parsePhone.countryCallingCode}-` : '',
    telephoneNumber: parsePhone?.nationalNumber || '',
  };

  const erpFields: any = {
    contactEmailAddress: erpPreferences?.contactEmailAddress || '',
    countryCallingCode: erpPreferences?.countryCallingCode || '',
    telephoneNumber: erpPreferences?.telephoneNumber || '',
  };

  return erpPreferences && whereEq(fields, erpFields) ? null : fields;
};

const getUpdateBusinessPhoneFields = ({ data, businessTelephoneNumber }) => {
  const parsePhone = parsePhoneNumber(data.phone);

  if (parsePhone && parsePhone.countryCallingCode === CountryCallingCodes.US) {
    const fields = {
      countryCallingCode: `+${parsePhone.countryCallingCode}-`,
      telephoneNumber: `${parsePhone.nationalNumber || ''}`,
    };

    return whereEq(fields, businessTelephoneNumber)
      ? null
      : { ...fields, telephoneNumber1: fields.telephoneNumber };
  }

  return null;
};

export const getAddDocumentFields = ({
  person,
  business,
  file,
  businessesDocumentsTypesId,
}) => {
  const formData = new FormData();
  const fileName = file.name.split('.');

  formData.append('file', file);
  formData.append('isActive', 'true');
  formData.append('createdBy', person.emailAddress);
  formData.append('businessesId', business.businessesId.toString());
  formData.append('blobFileName', file.name);
  formData.append('blobFileExtension', fileName[1]);
  formData.append('blobFileSize', file.size.toString());
  formData.append(
    'businessesDocumentsTypesId',
    businessesDocumentsTypesId.toString(),
  );

  return formData;
};

const checkBusinessInfoSuccess = (data) => {
  const {
    businessAddress,
    documents,
    business,
    businessTelephoneNumber,
    showDoingBusinessAs,
  } = data;

  const { city, postalCode, stateOrProvince, addressLine1 } = businessAddress;
  const document = documents.find(
    (doc) =>
      doc.businessesDocumentsTypesId === BusinessDocumentTypes.dbaStatement,
  );

  if (
    !business.businessLegalName ||
    !business.emailAddress ||
    !addressLine1 ||
    !city ||
    !stateOrProvince ||
    !postalCode ||
    !businessTelephoneNumber.telephoneNumber ||
    (showDoingBusinessAs && (!business.doingBusinessAs || !document))
  ) {
    return false;
  }

  return true;
};

export const calcBusinessInfoPercentage = (data) => {
  const {
    addressLine2,
    centralIndexKey,
    dunsnumber,
    applicantEmail,
    applicantName,
    applicantPhone,
    applicantRole,
    doingBusinessAs,
    dbaFile,
    ...requiredFields
  } = data;

  const fields = doingBusinessAs
    ? { ...requiredFields, dbaFile }
    : requiredFields;

  return calcProcentege(fields);
};

const getUpdateBusinessAddressFields = ({ data, businessAddress }) => {
  const {
    addressLine1,
    addressLine2,
    city,
    countryIsocode,
    postalCode,
    stateOrProvince,
  } = data;

  const fields = {
    addressLine1,
    addressLine2,
    city,
    countryIsocode,
    postalCode,
    stateOrProvince,
  };

  return whereEq(fields, businessAddress) ? null : fields;
};

export {
  mapApplicantData,
  mapBusinessDocuments,
  getNumberByDocumentTypeId,
  getUpdateBusinessFields,
  getUpdateBusinessAddressFields,
  getUpdateBusinessPhoneFields,
  checkBusinessInfoSuccess,
};
