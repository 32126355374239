import { BrowserRouter } from 'react-router-dom';
import { I18nextProvider } from 'react-i18next';

import Notification from 'components/notification/Notification';
import ApiInterceptors from 'components/ApiInterceptors';
import ErrorBoundary from 'components/ErrorBundery';
import ThemeProvider from 'components/ThemeProvider';
import AuthWrapper from 'components/AuthWrapper';
import AppRoutes from 'Routes';
import i18n from 'i18n';

const App = () => {
  return (
    <BrowserRouter>
      <AuthWrapper>
        <ThemeProvider>
          <ErrorBoundary>
            <I18nextProvider i18n={i18n}>
              <AppRoutes />
              <Notification />
            </I18nextProvider>
          </ErrorBoundary>
        </ThemeProvider>
        <ApiInterceptors />
      </AuthWrapper>
    </BrowserRouter>
  );
};

export default App;
