import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { getOnboardingTabs, OnboardingTab } from 'pages/dashboard/utils';
import { getOnboardingTab } from 'pages/dashboard/selectors';
import { StatusIconTypes } from 'components/Tabs';
import { useAppSelector } from 'hooks';
import {
  getActiveProductsTypeCodes,
  getTabProgresses,
  getShowErrors,
} from 'pages/dashboard/components/Progress/selectors';
import {
  ProcentByTabProps,
  ProductTypeCodes,
  ShowErrorsProps,
} from 'entities/progress';
import {
  CheckCircleIcon,
  ProgressIcon,
  DotIcon,
  ExclamationCircleIcon,
} from 'components/icons';

const IconClasses = 'flex-shrink-0 w-[18px] h-[18px]';

const colors = {
  [StatusIconTypes.success]: 'text-green-500',
  [StatusIconTypes.progress]: 'text-blue-500',
  [StatusIconTypes.error]: 'text-yellow-500',
  [StatusIconTypes.empty]: 'text-gray-500',
};

const icons = {
  [StatusIconTypes.success]: (
    <CheckCircleIcon
      className={classNames(IconClasses, colors[StatusIconTypes.success])}
    />
  ),
  [StatusIconTypes.progress]: (
    <ProgressIcon
      className={classNames(IconClasses, colors[StatusIconTypes.progress])}
    />
  ),
  [StatusIconTypes.error]: (
    <ExclamationCircleIcon
      className={classNames(IconClasses, colors[StatusIconTypes.error])}
    />
  ),
  [StatusIconTypes.empty]: (
    <DotIcon
      className={classNames(IconClasses, colors[StatusIconTypes.empty])}
    />
  ),
};

const OnboardingTabPopover = () => {
  const { t } = useTranslation('dashboard');

  const currentTab: number = useAppSelector(getOnboardingTab);
  const tabProgress: ProcentByTabProps = useAppSelector(getTabProgresses);
  const showErrors: ShowErrorsProps = useAppSelector(getShowErrors);
  const activeProductTypes: ProductTypeCodes[] = useAppSelector(
    getActiveProductsTypeCodes,
  );

  const onboardingTabs: OnboardingTab[] = getOnboardingTabs(
    { tabProgress, showErrors },
    currentTab,
  );

  return (
    <div className="bg-gray-900 rounded-lg p-3 md:w-[352px]">
      <div className="bg-gray-800 p-3 rounded-lg">
        {onboardingTabs
          .filter((onboardingTab: OnboardingTab) =>
            activeProductTypes.includes(onboardingTab.product),
          )
          .map((onboardingTab: OnboardingTab) => (
            <div
              key={onboardingTab.code}
              className="mb-2 last:mb-0 flex items-center"
            >
              {icons[onboardingTab.statusIcon]}
              <p className="text-sm font-medium text-gray-50 pl-2 w-full">
                {t(onboardingTab.name)}
              </p>
              <p className="text-xs font-semibold text-gray-300 flex-shrink-0 whitespace-nowrap">
                <span className={colors[onboardingTab.statusIcon]}>
                  {onboardingTab.progress?.enteredFields || 0}
                </span>{' '}
                / {onboardingTab.progress?.totalFields || 0}
              </p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OnboardingTabPopover;
