import { createSelector } from '@reduxjs/toolkit';

import { BankAccount, BankAccountStatusIds } from 'entities/bankAccounts';
import { ErpAccount, ERPAcountStatuses } from 'entities/accounting';
import { PermissionSchema } from 'services/security';
import { StatusIconTypes } from 'components/Tabs';
import { Permissions } from 'entities/dashboard';

import { DashboardTabKeys } from './components/DashboardTabs';
import {
  mappedCurrencyCodes,
  OnboardingTab,
  OnboardingTabCodes,
  tabList,
} from './utils';

export const getState = (state) => state;
export const getRoot = (state) => state.dashboard;

export const getDashboardData = createSelector(
  [getRoot],
  (dashboard) => dashboard,
);

export const getDashboardTab = createSelector(
  [getRoot],
  ({ dashboardTab }) => dashboardTab,
);

export const getOnboardingTab = createSelector(
  [getRoot],
  ({ onboardingTab }) => onboardingTab,
);

export const getPermissions = createSelector(
  [getRoot],
  ({ permissions }) => permissions,
);

export const hasPermission = createSelector(
  [getRoot, (_, checkPermission: Permissions | undefined) => checkPermission],
  ({ permissions }, checkPermission) => ({
    view:
      permissions.findIndex(
        (p: PermissionSchema) =>
          p.permissions_Code.toLowerCase() ===
          `${checkPermission?.toLowerCase()}_view`,
      ) >= 0,
    edit:
      permissions.findIndex(
        (p: PermissionSchema) =>
          p.permissions_Code.toLowerCase() ===
          `${checkPermission?.toLowerCase()}_edit`,
      ) >= 0,
  }),
);

export const getDashboardTabStatuses = createSelector([getState], (state) => {
  const { accounting, bankAccounts, dashboard, progress, configuration } =
    state;

  const erpConnectedStatusId = dashboard.dropdowns.erpAccountStatuses.find(
    (s) => s.description === ERPAcountStatuses.connected,
  )?.eRPAccountStatusesId;

  const connectedERP = accounting.erpAccounts.find(
    (erpAccount: ErpAccount) =>
      erpAccount.erpaccountStatusesId === erpConnectedStatusId,
  );
  const connectedBank = bankAccounts.bankAccounts.find(
    (bankAccount: BankAccount) =>
      bankAccount.bankAccountStatusId === BankAccountStatusIds.connected,
  );

  const result = {
    [DashboardTabKeys.integration]: StatusIconTypes.progress,
    [DashboardTabKeys.onboarding]: StatusIconTypes.progress,
    [DashboardTabKeys.configuration]: StatusIconTypes.progress,
  };

  if (connectedERP && connectedBank) {
    result[DashboardTabKeys.integration] = StatusIconTypes.success;
  }

  if (bankAccounts.alerts.length) {
    result[DashboardTabKeys.integration] = StatusIconTypes.error;
  }

  const activeProductsTypeCodes = progress.businessProducts
    .filter((product) => product.isActive && product.productsIsActive)
    .map((product) => product.productsTypeCode);

  const tabInProgress: OnboardingTab | undefined = tabList
    .filter((tab: OnboardingTab) =>
      activeProductsTypeCodes.includes(tab.product),
    )
    .find(
      (tab: OnboardingTab) =>
        progress.procentByTab[OnboardingTabCodes[tab.key]].progress < 100,
    );

  if (!tabInProgress) {
    result[DashboardTabKeys.onboarding] = StatusIconTypes.success;
  }

  if (
    bankAccounts.bankAccounts.find(
      (bankAccount: BankAccount) =>
        bankAccount.isActive &&
        bankAccount.bankAccountsId === configuration.bpsTrack?.bankAccountId,
    ) ||
    (!bankAccounts.bankAccounts.length && configuration.bpsTrack?.bankAccountId)
  ) {
    result[DashboardTabKeys.configuration] = StatusIconTypes.success;
  }

  return result;
});

export const getLoading = createSelector(
  [getRoot],
  ({ isLoading }) => isLoading,
);

export const getBankAccountStatuses = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.bankAccountStatuses,
);

export const getCountries = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.countries,
);

export const getApplicationStatuses = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.applicationStatus,
);

export const getBusinessStatuses = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.businessStatuses,
);

export const getInputCurrencies = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.inputCurrencies,
);

export const getBusinessTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.businessIdTypes,
);

export const getDocumentTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.businessesDocumentsTypes,
);

export const getPersonsDocumentsTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.personsDocumentsTypes,
);

export const getBlobReferenceTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.blobReferencesTypes,
);

export const getMappedBlobReferenceTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.mappedBlobReferencesTypes,
);

export const getBusinessLegalEntityFormTypes = createSelector(
  [getRoot],
  ({ dropdowns }) => dropdowns.businessesLegalEntityFormTypes,
);

export const getMappedCurrencyCodes = createSelector(
  [getRoot],
  ({ dropdowns }) => mappedCurrencyCodes(dropdowns.currencyCodes),
);
