import { DashboardTabs as TabIndexes } from 'entities/dashboard';
import Tabs, { TabProps, TabVariants } from 'components/Tabs';
import { useAppDispatch, useAppSelector } from 'hooks';

import { getDashboardTab, getDashboardTabStatuses } from '../selectors';
import ConfigurationTabPopover from './ConfigurationTabPopover';
import IntegrationTabPopover from './IntegrationTabPopover';
import OnboardingTabPopover from './OnboardingTabPopover';
import { setDashboardTab } from '../dashboardSlice';
import ConfigurationTab from './ConfigurationTab';
import IntegrationTab from './IntegrationTab';
import OnboardingTab from './OnboardingTab';

export enum DashboardTabKeys {
  integration = 'integration',
  onboarding = 'onboarding',
  configuration = 'configuration',
}

export const dashboardTabs: TabProps[] = [
  {
    name: 'dashboard:tabs.integration',
    popover: <IntegrationTabPopover />,
    key: DashboardTabKeys.integration,
  },
  {
    name: 'dashboard:tabs.onboarding',
    popover: <OnboardingTabPopover />,
    key: DashboardTabKeys.onboarding,
  },
  {
    name: 'dashboard:tabs.configuration',
    popover: <ConfigurationTabPopover />,
    key: DashboardTabKeys.configuration,
  },
];

export default function DashboardTabs() {
  const dispatch = useAppDispatch();

  const currentTab: TabIndexes = useAppSelector(getDashboardTab);
  const tabStatuses = useAppSelector(getDashboardTabStatuses);

  const tabs: TabProps[] = dashboardTabs.map((tab: TabProps) => ({
    ...tab,
    statusIcon: tabStatuses[tab.key || ''],
  }));

  const handleChange = (value: TabIndexes) => {
    dispatch(setDashboardTab(value));
  };

  const tabComponents = {
    [TabIndexes.integration]: () => <IntegrationTab />,
    [TabIndexes.onboarding]: () => <OnboardingTab />,
    [TabIndexes.configuration]: () => <ConfigurationTab />,
  };

  return (
    <>
      <Tabs
        tabs={tabs}
        currentTab={currentTab}
        onChange={handleChange}
        tabClassName="w-full justify-center ml-[1px] first:ml-0 bg-white"
        className="bg-gray-200 rounded-lg isolate shadow relative z-10"
        variant={TabVariants.button}
      />
      {tabComponents[currentTab]()}
    </>
  );
}
