import api from 'api';

export const erpValidateConnection = (id, emailAddress) =>
  api.post(
    `ERPApi/ValidateConnection?erpaccountsId=${id}&validatedBy=${emailAddress}`,
  );

export const searchERPIntegrators = (data) => {
  return api.post('ErpIntegrators/SearchErpIntegrators', data);
};

export const fetchERPLogoByURI = async (uri: string) => {
  try {
    const logo: Blob = await api.get(uri, { responseType: 'blob' });
    return logo;
  } catch (e) {
    return null;
  }
};

export const addErpAccount = (data) => {
  return api.post('Erpaccounts/AddErpaccounts', data);
};

export const getErpAccounts = (id) => {
  return api.get(`Erpaccounts/GetErpaccounts/${id}`);
};

export const updateErpAccount = (data) => {
  return api.put('Erpaccounts/UpdateErpaccounts', data);
};

export const updateIsActiveErpAccount = (data) => {
  return api.put('Erpaccounts/UpdateIsActiveErpaccounts', data);
};

export const getGuideByLink = (link) => {
  return api.get(link, { responseType: 'blob' });
};

export const erpAuthentication = (erpName, data) =>
  api.post(`${erpName}/Authentication`, data);

export const erpUpdateToken = (erpName, data) =>
  api.post(`${erpName}/UpdateAccessToken`, data);
