import { createSelector } from '@reduxjs/toolkit';

export const getRoot = (state) => state.notification;

export const getNotification = createSelector(
  [getRoot],
  ({ message, variant, translate, duration }) => ({
    message,
    variant,
    translate,
    duration,
  }),
);
