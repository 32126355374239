export interface BussinessProductsProps {
  businessesProductsXrefId: number;
  createdBy: string;
  createdOn: string;
  modifiedBy: string;
  modifiedOn: string;
  isActive: boolean;
  businessesId: number;
  isApproved: boolean;
  approvedOn: string;
  approvedBy: string;
  productsId: number;
  productStatusesId: number;
  productStatusesDescription: string;
  productsDescription: string;
  productsIsActive: boolean;
  productsTypeCode: string;
}

export interface ProductsProps {
  productsId: number;
  createdOn: string;
  createdBy: string;
  modifiedOn: string;
  modifiedB: string;
  isActive: boolean;
  typeCode: string;
  description: string;
}

export enum ProductTypeCodes {
  payments = 'AR/AP Payments',
  scf = 'Supply Chain Financing',
  services = 'Merchant Services',
  disbursments = 'Cross Border Disbursments',
  crossBorderPayments = 'Cross-Border Payments',
}

export interface TabProgressProps {
  totalFields: number;
  enteredFields: number;
  progress: number;
}

export interface ShowErrorsProps {
  businessInfo: boolean;
  owner: boolean;
  financial: boolean;
  transactional: boolean;
  businessPlan: boolean;
}

export interface ProcentByTabProps {
  businessInfo: TabProgressProps;
  owner: TabProgressProps;
  financial: TabProgressProps;
  transactional: TabProgressProps;
  businessPlan: TabProgressProps;
}

export interface ProgressState {
  progress: number;
  newProductType: string;
  procentByTab: ProcentByTabProps;
  businessProducts: BussinessProductsProps[];
  showErrors: ShowErrorsProps;
}

export enum ApplicationStatuses {
  pendingEnrollment = 'Pending Enrollment Data',
  pendingApproval = 'Pending Approval',
  complianceApproved = 'Compliance Approved',
  approved = 'Approved',
  declined = 'Declined',
  withdrawn = 'Application Withdrawn',
  applicationSuspended = 'Application or Account Suspended',
  suspended = 'Suspended',
}

export enum ApplicationStatusColors {
  'Pending Enrollment Data' = 'yellow',
  'Pending Approval' = 'blue',
  'Compliance Approved' = 'green',
  'Approved' = 'green',
  'Declined' = 'red',
  'Application Withdrawn' = 'yellow',
  'Application or Account Suspended' = 'red',
  'Suspended' = 'red',
}
