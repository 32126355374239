export const DotIcon = ({ className }: { className?: string }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <path
      d="M11.6998 9C11.6998 10.4912 10.491 11.7 8.9998 11.7C7.50864 11.7 6.2998 10.4912 6.2998 9C6.2998 7.50883 7.50864 6.3 8.9998 6.3C10.491 6.3 11.6998 7.50883 11.6998 9Z"
      fill="currentColor"
    />
  </svg>
);
