import { createSelector } from '@reduxjs/toolkit';

import { RootState } from 'state/reducers';

export const getRoot = (state: RootState) => state.transactional;

export const getCrossBorder = createSelector(
  [getRoot],
  ({ crossBorder }) => crossBorder,
);
